/* 01.Base */
html {
  width: 100% !important;
  height: 100% !important;
  background: #fbfbff !important; }

#root {
  height: 100% !important; }

body {
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background: #fbfbff !important; }
  body.background {
    height: 100% !important; }
    body.background main {
      margin: 0 !important;
      height: 100% !important; }
      body.background main .container {
        height: 100% !important; }

.fixed-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

hr {
  border-top: 1px solid #ced4da; }

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

::selection {
  background: #b5bcbd; }

::-moz-selection {
  background: #b5bcbd; }

.scrollbar-container {
  margin-right: -15px;
  padding-right: 15px;
  position: relative; }

.ps__rail-y {
  width: 5px; }

.ps__thumb-y {
  left: 0; }

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 5px;
  left: 0; }

.ps__thumb-y {
  background-color: #ced4da; }

.logo-single {
  width: 110px;
  height: 35px;
  background: url("/images/brand/logow.svg") no-repeat;
  background-position: center center;
  display: inline-block;
  margin-bottom: 60px; }

.list-item-heading {
  font-size: 1rem; }

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.no-transition {
  transition: none !important; }

a {
  color: #495057;
  transition: color 200ms; }
  a:hover, a:active {
    text-decoration: initial;
    color: #3949ab; }

.view-icon {
  font-size: 20px;
  color: #8898aa; }
  .view-icon:hover {
    color: #3949ab; }
  .view-icon.s {
    font-size: 18px; }

#displayOptions a {
  cursor: pointer; }

#displayOptions a.active i {
  color: #3949ab; }

#displayOptions button {
  border-color: #8898aa;
  color: #8898aa; }
  #displayOptions button:hover {
    background-color: #3949ab;
    border-color: #3949ab;
    color: #ffffff; }

#displayOptions .btn-outline-dark:not(:disabled):not(.disabled):active,
#displayOptions .btn-outline-dark:not(:disabled):not(.disabled).active,
#displayOptions .show > .btn-outline-dark.dropdown-toggle {
  background-color: #3949ab;
  border-color: #3949ab;
  color: #ffffff; }

#displayOptions .view-icon svg {
  width: 19px; }

#displayOptions .view-icon .view-icon-svg {
  fill: #8898aa; }

#displayOptions .view-icon:hover .view-icon-svg, #displayOptions .view-icon.active .view-icon-svg {
  fill: #3949ab; }

.main-heading {
  border-bottom: 1px solid #ced4da; }

.separator {
  border-bottom: 1px solid #ced4da; }

.alert-dismissible .close {
  padding: 0.5rem 1.25rem; }

.rounded {
  border-radius: 50px !important; }

.img-thumbnail {
  border-radius: 0.375rem;
  padding: 0;
  border: initial; }

.list-thumbnail {
  border-radius: 0.375rem;
  padding: 0;
  border: initial;
  height: auto;
  max-width: unset;
  height: 85px;
  object-fit: cover;
  width: unset !important; }
  @media (max-width: 991px) {
    .list-thumbnail {
      height: 80px; } }
  @media (max-width: 575px) {
    .list-thumbnail {
      height: 70px; } }
  @media (max-width: 991px) {
    .list-thumbnail.responsive {
      width: unset;
      height: 136px; } }
  @media (max-width: 575px) {
    .list-thumbnail.responsive {
      width: 110px !important;
      height: 100%; } }
  @media (max-width: 419px) {
    .list-thumbnail.responsive {
      width: 90px !important;
      height: 100%; } }
  .list-thumbnail.small {
    height: 60px;
    font-size: 1rem; }
    @media (max-width: 991px) {
      .list-thumbnail.small {
        height: 55px; } }
    @media (max-width: 575px) {
      .list-thumbnail.small {
        height: 50px; } }
  .list-thumbnail.xsmall {
    height: 40px;
    font-size: 1rem; }
    @media (max-width: 991px) {
      .list-thumbnail.xsmall {
        height: 40px; } }
    @media (max-width: 575px) {
      .list-thumbnail.xsmall {
        height: 40px; } }

.list-thumbnail-letters {
  width: 85px;
  height: 85px;
  background: #3949ab;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  color: #ffffff; }
  @media (max-width: 991px) {
    .list-thumbnail-letters {
      width: 80px;
      height: 80px; } }
  @media (max-width: 575px) {
    .list-thumbnail-letters {
      width: 70px;
      height: 70px; } }
  .list-thumbnail-letters.small {
    width: 60px;
    height: 60px;
    font-size: 1rem; }
    @media (max-width: 991px) {
      .list-thumbnail-letters.small {
        width: 55px;
        height: 55px; } }
    @media (max-width: 575px) {
      .list-thumbnail-letters.small {
        width: 50px;
        height: 50px; } }

.white {
  color: #ffffff; }

/* 02.Buttons */
.btn-arrow {
  display: inline-block;
  text-align: center;
  border-radius: 30px !important;
  width: 42px;
  height: 42px;
  line-height: 24px; }

.btn-arrow i {
  font-size: 15px;
  display: inline-block;
  text-align: center; }

.btn-sm.btn-arrow {
  width: 34px;
  height: 34px;
  line-height: 17px; }

.btn-sm.btn-arrow i {
  font-size: 13px;
  line-height: 10px; }

.btn-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  transition: background-color box-shadow 0.1s linear; }
  .btn-shadow:hover, .btn-shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 2px rgba(0, 0, 0, 0.15) !important; }

.btn-empty {
  background: transparent !important; }

.check-button {
  cursor: default !important; }

.check-button.btn-primary {
  background-color: #3949ab !important;
  border-color: #3949ab !important;
  opacity: 1; }

.check-button .custom-control {
  min-height: 1.1rem;
  margin-top: -7px; }

.dropdown-menu {
  font-size: 0.875rem;
  border-radius: 0.375rem;
  background: #ffffff;
  border-color: rgba(73, 80, 87, 0.15); }

.dropdown-item {
  padding: 0.75rem 1.5rem;
  color: #495057; }

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #fbfbff;
  color: #495057; }

.dropdown-item.active,
.dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #3949ab; }

.dropdown-divider {
  border-color: #ced4da; }

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #2a357d;
  border-color: #2a357d;
  color: #ffffff; }

.btn-multiple-state {
  position: relative;
  transition: opacity 500ms; }
  .btn-multiple-state .spinner,
  .btn-multiple-state .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 500ms;
    color: #ffffff; }
  .btn-multiple-state .icon i {
    vertical-align: text-bottom;
    font-size: 18px; }
  .btn-multiple-state .label {
    transition: opacity 500ms; }
  .btn-multiple-state.show-spinner .label {
    opacity: 0; }
  .btn-multiple-state.show-spinner .spinner {
    opacity: 1;
    visibility: visible; }
  .btn-multiple-state.show-success .label {
    opacity: 0; }
  .btn-multiple-state.show-success .icon.success {
    opacity: 1;
    visibility: visible; }
  .btn-multiple-state.show-fail .label {
    opacity: 0; }
  .btn-multiple-state.show-fail .icon.fail {
    opacity: 1;
    visibility: visible; }
  .btn-multiple-state.btn-primary:disabled {
    opacity: 1;
    background: #2c3985;
    border-color: #2c3985; }
  .btn-multiple-state.btn-secondary:disabled {
    opacity: 1;
    border-color: #20638f; }

.icon-button {
  padding: 0;
  font-size: 14px;
  width: 34px;
  height: 34px;
  line-height: 34px; }
  .icon-button.large {
    width: 44px;
    height: 44px;
    font-size: 18px; }
  .icon-button.small-icon {
    font-size: 12px;
    line-height: 32px; }

.top-right-button {
  width: calc(100% - 80px); }
  @media (max-width: 575px) {
    .top-right-button {
      padding-left: 0;
      padding-right: 0; } }

.top-right-button-single {
  width: unset; }
  @media (max-width: 991px) {
    .top-right-button-single {
      width: calc(100%); } }

/* 03.Widths-Spacing */
.w-10 {
  width: 10% !important; }

.w-90 {
  width: 90% !important; }

.w-12 {
  width: 12% !important; }

.w-88 {
  width: 88% !important; }

.w-15 {
  width: 15% !important; }

.w-85 {
  width: 85% !important; }

.w-20 {
  width: 20% !important; }

.w-80 {
  width: 80% !important; }

.w-30 {
  width: 30% !important; }

.w-70 {
  width: 70% !important; }

.w-40 {
  width: 40% !important; }

.w-60 {
  width: 60% !important; }

@media (max-width: 767px) {
  .w-xs-100 {
    width: 100% !important; } }

@media (max-width: 991px) {
  .w-sm-100 {
    width: 100% !important; } }

.r-0 {
  right: 0; }

.l-0 {
  left: 0; }

.min-width-zero {
  min-width: 0; }

@media (max-width: 575px) {
  .float-none-xs {
    float: initial !important; } }

/* 04.Borders */
.border {
  border: 1px solid #ecf0f1 !important; }

.border-right {
  border-right: 1px solid #ecf0f1 !important; }

.border-left {
  border-left: 1px solid #ecf0f1 !important; }

.border-top {
  border-top: 1px solid #ecf0f1 !important; }

.border-bottom {
  border-bottom: 1px solid #ecf0f1 !important; }

.border-primary {
  border-color: #3949ab !important; }

.border-secondary {
  border-color: #495057 !important; }

/* 05.Badges */
.badge {
  padding: 0.55em 0.75em 0.6em 0.75em;
  font-size: 75%; }
  .badge.badge-pill {
    padding-right: 1.25em;
    padding-left: 1.25em; }
  .badge.badge-top-left {
    top: 3px;
    left: -9px; }
  .badge.badge-top-left-2 {
    top: 40px;
    left: -7px; }
  .badge.badge-top-right {
    top: 8px;
    right: -7px; }
  .badge.badge-top-right-2 {
    top: 40px;
    right: -7px; }

/* 06.Breadcrumb */
@media (max-width: 991px) {
  .breadcrumb-container .breadcrumb {
    padding: 0; } }

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0.5rem; }

.breadcrumb-item + .breadcrumb-item::before {
  content: "|"; }

/* 07.Tabs */
.nav-tabs.separator-tabs {
  border-bottom: 1px solid #ced4da; }

.nav-tabs .nav-link {
  border: initial;
  padding-top: 1rem; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: #ffffff; }

.nav-tabs .nav-link.active::before,
.nav-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: #3949ab;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0;
  top: 0; }

.nav-tabs.separator-tabs .nav-link.active::before,
.nav-tabs.separator-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: #3949ab;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  top: unset; }

.nav-tabs.separator-tabs .nav-link {
  border: initial;
  padding-top: 1rem;
  background: initial;
  padding-left: 0;
  padding-top: 0.5rem;
  padding-right: 0;
  margin-right: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #8898aa; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: initial;
  position: relative;
  color: #3949ab; }

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: initial;
  color: #3949ab; }

.react-siema-container {
  overflow: hidden;
  padding-bottom: 15px;
  padding-top: 5px; }

.slider-nav .left-arrow,
.slider-nav .right-arrow {
  font-size: 20px;
  color: #3949ab;
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px;
  padding-top: 14px; }

.slider-nav .slider-dot-container {
  display: inline-block; }

.slider-nav .btn:hover, .slider-nav .btn:focus, .slider-nav .btn:active {
  text-decoration: initial; }

.slider-dot {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background: #ced4da;
  outline: initial !important;
  border: initial;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }
  .slider-dot.active {
    background: #3949ab; }

.react-siema-container .card .card-body {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

.react-siema-container .card .w-50 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

/* 09.Navbar*/
.navbar {
  background: #ffffff;
  height: 58px;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .navbar #notificationDropdown {
    width: 260px;
    padding: 1.5rem;
    height: 280px;
    right: 15px; }
  .navbar #iconMenuDropdown {
    width: 320px;
    padding: 1.5rem;
    padding-bottom: 0; }
  .navbar .icon-menu-item {
    width: 90px;
    display: inline-block;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #495057;
    font-weight: 600;
    vertical-align: top; }
    .navbar .icon-menu-item i {
      font-size: 32px;
      line-height: 42px; }
    .navbar .icon-menu-item span {
      text-align: center;
      padding: 0 10px;
      line-height: 14px; }
    .navbar .icon-menu-item:hover, .navbar .icon-menu-item:focus {
      color: #3949ab; }
  .navbar .menu-button-mobile {
    color: #8898aa;
    text-align: center; }
    .navbar .menu-button-mobile svg {
      height: 12px;
      fill: #495057; }
    @media (max-width: 767px) {
      .navbar .menu-button-mobile {
        width: 60px !important; } }
  .navbar .menu-button {
    color: #8898aa;
    width: 100px;
    text-align: center; }
    .navbar .menu-button svg {
      height: 12px; }
    .navbar .menu-button .main {
      fill: #495057;
      transition: fill 300ms; }
    .navbar .menu-button .sub {
      fill: #495057;
      transition: fill 300ms; }
    .navbar .menu-button:hover {
      color: #3949ab; }
    @media (max-width: 767px) {
      .navbar .menu-button {
        width: 60px; } }
  .navbar .navbar-logo {
    width: 100px;
    height: 32px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto; }
    .navbar .navbar-logo .logo {
      width: 100%;
      height: 100%;
      background: url("/images/brand/logow.svg") no-repeat;
      background-position: center center; }
    .navbar .navbar-logo .logo-mobile {
      width: 100%;
      height: 100%;
      background: url("/images/brand/logow.svg") no-repeat;
      background-position: center center; }
    @media (max-width: 767px) {
      .navbar .navbar-logo {
        width: 80px; } }
    @media (max-width: 419px) {
      .navbar .navbar-logo {
        width: 20px; } }
  .navbar .language-button {
    background: #fbfbff;
    border: initial;
    font-size: 0.875rem;
    color: #8898aa;
    padding: .6rem 1rem; }
    @media (max-width: 575px) {
      .navbar .language-button {
        padding-left: 0.75rem;
        padding-right: 0.75rem; } }
  .navbar .search {
    position: relative;
    width: 180px;
    border-radius: 20px;
    background: #fbfbff; }
    .navbar .search input {
      border: initial;
      background: transparent;
      outline: initial !important;
      padding: 0.5rem 1rem;
      line-height: 2;
      font-size: 0.875rem;
      width: 93%;
      color: #495057; }
    .navbar .search .search-icon {
      font-size: 17px;
      border-radius: 10px;
      color: #8898aa;
      position: absolute;
      width: 40px;
      height: 40px;
      bottom: -8px;
      right: 3px;
      text-align: center;
      cursor: pointer; }
      .navbar .search .search-icon:hover {
        color: #3949ab; }
    @media (max-width: 767px) {
      .navbar .search {
        width: 30px;
        height: 30px;
        background: initial;
        margin-left: 0.6rem;
        color: rgba(73, 80, 87, 0.7); }
        .navbar .search input {
          display: none; }
        .navbar .search .search-icon {
          font-size: 17px;
          width: 30px;
          height: 30px;
          bottom: -3px;
          right: 0;
          color: inherit; }
        .navbar .search.mobile-view {
          display: block;
          width: 100%;
          position: fixed;
          z-index: 2;
          background: #ffffff;
          left: 0;
          top: 0;
          height: 58px;
          margin-left: 15px; }
          .navbar .search.mobile-view input {
            display: block;
            width: 100%;
            height: 70px;
            padding-left: 0; }
          .navbar .search.mobile-view span {
            top: 50%;
            transform: translateY(-50%);
            right: 25px; } }
  .navbar .header-icons {
    margin-right: 1rem; }
    @media (max-width: 575px) {
      .navbar .header-icons {
        margin-right: 0; } }
  .navbar .header-icon {
    font-size: 16px;
    color: #8898aa;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    vertical-align: initial; }
    @media (max-width: 575px) {
      .navbar .header-icon {
        padding-left: 0.3rem;
        padding-right: 0.3rem; } }
    .navbar .header-icon:hover {
      color: #3949ab; }
    .navbar .header-icon#fullScreenButton i:last-of-type {
      display: none; }
    .navbar .header-icon#notificationButton:after {
      font-size: 10px;
      content: "3";
      color: #3949ab;
      border: 1px solid #3949ab;
      border-radius: 10px;
      position: absolute;
      width: 15px;
      height: 15px;
      text-align: center;
      font-weight: 700;
      top: 2px;
      right: 3px;
      line-height: 14px; }
      @media (max-width: 575px) {
        .navbar .header-icon#notificationButton:after {
          right: -1px; } }
  .navbar .user {
    color: #949da5;
    position: relative; }
    .navbar .user img {
      margin-left: 10px;
      border-radius: 30px;
      width: 36px; }
    @media (max-width: 767px) {
      .navbar .user {
        margin-left: initial; }
        .navbar .user .name {
          display: none; }
        .navbar .user:after {
          font-size: 11px;
          width: 14px;
          height: 14px;
          bottom: -3px;
          right: -3px; } }
  @media (max-width: 767px) {
    .navbar {
      height: 58px; } }

#app-container.sub-hidden .menu-button .sub,
#app-container.menu-sub-hidden .menu-button .sub {
  fill: #8898aa; }

#app-container.main-hidden .menu-button .main,
#app-container.main-hidden .menu-button .sub,
#app-container.menu-hidden .menu-button .main,
#app-container.menu-hidden .menu-button .sub {
  fill: #8898aa; }

/* 10.Menu*/
#app-container .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  padding-top: 58px;
  z-index: 4;
  height: calc(100% - 58px); }
  #app-container .sidebar .scrollbar-container {
    margin-right: 0;
    padding-right: 0; }
  #app-container .sidebar .main-menu {
    width: 100px;
    height: calc(100% - 58px);
    background: #ffffff;
    z-index: 3;
    position: fixed;
    transition: transform 300ms;
    padding-top: 2px;
    left: 0; }
    #app-container .sidebar .main-menu .scroll {
      padding-right: unset;
      margin-right: unset;
      height: 100%; }
      #app-container .sidebar .main-menu .scroll .ps__thumb-y {
        right: 0; }
    #app-container .sidebar .main-menu.main-hidden {
      transform: translateX(-100px); }
    #app-container .sidebar .main-menu ul li {
      position: relative; }
      #app-container .sidebar .main-menu ul li span {
        text-align: center;
        padding: 0 10px;
        line-height: 14px; }
      #app-container .sidebar .main-menu ul li a {
        height: 95px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        border-bottom: 1px solid #ecf0f1;
        color: #495057;
        transition: color 300ms;
        transition: background 300ms; }
        #app-container .sidebar .main-menu ul li a:hover, #app-container .sidebar .main-menu ul li a:focus {
          color: #3949ab;
          background: #fbfbff; }
      #app-container .sidebar .main-menu ul li i {
        font-size: 28px;
        line-height: 42px; }
      #app-container .sidebar .main-menu ul li.active a {
        color: #3949ab; }
      #app-container .sidebar .main-menu ul li.active:after {
        content: " ";
        background: #3949ab;
        border-radius: 1px;
        position: absolute;
        width: 6px;
        height: 85px;
        top: 50%;
        transform: translateY(-50%);
        left: 0; }
    @media (max-width: 767px) {
      #app-container .sidebar .main-menu {
        width: 100px;
        height: calc(100% - 58px); }
        #app-container .sidebar .main-menu ul li i {
          font-size: 28px;
          line-height: 38px; }
        #app-container .sidebar .main-menu ul li a {
          height: 95px; }
        #app-container .sidebar .main-menu ul li.active:after {
          width: 3px;
          height: 60px; } }
  #app-container .sidebar .sub-menu {
    width: 200px;
    background: #ffffff;
    z-index: 2;
    position: fixed;
    left: 100px;
    border-left: 1px solid #ecf0f1;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    transition: transform 300ms;
    height: calc(100% - 58px); }
    #app-container .sidebar .sub-menu .scroll {
      height: 100%;
      margin-top: 2px;
      margin-right: unset;
      padding-right: unset; }
      #app-container .sidebar .sub-menu .scroll .ps__thumb-y {
        right: 0; }
    #app-container .sidebar .sub-menu .ps {
      padding-top: 25px;
      padding-bottom: 25px; }
    #app-container .sidebar .sub-menu ul {
      display: none; }
      #app-container .sidebar .sub-menu ul li {
        margin-bottom: 10px;
        margin-left: 15px; }
        #app-container .sidebar .sub-menu ul li a {
          font-size: 13px;
          display: block;
          padding: 8px 0;
          font-weight: 600; }
        #app-container .sidebar .sub-menu ul li i {
          font-size: 1.3em;
          margin-right: 10px;
          color: #8898aa;
          vertical-align: middle; }
        #app-container .sidebar .sub-menu ul li span {
          vertical-align: middle;
          padding-top: 3px;
          display: inline-block; }
        #app-container .sidebar .sub-menu ul li.active i,
        #app-container .sidebar .sub-menu ul li.active a {
          color: #3949ab; }
        @media (max-width: 767px) {
          #app-container .sidebar .sub-menu ul li {
            margin-left: 15px; } }
    @media (max-width: 767px) {
      #app-container .sidebar .sub-menu {
        left: 100px;
        width: 190px;
        height: calc(100% - 58px); } }
  @media (max-width: 767px) {
    #app-container .sidebar {
      padding-top: 58px;
      box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); } }

#app-container.sub-hidden .sub-menu,
#app-container.menu-sub-hidden .sub-menu,
#app-container.menu-hidden .sub-menu {
  transform: translateX(-200px); }
  @media (max-width: 767px) {
    #app-container.sub-hidden .sub-menu,
    #app-container.menu-sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      transform: translateX(-190px); } }

#app-container.main-hidden .main-menu,
#app-container.menu-hidden .main-menu {
  transform: translateX(-100px); }
  @media (max-width: 767px) {
    #app-container.main-hidden .main-menu,
    #app-container.menu-hidden .main-menu {
      transform: translateX(-100px); } }

#app-container.main-hidden.sub-hidden .sub-menu,
#app-container.menu-hidden .sub-menu {
  transform: translateX(-300px); }
  @media (max-width: 767px) {
    #app-container.main-hidden.sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      transform: translateX(-290px); } }

#app-container.menu-mobile .main-menu {
  transform: translateX(-100px); }

#app-container.menu-mobile .sub-menu {
  transform: translateX(-340px); }

#app-container.main-show-temporary .main-menu {
  transform: translateX(0); }

#app-container.main-show-temporary .sub-menu {
  transform: translateX(-200px); }

@media (max-width: 767px) {
  #app-container.main-show-temporary .sub-menu {
    transform: translateX(-190px); } }

#app-container.sub-show-temporary .sub-menu, #app-container.menu-mobile.sub-show-temporary .sub-menu {
  transform: translateX(0); }

/* 11.Apps*/
.app-menu {
  z-index: 1;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  width: 280px;
  float: right;
  background: #ffffff;
  transition: transform 300ms;
  height: calc(100% - 58px);
  position: fixed;
  right: 0;
  top: 58px; }
  @media (max-width: 767px) {
    .app-menu {
      top: 58px;
      height: calc(100% - 58px); } }
  .app-menu .scrollbar-container {
    margin-right: unset;
    padding-right: unset; }
    .app-menu .scrollbar-container .ps__rail-y {
      right: 2px !important; }
  .app-menu.shown {
    transform: translateX(0); }
  .app-menu .app-menu-button {
    cursor: pointer;
    position: absolute;
    left: -28px;
    background: #ffffff;
    top: 25px;
    padding: 12px 8px 12px 5px;
    border-radius: 0.2rem;
    color: #8898aa !important;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
    font-size: 15px;
    line-height: 0; }
    @media (max-width: 767px) {
      .app-menu .app-menu-button {
        top: -2px; } }
  .app-menu ul li {
    margin-bottom: 5px; }
    .app-menu ul li a {
      font-size: 13px;
      display: block;
      padding: 3px 0; }
      .app-menu ul li a:hover i {
        color: #3949ab; }
    .app-menu ul li i {
      font-size: 1.2em;
      margin-right: 10px;
      color: #8898aa;
      vertical-align: unset; }
      @media (max-width: 767px) {
        .app-menu ul li i {
          font-size: 20px; } }
    .app-menu ul li.active i,
    .app-menu ul li.active a {
      color: #3949ab; }

.app-row {
  padding-right: 280px; }
  @media (max-width: 1199px) {
    .app-row {
      padding-right: 0; } }

/* 12.Chat App*/
.chat-app .scrollbar-container {
  padding-left: 10px;
  margin-left: -10px;
  padding-right: 10px;
  margin-right: 0;
  height: calc(100vh - 58px - 270px); }
  @media (max-width: 767px) {
    .chat-app .scrollbar-container {
      height: calc(100vh - 58px - 220px); } }
  .chat-app .scrollbar-container .ps__thumb-y {
    right: 10px; }

.chat-app .chat-text-left {
  padding-left: 64px; }

.chat-app .chat-text-right {
  padding-right: 64px; }

@media (max-width: 767px) {
  .chat-app .list-item-heading {
    font-size: 0.9rem; } }

@media (max-width: 767px) {
  .chat-app .card .card-body {
    padding: 0.75rem; } }

.chat-app-tab-content {
  height: calc(100% - 45px); }

.chat-app-tab-pane {
  height: calc(100% - 45px); }

.chat-input-container {
  width: 100%;
  height: 90px;
  background: #ffffff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  padding-left: 340px;
  padding-right: 320px; }
  @media (max-width: 767px) {
    .chat-input-container {
      padding-right: 15px;
      padding-left: 15px; } }
  .chat-input-container input {
    border: initial;
    width: unset;
    height: 90px;
    padding-left: 0;
    display: inline-block; }
  @media (max-width: 991px) {
    .chat-input-container .send-button {
      padding-left: 0.7rem;
      padding-right: 0.7rem; } }

/* 13.Survey App*/
.survey-app .answers .badge {
  color: #8898aa;
  font-size: 13px;
  padding-left: 0.75em;
  padding-right: 0.75em; }

.survey-app .answers input {
  padding-right: 70px; }

.survey-app .question.edit-quesiton .edit-mode {
  display: block; }

.survey-app .question.edit-quesiton .view-mode {
  display: none; }

.survey-app .question.edit-quesiton .view-button {
  display: inline-block; }

.survey-app .question.edit-quesiton .edit-button {
  display: none; }

.survey-app .question.view-quesiton .edit-mode {
  display: none; }

.survey-app .question.view-quesiton .view-mode {
  display: block; }

.survey-app .question.view-quesiton .view-button {
  display: none; }

.survey-app .question.view-quesiton .edit-button {
  display: inline-block; }

.survey-app .survey-question-types .btn-group-icon {
  font-size: 21px;
  line-height: 28px; }

.survey-app .survey-question-types .btn {
  padding-bottom: 0.3rem; }

.input-icons {
  position: absolute;
  right: 0;
  top: 9px; }

/* 14.Todo App*/
.heading-icon {
  font-size: 20px;
  color: #3949ab;
  margin-right: 5px;
  line-height: inherit;
  vertical-align: middle; }

.todo-details {
  margin-top: 20px;
  margin-left: 40px; }

.heading-number {
  border: 1px solid #3949ab;
  padding: 4px;
  vertical-align: middle;
  margin-right: 10px;
  border-radius: 20px;
  width: 34px;
  height: 34px;
  text-align: center;
  color: #3949ab; }

/* 15.Main*/
main {
  margin-left: 340px;
  margin-top: 80px;
  margin-right: 0;
  margin-bottom: 20px; }
  main.sub-hidden {
    margin-left: 140px; }
  main.main-hidden {
    margin-left: 40px; }
  main .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  @media (max-width: 767px) {
    main {
      margin-left: 0px !important;
      margin-right: 0px !important;
      margin-top: 80px !important;
      margin-bottom: 0; } }
  @media (max-width: 575px) {
    main {
      margin-bottom: 0; } }

#app-container.sub-hidden main,
#app-container.menu-sub-hidden main,
#app-container.menu-hidden main {
  margin-left: 100px; }

#app-container.main-hidden main,
#app-container.menu-hidden main {
  margin-left: 0; }

/* 16.Cards*/
.card {
  border: initial;
  background: #ffffff;
  border-radius: 0.375rem;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .card .card-header .card-icon {
    right: 5px;
    top: 5px; }
    .card .card-header .card-icon i {
      font-size: 12px;
      color: #ced4da; }
  .card .card-subtitle {
    margin: 0;
    margin-bottom: 1rem; }
  .card .card-header .handle {
    cursor: default; }
  .card .card-title {
    margin-bottom: 2rem; }
    @media (max-width: 767px) {
      .card .card-title {
        margin-bottom: 1.25rem; } }

.card-img {
  border-radius: calc(0.25rem - 1px);
  height: 100%;
  object-fit: cover;
  max-height: 200px;
  width: unset; }

.card-img-bottom {
  width: 100%;
  border-bottom-left-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.15rem - 1px);
  border-top-right-radius: calc(0.15rem - 1px); }

.card-img-left {
  width: 100%;
  border-top-left-radius: calc(0.15rem - 1px);
  border-bottom-left-radius: calc(0.15rem - 1px);
  height: 100%;
  object-fit: cover; }

.card-img-right {
  width: 100%;
  border-top-right-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px);
  height: 100%;
  object-fit: cover; }

.card-img-overlay {
  background: rgba(0, 0, 0, 0.5);
  padding: 1.75rem; }
  @media (max-width: 575px) {
    .card-img-overlay {
      padding: 1rem; } }

/* 17.Dashboard*/
.dashboard-line-chart {
  height: 283px; }

.dashboard-quick-post {
  min-height: 263px; }

.dashboard-list-with-thumbs {
  height: 400px; }

.dashboard-logs {
  height: 400px; }

.dashboard-list-with-user {
  height: 270px; }

.dashboard-donut-chart {
  height: 270px; }

.dashboard-small-chart {
  height: 150px; }
  .dashboard-small-chart .chart {
    height: 75px; }
  .dashboard-small-chart .lead {
    font-size: 1.4rem; }

.dashboard-small-chart-analytics {
  height: 180px; }
  .dashboard-small-chart-analytics .chart {
    height: 85px; }
  .dashboard-small-chart-analytics .lead {
    font-size: 1.4rem; }

.dashboard-filled-line-chart {
  height: 340px; }
  .dashboard-filled-line-chart .chart {
    height: 200px; }

.dashboard-sq-banner {
  background-image: linear-gradient(to right top, #2980b9, #3498db, #3949ab);
  background-size: cover;
  height: 100%;
  transition: 0.5s;
  background-size: 350% auto;
  cursor: pointer; }
  .dashboard-sq-banner .lead {
    line-height: 2.3rem; }
  .dashboard-sq-banner:hover {
    background-position: right top; }

.dashboard-link-list {
  height: 385px; }

.dashboard-progress {
  height: 385px; }

.dashboard-top-rated {
  height: 300px; }
  @media (max-width: 991px) {
    .dashboard-top-rated {
      height: unset; } }
  .dashboard-top-rated .react-siema-container {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    .dashboard-top-rated .react-siema-container img {
      height: 120px;
      display: flex;
      object-fit: cover;
      width: 100%; }
    .dashboard-top-rated .react-siema-container .react-rater {
      display: inline-block !important; }

.dashboard-search {
  height: 650px;
  background: url(/assets/img/plane.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ffffff; }
  .dashboard-search .card-body {
    padding: 120px 50px 30px 50px; }
    @media (max-width: 1439px) {
      .dashboard-search .card-body {
        padding: 80px 30px 30px 30px; } }
  .dashboard-search .form-container {
    height: 400px;
    border-radius: 0.375rem;
    box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.04);
    padding: 2rem;
    background-color: #ffffff; }

.icon-cards-row {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px; }
  .icon-cards-row .react-siema-container {
    padding-bottom: 0px;
    padding-top: 10px; }
    .icon-cards-row .react-siema-container .icon-row-item {
      padding-left: 5px;
      padding-right: 5px; }
  .icon-cards-row [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px; }
  .icon-cards-row .card-body {
    padding: 2rem 0.5rem; }
  .icon-cards-row .card-text {
    color: #8898aa;
    height: 30px;
    line-height: 26px; }
  .icon-cards-row .lead {
    color: #3949ab;
    margin-bottom: 0; }
  .icon-cards-row i {
    font-size: 46px;
    line-height: 66px;
    color: #3949ab; }
  .icon-cards-row .card {
    transition: box-shadow 1000ms;
    cursor: pointer; }
  @media (max-width: 1439px) {
    .icon-cards-row .card-text {
      height: 48px;
      line-height: 18px; }
    .icon-cards-row .lead {
      font-size: 1.6rem; }
    .icon-cards-row i {
      font-size: 32px;
      line-height: 47px; } }

.log-indicator {
  width: 13px;
  height: 13px;
  border: 2px solid #3949ab;
  border-radius: 14px;
  display: inline-block; }

/* 18.Calendar */
.big-calendar-header {
  margin-bottom: 1em; }

.rbc-month-header {
  min-height: 50px; }

.rbc-today {
  background: initial; }

.calendar-prev-btn,
.calendar-next-btn {
  outline: initial !important;
  box-shadow: initial !important;
  border-radius: 40px !important;
  text-align: center;
  min-width: 30px;
  height: 30px;
  padding: 0.55rem 0;
  background: #3949ab;
  color: #ffffff;
  border: 1px solid #3949ab;
  line-height: 0.9 !important;
  font-size: 0.76rem;
  font-weight: normal !important; }
  .calendar-prev-btn span,
  .calendar-next-btn span {
    line-height: 1 !important;
    font-size: 0.76rem;
    font-weight: normal !important; }
  .calendar-prev-btn:hover,
  .calendar-next-btn:hover {
    background-color: transparent;
    border-color: #5262c5;
    color: #3949ab; }

.calendar-prev-btn {
  margin-right: 5px; }

.calendar-today-btn {
  padding: 0.4em 1.3em !important;
  height: unset !important; }

.rbc-month-row {
  min-height: 5em;
  z-index: 1; }
  @media (max-width: 575px) {
    .rbc-month-row {
      min-height: 3em; } }

.rbc-month-view {
  border: initial; }

.rbc-off-range-bg {
  background: initial; }

.rbc-off-range {
  color: #495057;
  opacity: 0.3; }

.rbc-day-bg + .rbc-day-bg,
.rbc-month-row + .rbc-month-row,
.rbc-header + .rbc-header,
.rbc-header {
  border-color: #ecf0f1 !important; }

.rbc-header {
  padding: 15px 5px;
  color: #3949ab; }

.rbc-date-cell {
  padding: 10px; }
  .rbc-date-cell a {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    font-weight: initial;
    display: inline-block; }

.rbc-date-cell.rbc-now a {
  background: #3949ab;
  color: #ffffff; }

.rbc-event {
  font-size: 0.85em;
  border-radius: 25px;
  text-align: center;
  padding: 0px 5px;
  background: #2980b9; }

.fc-basic-view .fc-body .fc-row {
  min-height: 6em; }
  @media (max-width: 575px) {
    .fc-basic-view .fc-body .fc-row {
      min-height: 3em; } }

.fc-bootstrap4 .fc-day-top .fc-day-number {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px; }

.fc-bootstrap4 td.fc-today {
  background: initial; }
  .fc-bootstrap4 td.fc-today .fc-day-number {
    background: #3949ab;
    color: #ffffff; }

.fc-day-grid-container {
  height: 100%;
  overflow: visible; }

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  padding: 5px; }

.fc-view,
.fc-view > table {
  border-top: initial; }

.fc table {
  border: initial; }

.fc .table-bordered thead th,
.fc .table-bordered thead td {
  border-top: initial;
  border-bottom: initial;
  border-left: initial; }

.fc td:first-of-type,
.fc th:first-of-type {
  border-left: initial;
  border-bottom: initial; }

.fc td:last-of-type,
.fc th:last-of-type {
  border-right: initial;
  border-bottom: initial; }

.fc-body .fc-row:last-of-type td {
  border-bottom: initial; }

.fc tbody > tr > td.fc-widget-content:first-of-type {
  border-left: initial;
  border-right: initial;
  border-bottom: initial; }

.fc table,
.fc .table-bordered th,
.fc .table-bordered td {
  border-color: #ecf0f1 !important; }

.fc-day-header {
  color: #3949ab; }
  .fc-day-header span {
    padding: 10px;
    display: inline-block; }

.fc-event {
  border: initial; }

.fc-event,
.fc-event-dot {
  background-color: #2980b9;
  color: #ffffff !important;
  padding: 1px 6px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center; }

/* 19.Datatable */
.ReactTable {
  border: initial; }

.ReactTable .rt-th,
.ReactTable .rt-td {
  padding-top: 20px;
  padding-bottom: 10px; }

.ReactTable .rt-thead.-header {
  box-shadow: initial; }

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: initial;
  border: initial;
  text-align: left;
  font-weight: 700; }

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #ecf0f1; }

.ReactTable .list-item-heading {
  margin-bottom: 0; }

.ReactTable .rt-tbody .rt-td {
  border-right: initial; }

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #3949ab; }

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #3949ab; }

/* 20.Alerts */
.alert {
  border-radius: 0; }

.alert-primary {
  color: #3949ab;
  background-color: rgba(57, 73, 171, 0.2);
  border-color: rgba(57, 73, 171, 0.1); }

.alert-secondary {
  color: #2980b9;
  background-color: rgba(41, 128, 185, 0.2);
  border-color: rgba(41, 128, 185, 0.1); }

.alert-success {
  color: #27ae60;
  background-color: rgba(39, 174, 96, 0.2);
  border-color: rgba(39, 174, 96, 0.1); }

.alert-info {
  color: #16a085;
  background-color: rgba(22, 160, 133, 0.2);
  border-color: rgba(22, 160, 133, 0.1); }

.alert-warning {
  color: #d35400;
  background-color: rgba(211, 84, 0, 0.2);
  border-color: rgba(211, 84, 0, 0.1); }

.alert-danger {
  color: #c0392b;
  background-color: rgba(192, 57, 43, 0.2);
  border-color: rgba(192, 57, 43, 0.1); }

.alert-dismissible .close {
  text-shadow: initial; }

.alert *[data-notify="title"] {
  display: block;
  font-size: 0.9rem; }

div[data-notify="container"] {
  padding: 18px; }

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto; }

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px; }

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0; }

.notification:hover,
.notification:focus {
  opacity: 1; }

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0); }

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s; }

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0); }

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s; }

/* 21.Forms */
.av-invalid .av-label {
  color: #495057; }

.av-tooltip .invalid-feedback {
  border-radius: 15px;
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  color: #ffffff;
  background: #c0392b;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 1;
  margin-top: -0.2rem;
  left: 50%;
  transform: translateX(-50%); }
  .av-tooltip .invalid-feedback:after {
    content: "";
    position: absolute;
    top: -5px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px #c0392b;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent; }

@media (max-width: 575px) {
  .form-inline .form-group {
    width: 100%; } }

.form-check-label,
.custom-control-label {
  line-height: 24px; }

.react-tagsinput {
  background-color: #ffffff;
  border: 1px solid #ced4da;
  outline: initial;
  box-shadow: initial; }

.react-tagsinput--focused {
  border: 1px solid rgba(57, 73, 171, 0.6); }

.react-tagsinput-tag {
  background: #3949ab;
  border-radius: 15px;
  padding: 0px 10px;
  margin-bottom: 0px;
  display: inline-block;
  font-size: 12px;
  color: #ffffff;
  border: initial; }

.form-control:focus {
  background: #ffffff;
  color: #495057; }

.bootstrap-tagsinput {
  width: 100%; }

.bootstrap-tagsinput input {
  padding: 0; }

.form-control:focus {
  border-color: rgba(57, 73, 171, 0.6); }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.4rem + 3px); }

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: rgba(73, 80, 87, 0.25); }

.custom-control-input:active ~ .custom-control-label::before {
  background-color: transparent; }

.custom-checkbox .custom-control-label.indeterminate::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#ffffff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#ffffff'/%3E%3C/svg%3E"); }

.custom-control-label,
.custom-control-input {
  outline: initial !important;
  box-shadow: initial !important; }

.custom-control-input {
  left: 1px;
  top: 3px;
  opacity: 0;
  z-index: 1; }

.custom-control-label::before {
  border: 1px solid #8898aa;
  background: initial; }

.custom-checkbox
.custom-control-input:indeterminate
~ .custom-control-label::before {
  background-color: #3949ab; }

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3949ab;
  box-shadow: initial !important;
  border: 1px solid #3949ab; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3949ab;
  box-shadow: initial !important; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3949ab;
  box-shadow: initial !important; }

.custom-control-label::after,
.custom-control-label::before {
  box-shadow: initial !important; }

.custom-control-label::before {
  top: 0.25rem; }

.custom-control-label::after {
  top: 0.25rem; }

.btn.rotate-icon-click i {
  transition: transform 0.5s; }

.btn.rotate i {
  transform: rotate(180deg); }

.btn .custom-control-label::before {
  border: 1px solid #ffffff; }

.btn .custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid #ffffff; }

.btn-group-icon {
  line-height: 22px; }

.valid-tooltip,
.invalid-tooltip {
  border-radius: 15px;
  padding: 0.5rem 1rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.76rem; }

.valid-tooltip {
  background-color: #28a745;
  margin-top: -0.2rem; }

.invalid-tooltip {
  background-color: #dc3545;
  margin-top: -0.2rem; }

.valid-tooltip:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -2.5px;
  margin-left: 50%;
  width: 10px;
  height: 5px;
  border-bottom: solid 5px #28a745;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent; }

.invalid-tooltip:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -2.5px;
  margin-left: 50%;
  width: 10px;
  height: 5px;
  border-bottom: solid 5px #dc3545;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent; }

.valid-icon {
  position: absolute;
  bottom: 8px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.76rem;
  line-height: 1;
  border-radius: 0.2rem;
  right: 4px;
  color: #27ae60; }

.invalid-icon {
  position: absolute;
  bottom: 2px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.2rem;
  right: 4px;
  color: #c0392b; }

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-icon,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-icon,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-icon,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-icon {
  display: block; }

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-icon,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-icon,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-icon,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-icon {
  display: block; }

.autosuggest {
  position: relative; }

.react-autosuggest__suggestions-container {
  border-radius: 0.375rem;
  z-index: 20;
  box-shadow: initial;
  margin-top: -1px;
  background: #ffffff;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 100%; }

.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0; }

.react-autosuggest__suggestion {
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  background: #ffffff !important;
  color: #495057 !important; }
  .react-autosuggest__suggestion:hover, .react-autosuggest__suggestion:active {
    background: #5262c5 !important;
    color: #ffffff !important; }

.react-autosuggest__input--open {
  border: 1px solid rgba(57, 73, 171, 0.6) !important; }

.react-autosuggest__suggestions-container--open {
  border: 1px solid rgba(57, 73, 171, 0.6);
  border-top: initial; }

.react-select {
  outline: initial !important;
  box-shadow: none !important; }

.react-select__value-container {
  outline: initial !important;
  box-shadow: none !important;
  padding: 0.375rem 0.75rem !important;
  height: 2.25rem; }

.react-select .react-select__dropdown-indicator {
  color: #8898aa; }

.react-select .react-select__menu-list {
  padding-bottom: 0;
  padding-top: 0; }

.react-select .react-select__single-value,
.react-select .react-select__multi-value__label {
  color: #495057; }

.react-select .react-select__dropdown-indicator,
.react-select
.react-select__control--is-focused
.react-select__dropdown-indicator,
.react-select .react-select__clear-indicator,
.react-select
.react-select__control--is-focused
.react-select__clear-indicator {
  outline: initial;
  box-shadow: initial; }
  .react-select .react-select__dropdown-indicator:active, .react-select .react-select__dropdown-indicator:focus, .react-select .react-select__dropdown-indicator:hover,
  .react-select
.react-select__control--is-focused
.react-select__dropdown-indicator:active,
  .react-select
.react-select__control--is-focused
.react-select__dropdown-indicator:focus,
  .react-select
.react-select__control--is-focused
.react-select__dropdown-indicator:hover,
  .react-select .react-select__clear-indicator:active,
  .react-select .react-select__clear-indicator:focus,
  .react-select .react-select__clear-indicator:hover,
  .react-select
.react-select__control--is-focused
.react-select__clear-indicator:active,
  .react-select
.react-select__control--is-focused
.react-select__clear-indicator:focus,
  .react-select
.react-select__control--is-focused
.react-select__clear-indicator:hover {
    color: #3949ab !important; }

.react-select__control {
  border-radius: 0.375rem !important;
  border: 1px solid #ced4da !important;
  background: #ffffff !important;
  color: #495057;
  background-clip: padding-box;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
  -webkit-transition: box-shadow .15s ease;
  transition: box-shadow .15s ease; }

.react-select__indicator-separator {
  display: none; }

.react-select__dropdown-indicator svg {
  width: 15px;
  height: 15px; }

.react-select__option {
  color: #495057 !important; }
  .react-select__option:hover, .react-select__option:active {
    background: #3949ab !important;
    color: #ffffff !important; }

.react-select__option--is-selected {
  background: #3949ab !important;
  color: #ffffff !important; }

.react-select__control--is-focused {
  border-color: rgba(57, 73, 171, 0.6) !important; }

.react-select__multi-value {
  background: transparent !important;
  border: 1px solid #ced4da; }

.react-select__multi-value__remove:hover,
.react-select__multi-value__remove:active {
  background: transparent !important;
  color: #3949ab !important; }

.react-select .react-select__menu {
  border-radius: 0.375rem !important;
  z-index: 20 !important;
  border: 1px solid rgba(57, 73, 171, 0.6) !important;
  border-top: initial !important;
  margin-top: 0px !important;
  background-color: #ffffff;
  border-width: 1px !important; }

.react-select__single-value {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.react-datepicker {
  background-color: #ffffff;
  border: #ced4da; }

.react-datepicker__input-container input {
  background-color: #ffffff;
  border: #ced4da; }

.react-datepicker__input-container input:focus {
  border-color: rgba(57, 73, 171, 0.6) !important; }

.react-datepicker__input-container input::placeholder {
  color: #495057; }

.react-datepicker-popper {
  z-index: 20; }

.react-datepicker-wrapper {
  width: 100%; }

.react-datepicker__input-container {
  width: 100%; }

.react-datepicker__input-container input {
  font-size: 0.875rem;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  width: 100%;
  outline: initial;
  padding: 0.375rem 0.75rem !important;
  height: calc(2.25rem + 2px);
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
  -webkit-transition: box-shadow .15s ease;
  transition: box-shadow .15s ease; }

.react-datepicker {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  font-family: "Nunito Sans", sans-serif; }

.react-datepicker__header {
  background-color: #ffffff;
  border-bottom: initial; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 35px;
  height: 35px;
  line-height: 2.3rem;
  border-radius: 0 !important;
  margin: 0;
  outline: initial !important; }

.react-datepicker__day:hover {
  background: #ced4da; }

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected {
  background: #3949ab; }

.react-datepicker__day--today {
  font-weight: 400;
  color: #ffffff !important; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: #ffffff; }

.react-datepicker-popper[data-placement^="bottom"]
.react-datepicker__triangle::before {
  border-bottom-color: #ced4da; }

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: #495057; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #495057; }

.react-datepicker__input-container input {
  color: #495057; }

.react-datepicker__time-container {
  border-left: 1px solid #ced4da;
  width: 71px; }

.react-datepicker__time-container .react-datepicker__time {
  background-color: #ffffff;
  color: #495057; }

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  color: #ffffff; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ffffff; }

.react-datepicker-popper[data-placement^="top"]
.react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: #ced4da; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item:hover {
  background: #fbfbff; }

.react-datepicker__triangle {
  left: 30px; }

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background: #3949ab; }

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background: #ced4da; }

.react-datepicker.embedded {
  border: initial;
  width: 100%; }
  .react-datepicker.embedded .react-datepicker__day-name,
  .react-datepicker.embedded .react-datepicker__day,
  .react-datepicker.embedded .react-datepicker__time-name {
    width: 14.28%; }
  .react-datepicker.embedded .react-datepicker__month-container {
    width: 100%; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box {
  width: 87px; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 30px; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item--selected {
  background: #3949ab; }

.react-fine-uploader-gallery-dropzone,
.react-fine-uploader-gallery-nodrop-container {
  min-height: 118px;
  border: 1px solid #ced4da;
  background: #ffffff;
  padding: 10px 10px;
  border-radius: 0.375rem;
  color: #495057;
  padding: 0 !important; }

.react-fine-uploader-gallery-dropzone-active {
  border: 1px solid rgba(57, 73, 171, 0.6) !important; }

.react-fine-uploader-gallery-dropzone button svg {
  fill: #3949ab; }

.react-fine-uploader-gallery-file-input-container {
  display: inline-block;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute !important;
  margin: 0;
  padding: 0; }

.react-fine-uploader-gallery-dropzone-content,
.react-fine-uploader-gallery-nodrop-content {
  font-size: 0.875rem;
  color: #495057;
  opacity: 1;
  top: 50%;
  transform: translateY(-50%); }

.react-fine-uploader-gallery-files {
  padding: 10px;
  margin-bottom: 0; }

.react-fine-uploader-gallery-file {
  display: flex;
  flex-direction: row;
  width: 260px;
  height: unset;
  box-shadow: initial !important;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  border: 1px solid #ced4da;
  float: left;
  margin: 16px; }

.react-fine-uploader-gallery-thumbnail-container {
  display: block;
  height: unset !important;
  text-align: center;
  width: 30% !important;
  height: 62px !important;
  overflow: hidden; }

.react-fine-uploader-gallery-progress-bar-container {
  width: 100%;
  height: 3px;
  background: #ced4da; }

.react-fine-uploader-gallery-progress-bar,
.react-fine-uploader-gallery-total-progress-bar {
  background: #3949ab;
  box-shadow: initial; }

.react-fine-uploader-gallery-status,
.react-fine-uploader-filesize-value,
.react-fine-uploader-filesize-unit {
  font-style: initial;
  color: #3949ab;
  font-size: 0.76rem; }

.react-fine-uploader-filename.react-fine-uploader-gallery-filename {
  font-weight: normal; }

.react-fine-uploader-gallery-total-progress-bar-container {
  display: none;
  background: #ced4da; }

.react-fine-uploader-gallery-thumbnail {
  height: 100%; }

.react-fine-uploader-gallery-upload-failed-icon,
.react-fine-uploader-gallery-upload-success-icon {
  height: 36px;
  width: 36px; }

.react-fine-uploader-gallery-upload-failed-icon {
  fill: #ffffff;
  opacity: 1; }

.react-fine-uploader-gallery-file-footer {
  width: 70%;
  padding: 15px;
  background: #ffffff; }

.react-fine-uploader-gallery-thumbnail-icon-backdrop {
  opacity: 0; }

.react-fine-uploader-gallery-thumbnail-icon-backdrop,
.react-fine-uploader-gallery-upload-failed-icon,
.react-fine-uploader-gallery-upload-success-icon {
  left: 38px;
  position: absolute;
  top: 50%; }

.dropzone .dz-message {
  text-align: center; }

.dropzone.dz-clickable {
  cursor: pointer; }

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  position: relative;
  transform: translateY(-50%);
  top: 50%;
  margin: 0; }

.dropzone .dz-preview.dz-image-preview {
  width: 260px;
  height: unset;
  min-height: unset;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  background: #ffffff;
  color: #495057; }
  .dropzone .dz-preview.dz-image-preview strong {
    font-weight: normal; }
  .dropzone .dz-preview.dz-image-preview .remove {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #3949ab; }
  .dropzone .dz-preview.dz-image-preview .dz-details {
    position: static;
    display: block;
    opacity: 1;
    text-align: left;
    min-width: unset;
    z-index: initial;
    color: #495057; }
  .dropzone .dz-preview.dz-image-preview .dz-error-mark {
    color: #ffffff;
    transform: translateX(-50%) translateY(-50%) !important;
    transition: initial !important;
    animation: initial !important;
    margin-left: 0;
    margin-top: 0; }
    .dropzone .dz-preview.dz-image-preview .dz-error-mark i {
      font-size: 26px; }
  .dropzone .dz-preview.dz-image-preview .dz-progress {
    width: 90%;
    margin-left: 0;
    margin-top: 0;
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    height: 5px; }
    .dropzone .dz-preview.dz-image-preview .dz-progress .dz-upload {
      width: 100%;
      background: #3949ab; }
  .dropzone .dz-preview.dz-image-preview .dz-error-message {
    border-radius: 15px;
    background: #c0392b;
    top: 60px; }
    .dropzone .dz-preview.dz-image-preview .dz-error-message:after {
      border-bottom: 6px solid #c0392b; }

.custom-switch {
  display: block;
  background: #ced4da;
  width: 58px;
  height: 27px;
  border: 1px solid #ced4da; }
  .custom-switch:after {
    width: 18px;
    height: 18px;
    top: 3px;
    margin-left: 3px;
    box-shadow: initial;
    transform: initial !important;
    animation-name: unset !important;
    background: #ffffff; }
  .custom-switch.rc-switch-checked:after {
    left: 30px; }
  .custom-switch.rc-switch-checked.custom-switch-primary {
    background: #3949ab;
    border: 1px solid #3949ab; }
  .custom-switch.rc-switch-checked.custom-switch-secondary {
    background: #2980b9;
    border: 1px solid #2980b9; }
  .custom-switch.custom-switch-primary-inverse {
    border: 1px solid #ced4da; }
  .custom-switch.rc-switch-checked.custom-switch-primary-inverse {
    background: #ffffff;
    border: 1px solid #3949ab; }
    .custom-switch.rc-switch-checked.custom-switch-primary-inverse:after {
      background: #3949ab; }
  .custom-switch.custom-switch-secondary-inverse {
    border: 1px solid #ced4da; }
  .custom-switch.rc-switch-checked.custom-switch-secondary-inverse {
    background: #ffffff;
    border: 1px solid #2980b9; }
    .custom-switch.rc-switch-checked.custom-switch-secondary-inverse:after {
      background: #2980b9; }

.custom-switch .custom-switch-input + .custom-switch-btn {
  background: #ced4da; }

.custom-switch.custom-switch-primary
.custom-switch-input:checked
+ .custom-switch-btn {
  background: #3949ab;
  border: 1px solid #3949ab; }

.custom-switch.custom-switch-secondary
.custom-switch-input:checked
+ .custom-switch-btn {
  background: #2980b9;
  border: 1px solid #2980b9; }

.custom-switch.custom-switch-primary-inverse
.custom-switch-input
+ .custom-switch-btn {
  border: 1px solid #ced4da; }

.custom-switch.custom-switch-primary-inverse
.custom-switch-input:checked
+ .custom-switch-btn {
  background: #ffffff;
  border: 1px solid #3949ab; }

.custom-switch.custom-switch-primary-inverse
.custom-switch-input:checked
+ .custom-switch-btn:after {
  background: #3949ab; }

.custom-switch.custom-switch-secondary-inverse
.custom-switch-input
+ .custom-switch-btn {
  border: 1px solid #ced4da; }

.custom-switch.custom-switch-secondary-inverse
.custom-switch-input:checked
+ .custom-switch-btn {
  background: #ffffff;
  border: 1px solid #2980b9; }

.custom-switch.custom-switch-secondary-inverse
.custom-switch-input:checked
+ .custom-switch-btn:after {
  background: #2980b9; }

.custom-switch .custom-switch-input + .custom-switch-btn:after {
  background: #ffffff; }

.custom-switch .custom-switch-input + .custom-switch-btn {
  border-color: #ced4da; }

.input-group-text {
  border-radius: 0.375rem;
  background-color: white;
  border-color: #ced4da;
  color: #495057;
  font-size: 0.875rem;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem; }

@keyframes autofill {
  to {
    color: #495057;
    background: transparent; } }

input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both; }

input:-webkit-autofill {
  -webkit-text-fill-color: #495057 !important; }

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  border-radius: 0.375rem; }

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  border-radius: 0.375rem; }

.custom-select {
  border-radius: 0.375rem;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem; }

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  outline: initial !important;
  box-shadow: initial !important; }

.custom-select {
  height: calc(2.5rem + 2px); }

.custom-select:focus {
  border-color: #3949ab; }

.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba(57, 73, 171, 0.6); }

.custom-file-label::after {
  background: #ffffff;
  color: #495057;
  border-color: #ced4da; }

.custom-file-input {
  box-shadow: initial !important; }

.custom-file-label {
  background: #ffffff;
  border-color: #ced4da; }

.custom-file-label {
  box-shadow: initial !important;
  border-radius: 0.375rem;
  height: calc(2.5rem + 2px);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem; }

.custom-file {
  height: calc(2.5rem + 2px); }

.custom-file-label:focus,
.custom-file-input:focus {
  border-color: #3949ab; }

.custom-file-label::after {
  height: calc(calc(2.5rem + 2px) - 1px * 2);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem; }

.jumbotron {
  background: initial; }

/* 22.Slider(Range) */
.rc-slider-tooltip {
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  text-align: center;
  top: 150%;
  bottom: unset;
  padding: 5px;
  transform: translateX(-50%); }

.rc-slider-handle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: #ffffff;
  cursor: default;
  border: 1px solid #ced4da !important;
  box-shadow: initial !important; }

.rc-slider-track,
.rc-slider-rail,
.rc-slider-step {
  height: 7px; }

.rc-slider-rail {
  border: 1px solid #ced4da;
  background: #ffffff; }

.rc-slider-handle {
  margin-top: -7px; }

.rc-slider-track {
  background: #3949ab; }

/* 23.Navs */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #3949ab; }

.nav-pills .nav-link {
  border-radius: 50px; }

.nav-link {
  padding: 0.5rem 1rem; }
  @media (max-width: 767px) {
    .nav-link {
      padding: 0.5rem 0.5rem; } }
  .nav-link.active {
    color: #3949ab; }

.page-item {
  padding: 10px; }
  @media (max-width: 767px) {
    .page-item {
      padding: 3px; } }
  .page-item .page-link {
    outline: initial !important;
    box-shadow: initial !important;
    line-height: 1.7;
    border-radius: 40px !important;
    min-width: 38px;
    text-align: center;
    height: 38px;
    padding: 0.55rem 0; }
    @media (max-width: 575px) {
      .page-item .page-link {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem; } }
    .page-item .page-link.next {
      background: #3949ab;
      color: #ffffff;
      border: 1px solid #3949ab; }
    .page-item .page-link.prev, .page-item .page-link.prev {
      background: #3949ab;
      border: 1px solid #3949ab;
      color: #ffffff; }
    .page-item .page-link.first, .page-item .page-link.last {
      background: transparent;
      color: #3949ab;
      border: 1px solid #3949ab;
      border-radius: 30px; }
      .page-item .page-link.first:hover, .page-item .page-link.last:hover {
        background: #3949ab;
        color: #ffffff;
        border: 1px solid #3949ab; }
    .page-item .page-link:hover {
      background-color: transparent;
      border-color: #5262c5;
      color: #3949ab; }
  .page-item.active .page-link {
    background: transparent;
    border: 1px solid #3949ab;
    color: #3949ab; }
  .page-item.disabled .page-link {
    border-color: #ced4da;
    color: #ced4da;
    background: transparent; }

.page-link {
  background-color: transparent;
  border-color: transparent;
  color: #495057; }

.btn-sm.page-link {
  padding: 0.5rem 0.5rem; }

.pagination-lg .page-item {
  padding: 15px; }
  @media (max-width: 767px) {
    .pagination-lg .page-item {
      padding: 3px; } }
  .pagination-lg .page-item .page-link {
    min-width: 50px;
    height: 50px; }
    @media (max-width: 767px) {
      .pagination-lg .page-item .page-link {
        min-width: 38px;
        height: 38px;
        padding: 0.55rem 0;
        line-height: 1.5;
        font-size: 0.875rem; } }
    @media (max-width: 575px) {
      .pagination-lg .page-item .page-link {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem; } }

.pagination-sm .page-item {
  padding: 6px; }
  @media (max-width: 767px) {
    .pagination-sm .page-item {
      padding: 3px; } }
  .pagination-sm .page-item .page-link {
    min-width: 30px;
    height: 30px;
    line-height: 0.9;
    font-size: 0.76rem; }

/* 24.Tooltip and popover */
.popover {
  border-radius: 0.375rem;
  background-color: #ffffff;
  border-color: #ced4da; }
  .popover .popover-body {
    color: #495057; }

.popover-header {
  background-color: transparent;
  border-bottom: initial; }

.tooltip-inner {
  padding: 0.5rem 0.75rem;
  color: #495057;
  background-color: #ffffff;
  border-radius: 0.1rem;
  border: 1px solid #ced4da; }

.tooltip.show {
  opacity: 1; }

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  border-right-color: #ced4da; }

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #ffffff; }

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  border-left-color: #ced4da; }

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #ffffff; }

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #ced4da; }

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #ffffff; }

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  border-top-color: #ced4da; }

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #ffffff; }

.tooltip .arrow::before,
.tooltip .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: #ced4da; }

.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #ffffff; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.4rem 0.4rem 0.4rem 0; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: #ced4da; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0; }

.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #ffffff; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.4rem 0.4rem 0; }

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: #ced4da; }

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #ffffff; }

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: #ced4da; }

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  right: 0;
  border-left-color: #ffffff; }

.search-sm {
  position: relative; }
  .search-sm input {
    background: none;
    outline: initial !important;
    border: 1px solid #8898aa;
    border-radius: 15px;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    font-size: 0.76rem;
    line-height: 1.3;
    color: #495057; }
  .search-sm:after {
    font-family: "simple-line-icons";
    content: "\e090";
    font-size: 14px;
    border-radius: 10px;
    color: #8898aa;
    position: absolute;
    width: 25px;
    right: 2px;
    text-align: center;
    cursor: pointer;
    top: 2px; }

.notification {
  border-radius: 0.375rem;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  padding: 25px 25px 25px 25px; }

.notification:before {
  content: "";
  display: none; }

.notification-primary {
  color: #3949ab;
  background-color: #ffffff;
  border: 1px solid #3949ab;
  opacity: 1; }
  .notification-primary .notification-message .title {
    color: #3949ab; }
  .notification-primary .notification-message .message {
    color: #495057; }
  .notification-primary.filled {
    color: #ffffff;
    background-color: #3949ab; }
    .notification-primary.filled .notification-message .title {
      color: #ffffff; }
    .notification-primary.filled .notification-message .message {
      color: #ffffff; }

.notification-secondary {
  color: #2980b9;
  background-color: #ffffff;
  border: 1px solid #2980b9;
  opacity: 1; }
  .notification-secondary .notification-message .title {
    color: #2980b9; }
  .notification-secondary .notification-message .message {
    color: #8898aa; }
  .notification-secondary.filled {
    color: #ffffff;
    background-color: #2980b9; }
    .notification-secondary.filled .notification-message .title {
      color: #ffffff; }
    .notification-secondary.filled .notification-message .message {
      color: #ffffff; }

.notification-info {
  color: #16a085;
  background-color: #ffffff;
  border: 1px solid #16a085;
  opacity: 1; }
  .notification-info .notification-message .title {
    color: #16a085; }
  .notification-info .notification-message .message {
    color: #8898aa; }
  .notification-info.filled {
    color: #ffffff;
    background-color: #16a085; }
    .notification-info.filled .notification-message .title {
      color: #ffffff; }
    .notification-info.filled .notification-message .message {
      color: #ffffff; }

.notification-success {
  color: #27ae60;
  background-color: #ffffff;
  border: 1px solid #27ae60;
  opacity: 1; }
  .notification-success .notification-message .title {
    color: #27ae60; }
  .notification-success .notification-message .message {
    color: #8898aa; }
  .notification-success.filled {
    color: #ffffff;
    background-color: #27ae60; }
    .notification-success.filled .notification-message .title {
      color: #ffffff; }
    .notification-success.filled .notification-message .message {
      color: #ffffff; }

.notification-warning {
  color: #d35400;
  background-color: #ffffff;
  border: 1px solid #d35400;
  opacity: 1; }
  .notification-warning .notification-message .title {
    color: #d35400; }
  .notification-warning .notification-message .message {
    color: #8898aa; }
  .notification-warning.filled {
    color: #ffffff;
    background-color: #d35400; }
    .notification-warning.filled .notification-message .title {
      color: #ffffff; }
    .notification-warning.filled .notification-message .message {
      color: #ffffff; }

.notification-error {
  color: #c0392b;
  background-color: #ffffff;
  border: 1px solid #c0392b;
  opacity: 1; }
  .notification-error .notification-message .title {
    color: #c0392b; }
  .notification-error .notification-message .message {
    color: #8898aa; }
  .notification-error.filled {
    color: #ffffff;
    background-color: #c0392b; }
    .notification-error.filled .notification-message .title {
      color: #ffffff; }
    .notification-error.filled .notification-message .message {
      color: #ffffff; }

/* 25.Progress */
.progress {
  background-color: #ecf0f1;
  height: 3px; }

.progress-bar {
  background-color: #3949ab; }

.CircularProgressbar-text {
  fill: #495057 !important; }

.progress-bar-circle {
  width: 54px;
  height: 54px; }
  .progress-bar-circle svg path:first-of-type {
    stroke: #ced4da; }
  .progress-bar-circle svg path:last-of-type {
    stroke: #3949ab; }
  .progress-bar-circle.progress-bar-banner svg path:first-of-type {
    stroke: #263172; }
  .progress-bar-circle.progress-bar-banner svg path:last-of-type {
    stroke: #ffffff; }

.progress-banner {
  height: 200px;
  background-image: linear-gradient(to right top, #2980b9, #3498db, #3949ab);
  transition: 0.5s;
  background-size: 200% auto;
  cursor: pointer; }
  .progress-banner .CircularProgressbar .CircularProgressbar-text {
    fill: #ffffff !important; }
  .progress-banner .lead {
    font-size: 1.5rem;
    margin-bottom: 0.5rem; }
    @media (max-width: 1199px) {
      .progress-banner .lead {
        font-size: 1.2rem;
        margin-bottom: 0.2rem; } }
  .progress-banner i {
    font-size: 2.7rem;
    margin-bottom: 1rem; }
    @media (max-width: 1199px) {
      .progress-banner i {
        font-size: 2rem;
        margin-bottom: 0.2rem; } }
  .progress-banner .progress-bar-circle.progress-bar-banner {
    width: 120px;
    height: 120px; }
    .progress-banner .progress-bar-circle.progress-bar-banner svg path:last-of-type {
      stroke: #e6e6e6; }
    @media (max-width: 1199px) {
      .progress-banner .progress-bar-circle.progress-bar-banner {
        width: 80px;
        height: 80px; } }
  .progress-banner .progress-bar-banner .progressbar-text {
    color: #495057 !important;
    font-size: 1.7rem;
    width: 110px;
    font-weight: 300; }
    @media (max-width: 1199px) {
      .progress-banner .progress-bar-banner .progressbar-text {
        font-size: 1.2rem;
        margin-bottom: 0.2rem; } }
  .progress-banner:hover {
    background-position: right top; }

/* 26.Rating */
.br-theme-bootstrap-stars .br-widget a.br-active:after {
  color: #3949ab; }

.br-theme-bootstrap-stars .br-widget a.br-selected:after {
  color: #3949ab; }

.react-rater {
  display: block !important;
  font-size: 16px;
  color: #ced4da; }

.react-rater-star {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "simple-line-icons";
  color: #ced4da;
  margin-right: 3px;
  font-size: 18px; }
  .react-rater-star.is-active, .react-rater-star.will-be-active {
    color: #3949ab; }
  .react-rater-star:after {
    content: "\e09b"; }

/* 27.Sortable */
.sortable {
  cursor: default; }

.sortable span {
  vertical-align: middle; }

.sortable-ghost {
  opacity: 0.5; }

/* 28.Spinner */
.spinner {
  width: 36px;
  text-align: center; }

.spinner > span {
  width: 6px;
  height: 6px;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out both;
  animation: sk-bouncedelay 1.2s infinite ease-in-out both; }

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

/* 29.Croppper */
.cropper-line {
  background-color: #3949ab; }

.cropper-point {
  background-color: #3949ab; }

.cropper-view-box {
  outline-color: #3949ab;
  outline: #3949ab; }

.cropper-preview {
  overflow: hidden; }

#cropperContainer {
  height: 300px;
  display: none; }

/* 30.Modal */
.modal .modal-header,
.modal .modal-body,
.modal .modal-footer {
  padding: 1.75rem; }

.modal .modal-header {
  border-bottom: 1px solid #ced4da; }

.modal .modal-footer {
  border-top: 1px solid #ced4da; }

.modal .close {
  color: #495057;
  text-shadow: initial; }

.modal-content {
  border: initial;
  border-radius: 0.1rem;
  background: #ffffff; }

.modal-dialog {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }

.modal-right {
  padding-right: 0 !important; }
  .modal-right .modal-dialog {
    margin: 0 auto;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-width: 380px; }
  .modal-right .modal-content {
    min-height: 100%; }
  .modal-right .modal-header {
    height: 58px; }
    @media (max-width: 767px) {
      .modal-right .modal-header {
        height: 58px; } }
  .modal-right .modal-footer {
    justify-content: center; }
  .modal-right .modal.fade .modal-dialog {
    transform: translate(25%, 0) !important; }
  .modal-right .modal.show .modal-dialog {
    transform: translate(0, 0) !important; }

/* 31.Authorization */
.auth-card {
  display: flex;
  flex-direction: row;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .auth-card .image-side {
    width: 40%;
    background: url("/assets/img/login-balloon.jpg") no-repeat center top;
    background-size: cover;
    padding: 80px 40px; }
    .auth-card .image-side .h3 {
      line-height: 0.875rem; }
  .auth-card .form-side {
    width: 60%;
    padding: 80px; }
  @media (max-width: 991px) {
    .auth-card {
      flex-direction: column; }
      .auth-card .image-side {
        width: 100%;
        padding: 60px; }
      .auth-card .form-side {
        width: 100%;
        padding: 60px; } }
  @media (max-width: 767px) {
    .auth-card p.h2 {
      font-size: 1.6rem; } }
  @media (max-width: 575px) {
    .auth-card {
      flex-direction: column; }
      .auth-card .image-side {
        padding: 35px 30px; }
      .auth-card .form-side {
        padding: 35px 30px; }
      .auth-card .logo-single {
        margin-bottom: 20px; }
      .auth-card p.h2 {
        font-size: 1.4rem; } }

/* 32.Html Editors */
.html-editor {
  height: 350px; }

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #3949ab; }

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #3949ab; }

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter {
  stroke: #3949ab; }

.ql-toolbar.ql-snow {
  border-color: #ced4da; }

.ql-container.ql-snow {
  border-color: #ced4da; }

.html-editor-bubble {
  height: 200px; }

.html-editor-bubble .ql-editor {
  border: 1px solid #ced4da; }

.ql-tooltip {
  z-index: 4; }

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  height: 350px; }

.ck.ck-content.ck-editor__editable.ck-rounded-corners {
  box-shadow: initial !important; }

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-focused {
  border-color: #ced4da; }

.RichEditor-root {
  background: #ffffff;
  border: 1px solid #ced4da;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  padding: 15px; }

.RichEditor-editor {
  border-top: 1px solid #ced4da;
  cursor: text;
  font-size: 14px;
  margin-top: 10px; }

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px; }

.RichEditor-editor .public-DraftEditor-content {
  min-height: 200px; }

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none; }

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #ced4da;
  color: #8898aa;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px; }

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: #8898aa;
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px; }

.RichEditor-controls {
  font-family: "Nunito Sans", sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
  user-select: none; }

.RichEditor-styleButton {
  color: #ced4da;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
  color: #495057;
  font-size: 0.76rem; }

.RichEditor-activeButton {
  color: #495057; }

.ql-editor {
  height: 200px; }

.ql-container.ql-bubble {
  border: 1px solid #ced4da; }

/* 33.Icons */
.simple-line-icons .glyph,
.mind-icons .glyph {
  width: 14.28%;
  text-align: center;
  float: left;
  height: 100px; }
  .simple-line-icons .glyph .glyph-icon,
  .simple-line-icons .glyph .fa,
  .mind-icons .glyph .glyph-icon,
  .mind-icons .glyph .fa {
    font-size: 32px; }
  .simple-line-icons .glyph .author-name,
  .mind-icons .glyph .author-name {
    display: none; }
  .simple-line-icons .glyph .class-name,
  .mind-icons .glyph .class-name {
    font-size: 0.76rem;
    color: #8898aa !important; }
  @media (max-width: 1199px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 16.66%; } }
  @media (max-width: 991px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 20%; } }
  @media (max-width: 767px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 25%; } }
  @media (max-width: 575px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 50%; } }

.chart-container {
  height: 300px; }

.theme-colors {
  width: 280px;
  position: fixed;
  z-index: 1030;
  top: 50%;
  right: 0;
  background: #ffffff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  transform: translate(280px, -50%);
  transition: transform 0.4s ease-out;
  padding-top: 10px;
  padding-bottom: 10px; }
  .theme-colors .theme-button {
    position: absolute;
    left: -34px;
    background: #ffffff;
    padding: 13px 7px 13px 7px;
    border-radius: 0.2rem;
    color: #495057;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
    font-size: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: #3949ab; }
  .theme-colors .theme-color {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 20px;
    transition: background 0.25s; }
    .theme-colors .theme-color.active, .theme-colors .theme-color:hover {
      background: #ffffff; }
  .theme-colors .theme-color-purple {
    border: 3px solid #8965e0;
    background: #8965e0; }
  .theme-colors .theme-color-blue {
    border: 3px solid #edefff;
    background: #edefff; }
  .theme-colors .theme-color-orange {
    border: 3px solid #e67e22;
    background: #e67e22; }
  .theme-colors .theme-color-green {
    border: 3px solid #2ecc71;
    background: #2ecc71; }
  .theme-colors .theme-color-red {
    border: 3px solid #e74c3c;
    background: #e74c3c; }
  .theme-colors.shown {
    transform: translate(0, -50%); }

/* 34.Floating Label */
.has-float-label {
  display: block;
  position: relative; }

.has-float-label label::after,
.has-float-label > span::after {
  background: #ffffff !important; }

.has-top-label label,
.has-top-label > span,
.has-float-label label,
.has-float-label > span {
  color: rgba(73, 80, 87, 0.7); }

.has-float-label label,
.has-float-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 90%;
  opacity: 1;
  top: -0.4em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px; }

.has-float-label label::after,
.has-float-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 5px;
  top: 3px;
  left: -0.2em;
  right: -0.2em;
  z-index: -1; }

.has-float-label .form-control::-webkit-input-placeholder {
  opacity: 1; }

.has-float-label .form-control::-moz-placeholder {
  opacity: 1; }

.has-float-label .form-control:-ms-input-placeholder {
  opacity: 1; }

.has-float-label .form-control::placeholder {
  opacity: 1; }

.has-float-label
.form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0; }

.has-float-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0; }

.has-float-label
.form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0; }

.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0; }

.input-group .has-float-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.input-group .has-float-label .form-control {
  width: 100%; }

.input-group .has-float-label:not(:last-child),
.input-group .has-float-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0; }

.input-group .has-float-label:not(:first-child),
.input-group .has-float-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
  padding: 1.7rem 0.75rem 0.5rem 0.75rem !important; }

.has-top-label {
  display: block;
  position: relative; }
  .has-top-label .react-tagsinput-input {
    padding: 0;
    margin: 0;
    font-size: 0.875rem;
    line-height: 1; }

.has-top-label .react-select__value-container .css-rsyb7x {
  margin: 0 !important;
  padding: 0 !important; }

.has-top-label label,
.has-top-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 76%;
  opacity: 1;
  top: 0.7rem;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
  font-weight: 600; }

.has-top-label label::after,
.has-top-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 2px;
  top: 50%;
  left: -0.2em;
  right: -0.2em;
  z-index: -1; }

.has-top-label .form-control::-webkit-input-placeholder {
  opacity: 1; }

.has-top-label .form-control::-moz-placeholder {
  opacity: 1; }

.has-top-label .form-control:-ms-input-placeholder {
  opacity: 1; }

.has-top-label .form-control::placeholder {
  opacity: 1; }

.has-top-label
.form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0; }

.has-top-label .form-control:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0; }

.has-top-label
.form-control:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0; }

.has-top-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0; }

.has-top-label .form-control:placeholder-shown:not(:focus) + * {
  font-size: 150%;
  opacity: 0.5;
  top: 0.3em; }

.has-top-label .react-select__single-value {
  top: unset !important;
  bottom: -3px !important;
  margin-left: 0 !important; }

.input-group .has-top-label {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  margin-bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center; }

.input-group .has-top-label .form-control {
  width: 100%; }

.input-group .has-top-label:not(:last-child),
.input-group .has-top-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0; }

.input-group .has-top-label:not(:first-child),
.input-group .has-top-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
  min-height: calc(3.3rem + 2px) !important; }

.form-group.has-top-label select.form-control:not([size]):not([multiple]) {
  height: calc(3.3rem + 2px);
  padding: 1.7rem 0.75rem 0.5rem 0.5rem; }

/* 35.Loading */
.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(57, 73, 171, 0.2);
  border-radius: 50%;
  border-top-color: #3949ab;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1; }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

/* 36.Context Menu */
.react-contextmenu {
  box-shadow: initial;
  border-radius: 0.375rem;
  background: #ffffff;
  border: 1px solid rgba(73, 80, 87, 0.15);
  padding: 0.5rem 0;
  opacity: 0; }

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999; }

.react-contextmenu-item {
  padding: 0.5rem 1.5rem;
  background: #ffffff;
  color: #495057;
  cursor: pointer; }
  .react-contextmenu-item span {
    font-family: "Nunito Sans", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    margin-left: 0.5rem;
    line-height: 15px; }
  .react-contextmenu-item:hover {
    color: #495057;
    text-decoration: none;
    background-color: #fbfbff; }

.react-contextmenu-item:not(.react-contextmenu-item--disabled):hover {
  color: #495057;
  text-decoration: none;
  background-color: #fbfbff; }

.card.react-contextmenu--visible,
.card.active {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }

/* VHMS */
.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 600 !important; }

.font-weight-strong {
  font-weight: 700 !important; }

small, .small {
  font-size: 90%; }

.fixed-footer {
  position: absolute;
  left: 0;
  right: 0; }

body.landing {
  height: 100%; }
  body.landing main {
    margin: 0 !important;
    min-height: 100%;
    padding-top: 58px !important; }
    body.landing main .container {
      height: 100%; }

.lead {
  font-weight: 400; }

.heading-small {
  text-transform: uppercase;
  font-weight: 600; }

.invalid-feedback {
  font-size: 90%; }

.dropdown-item {
  padding: 0.5rem 1rem;
  font-weight: 600; }

.preloader {
  background-color: #3949ab;
  /* background: -webkit-linear-gradient(3deg, #3949ab 0, #11cdef 100%) !important;
    background: linear-gradient(87deg, #3949ab 0, #11cdef 100%) !important; */
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.spinner {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 50px; }

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #FFF;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1s infinite ease-in-out both;
  animation: sk-bouncedelay 1s infinite ease-in-out both; }

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.navbar {
  background: #3949ab;
  padding-right: 0;
  padding-left: 0;
  z-index: 100; }
  .navbar .search {
    border-radius: 5px; }
  .navbar .header-icon {
    color: #ffffff; }
    .navbar .header-icon:hover {
      color: #f6f9fc; }
  .navbar .menu-button-mobile {
    color: #8898aa;
    text-align: center; }
    .navbar .menu-button-mobile svg {
      fill: #ffffff;
      height: 16px; }
    @media (max-width: 767px) {
      .navbar .menu-button-mobile {
        width: 90px; } }
  .navbar .menu-button {
    fill: #8898aa;
    width: 100px; }
    .navbar .menu-button .main {
      fill: #ffffff; }
    .navbar .menu-button .sub {
      fill: #ffffff !important; }
    .navbar .menu-button:hover {
      fill: #2980b9 !important; }
    .navbar .menu-button svg {
      height: 16px; }
  .navbar .navbar-logo {
    width: 94px;
    height: 30px; }
    .navbar .navbar-logo .logo {
      background-size: 94px 30px; }
    .navbar .navbar-logo .logo-mobile {
      background-size: 94px 30px; }
  .navbar .user .name {
    color: #ffffff; }
  @media (max-width: 767px) {
    .navbar {
      height: 58px; } }

.fixed-navbar {
  position: absolute;
  left: 0;
  right: 0; }

.card {
  border-radius: 0.375rem; }

.auth-card .image-side {
  border-radius: .325rem 0 0 .325rem; }
  .auth-card .image-side p {
    margin-bottom: 0; }

.auth-card .form-side {
  padding: 30px; }

@media (max-width: 991px) {
  .auth-card {
    margin-top: 80px;
    margin-bottom: 80px; }
    .auth-card .image-side {
      padding: 45px 30px;
      border-radius: .325rem .325rem 0 0; }
    .auth-card .form-side {
      padding: 30px; } }

@media (max-width: 575px) {
  .auth-card .image-side {
    padding: 30px; } }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 768px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card .card-header-dashboard {
  border-bottom: 0;
  font-size: 1.25rem;
  font-weight: 600;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.card .card-top-buttons {
  padding: 0.875rem;
  right: 0;
  top: 0; }

.card-header:first-child {
  border-radius: 0.375rem 0.375rem 0 0; }

.form-control-label {
  color: #7f8c8d;
  font-size: 0.875rem;
  font-weight: 600; }

.form-control {
  font-size: 0.875rem;
  border-radius: 0.375rem; }
  .form-control:focus::placeholder {
    color: #95a5a6; }

textarea[resize="none"] {
  resize: none !important; }

textarea[resize="both"] {
  resize: both !important; }

textarea[resize="vertical"] {
  resize: vertical !important; }

textarea[resize="horizontal"] {
  resize: horizontal !important; }

.form-control-muted {
  background-color: #F7FAFE;
  border-color: #F7FAFE;
  box-shadow: none; }
  .form-control-muted:focus {
    background-color: #fcfdff; }

.form-control-alternative {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
  transition: box-shadow .15s ease; }
  .form-control-alternative:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important; }

.form-control-lg {
  font-size: 0.875rem; }

.btn {
  position: relative;
  transition: all 0.15s ease;
  font-size: 0.875rem;
  font-weight: 600;
  will-change: transform; }
  .btn:hover {
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    transform: translateY(-1px); }
  .btn:not(:last-child) {
    margin-right: .5rem; }
  .btn i:not(:first-child),
  .btn svg:not(:first-child) {
    margin-left: .5rem; }
  .btn i:not(:last-child),
  .btn svg:not(:last-child) {
    margin-right: .5rem; }

.btn-empty:hover {
  box-shadow: none;
  transform: translateY(0); }

.btn-empty:focus {
  box-shadow: none; }

.btn-group .btn,
.input-group .btn {
  margin-right: 0;
  transform: translateY(0); }

.btn-sm {
  font-size: 0.75rem; }

.btn-lg {
  font-size: 0.925rem; }

[class*="btn-outline-"] {
  border-width: 1px; }

.btn-inner--icon i:not(.fa) {
  position: relative;
  top: 2px; }

.btn-link {
  font-weight: 600;
  box-shadow: none; }
  .btn-link:hover {
    box-shadow: none;
    transform: none; }

.btn-neutral {
  color: #3949ab; }

.btn-icon .btn-inner--icon img {
  width: 20px; }

.btn-icon .btn-inner--text:not(:first-child) {
  margin-left: 0.75em; }

.btn-icon .btn-inner--text:not(:last-child) {
  margin-right: 0.75em; }

.btn-icon-only {
  width: 2.375rem;
  height: 2.375rem;
  padding: 0; }

a.btn-icon-only {
  line-height: 2.5; }

.btn-icon-only.btn-sm {
  width: 2rem;
  height: 2rem; }

.btn-default {
  color: #ffffff;
  background-color: #3949ab;
  border-color: #3949ab;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-default:hover {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
  .btn-default:focus, .btn-default.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(57, 73, 171, 0.5); }
  .btn-default.disabled, .btn-default:disabled {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
  .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active,
  .show > .btn-default.dropdown-toggle {
    color: #ffffff;
    background-color: #2c3985;
    border-color: #3949ab; }
    .btn-default:not(:disabled):not(.disabled):active:focus, .btn-default:not(:disabled):not(.disabled).active:focus,
    .show > .btn-default.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(57, 73, 171, 0.5); }

.btn-primary {
  color: #ffffff;
  background-color: #3949ab;
  border-color: #3949ab;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(57, 73, 171, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #2c3985;
    border-color: #3949ab; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(57, 73, 171, 0.5); }

.btn-secondary {
  color: #ffffff;
  background-color: #7f8c8d;
  border-color: #7f8c8d;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #7f8c8d;
    border-color: #7f8c8d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(127, 140, 141, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #ffffff;
    background-color: #7f8c8d;
    border-color: #7f8c8d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #667273;
    border-color: #7f8c8d; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(127, 140, 141, 0.5); }

.btn-success {
  color: #ffffff;
  background-color: #27ae60;
  border-color: #27ae60;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-success:hover {
    color: #ffffff;
    background-color: #27ae60;
    border-color: #27ae60; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(39, 174, 96, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #ffffff;
    background-color: #27ae60;
    border-color: #27ae60; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #1e8449;
    border-color: #27ae60; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(39, 174, 96, 0.5); }

.btn-info {
  color: #ffffff;
  background-color: #16a085;
  border-color: #16a085;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-info:hover {
    color: #ffffff;
    background-color: #16a085;
    border-color: #16a085; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(22, 160, 133, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #ffffff;
    background-color: #16a085;
    border-color: #16a085; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #107360;
    border-color: #16a085; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(22, 160, 133, 0.5); }

.btn-warning {
  color: #ffffff;
  background-color: #d35400;
  border-color: #d35400;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-warning:hover {
    color: #ffffff;
    background-color: #d35400;
    border-color: #d35400; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(211, 84, 0, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #ffffff;
    background-color: #d35400;
    border-color: #d35400; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #a04000;
    border-color: #d35400; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(211, 84, 0, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #c0392b;
  border-color: #c0392b;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #c0392b;
    border-color: #c0392b; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(192, 57, 43, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #c0392b;
    border-color: #c0392b; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #962d22;
    border-color: #c0392b; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(192, 57, 43, 0.5); }

.btn-white {
  color: #1f3448;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-white:hover {
    color: #1f3448;
    background-color: white;
    border-color: white; }
  .btn-white:focus, .btn-white.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #1f3448;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #1f3448;
    background-color: #e6e6e6;
    border-color: white; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-neutral {
  color: #1f3448;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-neutral:hover {
    color: #1f3448;
    background-color: white;
    border-color: white; }
  .btn-neutral:focus, .btn-neutral.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-neutral.disabled, .btn-neutral:disabled {
    color: #1f3448;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-neutral:not(:disabled):not(.disabled):active, .btn-neutral:not(:disabled):not(.disabled).active,
  .show > .btn-neutral.dropdown-toggle {
    color: #1f3448;
    background-color: #e6e6e6;
    border-color: white; }
    .btn-neutral:not(:disabled):not(.disabled):active:focus, .btn-neutral:not(:disabled):not(.disabled).active:focus,
    .show > .btn-neutral.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #1f3448;
  border-color: #1f3448;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #1f3448;
    border-color: #1f3448; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(31, 52, 72, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #1f3448;
    border-color: #1f3448; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #101a24;
    border-color: #1f3448; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(31, 52, 72, 0.5); }

.btn-darker {
  color: #ffffff;
  background-color: #080d13;
  border-color: #080d13;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-darker:hover {
    color: #ffffff;
    background-color: #080d13;
    border-color: #080d13; }
  .btn-darker:focus, .btn-darker.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(8, 13, 19, 0.5); }
  .btn-darker.disabled, .btn-darker:disabled {
    color: #ffffff;
    background-color: #080d13;
    border-color: #080d13; }
  .btn-darker:not(:disabled):not(.disabled):active, .btn-darker:not(:disabled):not(.disabled).active,
  .show > .btn-darker.dropdown-toggle {
    color: #ffffff;
    background-color: black;
    border-color: #080d13; }
    .btn-darker:not(:disabled):not(.disabled):active:focus, .btn-darker:not(:disabled):not(.disabled).active:focus,
    .show > .btn-darker.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(8, 13, 19, 0.5); }

.btn-primary-light {
  color: #1f3448;
  background-color: #edefff;
  border-color: #edefff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-primary-light:hover {
    color: #1f3448;
    background-color: #edefff;
    border-color: #edefff; }
  .btn-primary-light:focus, .btn-primary-light.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(237, 239, 255, 0.5); }
  .btn-primary-light.disabled, .btn-primary-light:disabled {
    color: #1f3448;
    background-color: #edefff;
    border-color: #edefff; }
  .btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-primary-light.dropdown-toggle {
    color: #1f3448;
    background-color: #bac2ff;
    border-color: #edefff; }
    .btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-light.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(237, 239, 255, 0.5); }

.btn-info-light {
  color: #ffffff;
  background-color: #1abc9c;
  border-color: #1abc9c;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-info-light:hover {
    color: #ffffff;
    background-color: #1abc9c;
    border-color: #1abc9c; }
  .btn-info-light:focus, .btn-info-light.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(26, 188, 156, 0.5); }
  .btn-info-light.disabled, .btn-info-light:disabled {
    color: #ffffff;
    background-color: #1abc9c;
    border-color: #1abc9c; }
  .btn-info-light:not(:disabled):not(.disabled):active, .btn-info-light:not(:disabled):not(.disabled).active,
  .show > .btn-info-light.dropdown-toggle {
    color: #ffffff;
    background-color: #148f77;
    border-color: #1abc9c; }
    .btn-info-light:not(:disabled):not(.disabled):active:focus, .btn-info-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info-light.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(26, 188, 156, 0.5); }

.btn-outline-default {
  color: #3949ab;
  background-color: transparent;
  background-image: none;
  border-color: #3949ab; }
  .btn-outline-default:hover {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
  .btn-outline-default:focus, .btn-outline-default.focus {
    box-shadow: 0 0 0 0 rgba(57, 73, 171, 0.5); }
  .btn-outline-default.disabled, .btn-outline-default:disabled {
    color: #3949ab;
    background-color: transparent; }
  .btn-outline-default:not(:disabled):not(.disabled):active, .btn-outline-default:not(:disabled):not(.disabled).active,
  .show > .btn-outline-default.dropdown-toggle {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
    .btn-outline-default:not(:disabled):not(.disabled):active:focus, .btn-outline-default:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-default.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(57, 73, 171, 0.5); }

.btn-outline-primary {
  color: #3949ab;
  background-color: transparent;
  background-image: none;
  border-color: #3949ab; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(57, 73, 171, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #3949ab;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(57, 73, 171, 0.5); }

.btn-outline-secondary {
  color: #7f8c8d;
  background-color: transparent;
  background-image: none;
  border-color: #7f8c8d; }
  .btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #7f8c8d;
    border-color: #7f8c8d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(127, 140, 141, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #7f8c8d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #7f8c8d;
    border-color: #7f8c8d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(127, 140, 141, 0.5); }

.btn-outline-success {
  color: #27ae60;
  background-color: transparent;
  background-image: none;
  border-color: #27ae60; }
  .btn-outline-success:hover {
    color: #ffffff;
    background-color: #27ae60;
    border-color: #27ae60; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(39, 174, 96, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #27ae60;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #27ae60;
    border-color: #27ae60; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(39, 174, 96, 0.5); }

.btn-outline-info {
  color: #16a085;
  background-color: transparent;
  background-image: none;
  border-color: #16a085; }
  .btn-outline-info:hover {
    color: #ffffff;
    background-color: #16a085;
    border-color: #16a085; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #16a085;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #16a085;
    border-color: #16a085; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.5); }

.btn-outline-warning {
  color: #d35400;
  background-color: transparent;
  background-image: none;
  border-color: #d35400; }
  .btn-outline-warning:hover {
    color: #ffffff;
    background-color: #d35400;
    border-color: #d35400; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(211, 84, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #d35400;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #d35400;
    border-color: #d35400; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(211, 84, 0, 0.5); }

.btn-outline-danger {
  color: #c0392b;
  background-color: transparent;
  background-image: none;
  border-color: #c0392b; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #c0392b;
    border-color: #c0392b; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(192, 57, 43, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #c0392b;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #c0392b;
    border-color: #c0392b; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(192, 57, 43, 0.5); }

.btn-outline-white {
  color: #ffffff;
  background-color: transparent;
  background-image: none;
  border-color: #ffffff; }
  .btn-outline-white:hover {
    color: #1f3448;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #1f3448;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-outline-neutral {
  color: #ffffff;
  background-color: transparent;
  background-image: none;
  border-color: #ffffff; }
  .btn-outline-neutral:hover {
    color: #1f3448;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-neutral:focus, .btn-outline-neutral.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-outline-neutral.disabled, .btn-outline-neutral:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-neutral:not(:disabled):not(.disabled):active, .btn-outline-neutral:not(:disabled):not(.disabled).active,
  .show > .btn-outline-neutral.dropdown-toggle {
    color: #1f3448;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-neutral:not(:disabled):not(.disabled):active:focus, .btn-outline-neutral:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-neutral.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-outline-dark {
  color: #1f3448;
  background-color: transparent;
  background-image: none;
  border-color: #1f3448; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #1f3448;
    border-color: #1f3448; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(31, 52, 72, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #1f3448;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #1f3448;
    border-color: #1f3448; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(31, 52, 72, 0.5); }

.btn-outline-darker {
  color: #080d13;
  background-color: transparent;
  background-image: none;
  border-color: #080d13; }
  .btn-outline-darker:hover {
    color: #ffffff;
    background-color: #080d13;
    border-color: #080d13; }
  .btn-outline-darker:focus, .btn-outline-darker.focus {
    box-shadow: 0 0 0 0 rgba(8, 13, 19, 0.5); }
  .btn-outline-darker.disabled, .btn-outline-darker:disabled {
    color: #080d13;
    background-color: transparent; }
  .btn-outline-darker:not(:disabled):not(.disabled):active, .btn-outline-darker:not(:disabled):not(.disabled).active,
  .show > .btn-outline-darker.dropdown-toggle {
    color: #ffffff;
    background-color: #080d13;
    border-color: #080d13; }
    .btn-outline-darker:not(:disabled):not(.disabled):active:focus, .btn-outline-darker:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-darker.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(8, 13, 19, 0.5); }

.btn-outline-primary-light {
  color: #edefff;
  background-color: transparent;
  background-image: none;
  border-color: #edefff; }
  .btn-outline-primary-light:hover {
    color: #1f3448;
    background-color: #edefff;
    border-color: #edefff; }
  .btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
    box-shadow: 0 0 0 0 rgba(237, 239, 255, 0.5); }
  .btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
    color: #edefff;
    background-color: transparent; }
  .btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-light.dropdown-toggle {
    color: #1f3448;
    background-color: #edefff;
    border-color: #edefff; }
    .btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(237, 239, 255, 0.5); }

.btn-outline-info-light {
  color: #1abc9c;
  background-color: transparent;
  background-image: none;
  border-color: #1abc9c; }
  .btn-outline-info-light:hover {
    color: #ffffff;
    background-color: #1abc9c;
    border-color: #1abc9c; }
  .btn-outline-info-light:focus, .btn-outline-info-light.focus {
    box-shadow: 0 0 0 0 rgba(26, 188, 156, 0.5); }
  .btn-outline-info-light.disabled, .btn-outline-info-light:disabled {
    color: #1abc9c;
    background-color: transparent; }
  .btn-outline-info-light:not(:disabled):not(.disabled):active, .btn-outline-info-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info-light.dropdown-toggle {
    color: #ffffff;
    background-color: #1abc9c;
    border-color: #1abc9c; }
    .btn-outline-info-light:not(:disabled):not(.disabled):active:focus, .btn-outline-info-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(26, 188, 156, 0.5); }

.footer {
  background: theme-color("secondary");
  padding: 2.5rem 0; }
  .footer .col-footer .heading {
    color: #8898aa;
    letter-spacing: 0;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1rem; }
  .footer .nav .nav-item .nav-link,
  .footer .footer-link {
    color: #8898aa !important; }
    .footer .nav .nav-item .nav-link:hover,
    .footer .footer-link:hover {
      color: #7f8c8d !important; }
  .footer .list-unstyled li a {
    display: inline-block;
    padding: .125rem 0;
    color: #8898aa;
    font-size: 0.875rem; }
    .footer .list-unstyled li a:hover {
      color: #7f8c8d; }
  .footer .copyright {
    font-size: 0.875rem; }

.footer-dark .col-footer .heading {
  color: #ffffff; }

.nav-footer .nav-link {
  font-size: 0.875rem; }

.nav-footer .nav-item:last-child .nav-link {
  padding-right: 0; }

.dashboard-lifecard-img {
  width: 100%;
  max-width: 425px;
  height: auto;
  padding: 0px; }

.dashboard-thumbnail-letters {
  width: 100px;
  height: 80px;
  border-radius: 0.375rem;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ffffff; }
  .dashboard-thumbnail-letters i {
    font-size: 35px;
    margin-top: 15px; }

.dashboard-records-img {
  width: 100%;
  max-width: 400px;
  height: auto;
  padding: 0px; }

.dashboard-records-item {
  width: 150px; }

.dash-records-item {
  width: 100px; }

.dash-records-item-thumbnail {
  width: 65px;
  height: 65px;
  background: #3949ab;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: .75rem; }
  .dash-records-item-thumbnail i {
    font-size: 28px;
    color: #ffffff; }
  .dash-records-item-thumbnail:hover {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
    background: #16a085; }

.dropdown-header {
  padding: .5rem .5rem;
  padding-top: 0;
  font-size: 0.75rem; }

.dropdown-profile {
  padding: .5rem 0; }
  .dropdown-profile .name {
    color: #3949ab !important; }

.dropdown-divider {
  margin: 0.1rem 0; }

.ant-modal-title {
  font-size: 18px !important;
  line-height: 22px !important;
  font-weight: 600 !important; }

.ant-modal-close-x {
  line-height: 48px !important; }

.anticon {
  vertical-align: initial !important; }

.bg-default {
  background: #3949ab !important; }

.bg-primary {
  background: #3949ab !important; }

.bg-secondary {
  background: #7f8c8d !important; }

.bg-success {
  background: #27ae60 !important; }

.bg-info {
  background: #16a085 !important; }

.bg-warning {
  background: #d35400 !important; }

.bg-danger {
  background: #c0392b !important; }

.bg-white {
  background: #ffffff !important; }

.bg-neutral {
  background: #ffffff !important; }

.bg-dark {
  background: #1f3448 !important; }

.bg-darker {
  background: #080d13 !important; }

.bg-primary-light {
  background: #edefff !important; }

.bg-info-light {
  background: #1abc9c !important; }

.fill-default {
  fill: #3949ab !important; }

.fill-primary {
  fill: #3949ab !important; }

.fill-secondary {
  fill: #7f8c8d !important; }

.fill-success {
  fill: #27ae60 !important; }

.fill-info {
  fill: #16a085 !important; }

.fill-warning {
  fill: #d35400 !important; }

.fill-danger {
  fill: #c0392b !important; }

.fill-white {
  fill: #ffffff !important; }

.fill-neutral {
  fill: #ffffff !important; }

.fill-dark {
  fill: #1f3448 !important; }

.fill-darker {
  fill: #080d13 !important; }

.fill-primary-light {
  fill: #edefff !important; }

.fill-info-light {
  fill: #1abc9c !important; }

.badge-default {
  color: #ffffff;
  background: #3949ab !important; }

.badge-primary {
  color: #ffffff;
  background: #3949ab !important; }

.badge-secondary {
  color: #ffffff;
  background: #7f8c8d !important; }

.badge-success {
  color: #ffffff;
  background: #27ae60 !important; }

.badge-info {
  color: #ffffff;
  background: #16a085 !important; }

.badge-warning {
  color: #ffffff;
  background: #d35400 !important; }

.badge-danger {
  color: #ffffff;
  background: #c0392b !important; }

.badge-white {
  color: #1f3448;
  background: #ffffff !important; }

.badge-neutral {
  color: #1f3448;
  background: #ffffff !important; }

.badge-dark {
  color: #ffffff;
  background: #1f3448 !important; }

.badge-darker {
  color: #ffffff;
  background: #080d13 !important; }

.badge-primary-light {
  color: #1f3448;
  background: #edefff !important; }

.badge-info-light {
  color: #ffffff;
  background: #1abc9c !important; }

.text-default {
  color: #3949ab !important; }

.text-primary {
  color: #3949ab !important; }

.text-secondary {
  color: #7f8c8d !important; }

.text-success {
  color: #27ae60 !important; }

.text-info {
  color: #16a085 !important; }

.text-warning {
  color: #d35400 !important; }

.text-danger {
  color: #c0392b !important; }

.text-white {
  color: #ffffff !important; }

.text-neutral {
  color: #ffffff !important; }

.text-dark {
  color: #1f3448 !important; }

.text-darker {
  color: #080d13 !important; }

.text-primary-light {
  color: #edefff !important; }

.text-info-light {
  color: #1abc9c !important; }
