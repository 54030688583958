@font-face {
  font-family: 'simple-line-icons';
  src: url(/static/media/Simple-Line-Icons.f33df365.eot);
  src: url(/static/media/Simple-Line-Icons.f33df365.eot#iefix) format('embedded-opentype'), url(/static/media/Simple-Line-Icons.0cb0b9c5.woff2) format('woff2'), url(/static/media/Simple-Line-Icons.d2285965.ttf) format('truetype'), url(/static/media/Simple-Line-Icons.78f07e2c.woff) format('woff'), url(/static/media/Simple-Line-Icons.73a93256.svg#simple-line-icons) format('svg');
  font-weight: normal;
  font-style: normal;
}
/*
 Use the following CSS code if you want to have a class per icon.
 Instead of a list of all class selectors, you can use the generic [class*="icon-"] selector, but it's slower:
*/
.simple-icon-user,
.simple-icon-people,
.simple-icon-user-female,
.simple-icon-user-follow,
.simple-icon-user-following,
.simple-icon-user-unfollow,
.simple-icon-login,
.simple-icon-logout,
.simple-icon-emotsmile,
.simple-icon-phone,
.simple-icon-call-end,
.simple-icon-call-in,
.simple-icon-call-out,
.simple-icon-map,
.simple-icon-location-pin,
.simple-icon-direction,
.simple-icon-directions,
.simple-icon-compass,
.simple-icon-layers,
.simple-icon-menu,
.simple-icon-list,
.simple-icon-options-vertical,
.simple-icon-options,
.simple-icon-arrow-down,
.simple-icon-arrow-left,
.simple-icon-arrow-right,
.simple-icon-arrow-up,
.simple-icon-arrow-up-circle,
.simple-icon-arrow-left-circle,
.simple-icon-arrow-right-circle,
.simple-icon-arrow-down-circle,
.simple-icon-check,
.simple-icon-clock,
.simple-icon-plus,
.simple-icon-minus,
.simple-icon-close,
.simple-icon-event,
.simple-icon-exclamation,
.simple-icon-organization,
.simple-icon-trophy,
.simple-icon-screen-smartphone,
.simple-icon-screen-desktop,
.simple-icon-plane,
.simple-icon-notebook,
.simple-icon-mustache,
.simple-icon-mouse,
.simple-icon-magnet,
.simple-icon-energy,
.simple-icon-disc,
.simple-icon-cursor,
.simple-icon-cursor-move,
.simple-icon-crop,
.simple-icon-chemistry,
.simple-icon-speedometer,
.simple-icon-shield,
.simple-icon-screen-tablet,
.simple-icon-magic-wand,
.simple-icon-hourglass,
.simple-icon-graduation,
.simple-icon-ghost,
.simple-icon-game-controller,
.simple-icon-fire,
.simple-icon-eyeglass,
.simple-icon-envelope-open,
.simple-icon-envelope-letter,
.simple-icon-bell,
.simple-icon-badge,
.simple-icon-anchor,
.simple-icon-wallet,
.simple-icon-vector,
.simple-icon-speech,
.simple-icon-puzzle,
.simple-icon-printer,
.simple-icon-present,
.simple-icon-playlist,
.simple-icon-pin,
.simple-icon-picture,
.simple-icon-handbag,
.simple-icon-globe-alt,
.simple-icon-globe,
.simple-icon-folder-alt,
.simple-icon-folder,
.simple-icon-film,
.simple-icon-feed,
.simple-icon-drop,
.simple-icon-drawer,
.simple-icon-docs,
.simple-icon-doc,
.simple-icon-diamond,
.simple-icon-cup,
.simple-icon-calculator,
.simple-icon-bubbles,
.simple-icon-briefcase,
.simple-icon-book-open,
.simple-icon-basket-loaded,
.simple-icon-basket,
.simple-icon-bag,
.simple-icon-action-undo,
.simple-icon-action-redo,
.simple-icon-wrench,
.simple-icon-umbrella,
.simple-icon-trash,
.simple-icon-tag,
.simple-icon-support,
.simple-icon-frame,
.simple-icon-size-fullscreen,
.simple-icon-size-actual,
.simple-icon-shuffle,
.simple-icon-share-alt,
.simple-icon-share,
.simple-icon-rocket,
.simple-icon-question,
.simple-icon-pie-chart,
.simple-icon-pencil,
.simple-icon-note,
.simple-icon-loop,
.simple-icon-home,
.simple-icon-grid,
.simple-icon-graph,
.simple-icon-microphone,
.simple-icon-music-tone-alt,
.simple-icon-music-tone,
.simple-icon-earphones-alt,
.simple-icon-earphones,
.simple-icon-equalizer,
.simple-icon-like,
.simple-icon-dislike,
.simple-icon-control-start,
.simple-icon-control-rewind,
.simple-icon-control-play,
.simple-icon-control-pause,
.simple-icon-control-forward,
.simple-icon-control-end,
.simple-icon-volume-1,
.simple-icon-volume-2,
.simple-icon-volume-off,
.simple-icon-calendar,
.simple-icon-bulb,
.simple-icon-chart,
.simple-icon-ban,
.simple-icon-bubble,
.simple-icon-camrecorder,
.simple-icon-camera,
.simple-icon-cloud-download,
.simple-icon-cloud-upload,
.simple-icon-envelope,
.simple-icon-eye,
.simple-icon-flag,
.simple-icon-heart,
.simple-icon-info,
.simple-icon-key,
.simple-icon-link,
.simple-icon-lock,
.simple-icon-lock-open,
.simple-icon-magnifier,
.simple-icon-magnifier-add,
.simple-icon-magnifier-remove,
.simple-icon-paper-clip,
.simple-icon-paper-plane,
.simple-icon-power,
.simple-icon-refresh,
.simple-icon-reload,
.simple-icon-settings,
.simple-icon-star,
.simple-icon-symbol-female,
.simple-icon-symbol-male,
.simple-icon-target,
.simple-icon-credit-card,
.simple-icon-paypal,
.simple-icon-social-tumblr,
.simple-icon-social-twitter,
.simple-icon-social-facebook,
.simple-icon-social-instagram,
.simple-icon-social-linkedin,
.simple-icon-social-pinterest,
.simple-icon-social-github,
.simple-icon-social-google,
.simple-icon-social-reddit,
.simple-icon-social-skype,
.simple-icon-social-dribbble,
.simple-icon-social-behance,
.simple-icon-social-foursqare,
.simple-icon-social-soundcloud,
.simple-icon-social-spotify,
.simple-icon-social-stumbleupon,
.simple-icon-social-youtube,
.simple-icon-social-dropbox,
.simple-icon-social-vkontakte,
.simple-icon-social-steam {
  font-family: 'simple-line-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.simple-icon-user:before {
  content: "\E005";
}
.simple-icon-people:before {
  content: "\E001";
}
.simple-icon-user-female:before {
  content: "\E000";
}
.simple-icon-user-follow:before {
  content: "\E002";
}
.simple-icon-user-following:before {
  content: "\E003";
}
.simple-icon-user-unfollow:before {
  content: "\E004";
}
.simple-icon-login:before {
  content: "\E066";
}
.simple-icon-logout:before {
  content: "\E065";
}
.simple-icon-emotsmile:before {
  content: "\E021";
}
.simple-icon-phone:before {
  content: "\E600";
}
.simple-icon-call-end:before {
  content: "\E048";
}
.simple-icon-call-in:before {
  content: "\E047";
}
.simple-icon-call-out:before {
  content: "\E046";
}
.simple-icon-map:before {
  content: "\E033";
}
.simple-icon-location-pin:before {
  content: "\E096";
}
.simple-icon-direction:before {
  content: "\E042";
}
.simple-icon-directions:before {
  content: "\E041";
}
.simple-icon-compass:before {
  content: "\E045";
}
.simple-icon-layers:before {
  content: "\E034";
}
.simple-icon-menu:before {
  content: "\E601";
}
.simple-icon-list:before {
  content: "\E067";
}
.simple-icon-options-vertical:before {
  content: "\E602";
}
.simple-icon-options:before {
  content: "\E603";
}
.simple-icon-arrow-down:before {
  content: "\E604";
}
.simple-icon-arrow-left:before {
  content: "\E605";
}
.simple-icon-arrow-right:before {
  content: "\E606";
}
.simple-icon-arrow-up:before {
  content: "\E607";
}
.simple-icon-arrow-up-circle:before {
  content: "\E078";
}
.simple-icon-arrow-left-circle:before {
  content: "\E07A";
}
.simple-icon-arrow-right-circle:before {
  content: "\E079";
}
.simple-icon-arrow-down-circle:before {
  content: "\E07B";
}
.simple-icon-check:before {
  content: "\E080";
}
.simple-icon-clock:before {
  content: "\E081";
}
.simple-icon-plus:before {
  content: "\E095";
}
.simple-icon-minus:before {
  content: "\E615";
}
.simple-icon-close:before {
  content: "\E082";
}
.simple-icon-event:before {
  content: "\E619";
}
.simple-icon-exclamation:before {
  content: "\E617";
}
.simple-icon-organization:before {
  content: "\E616";
}
.simple-icon-trophy:before {
  content: "\E006";
}
.simple-icon-screen-smartphone:before {
  content: "\E010";
}
.simple-icon-screen-desktop:before {
  content: "\E011";
}
.simple-icon-plane:before {
  content: "\E012";
}
.simple-icon-notebook:before {
  content: "\E013";
}
.simple-icon-mustache:before {
  content: "\E014";
}
.simple-icon-mouse:before {
  content: "\E015";
}
.simple-icon-magnet:before {
  content: "\E016";
}
.simple-icon-energy:before {
  content: "\E020";
}
.simple-icon-disc:before {
  content: "\E022";
}
.simple-icon-cursor:before {
  content: "\E06E";
}
.simple-icon-cursor-move:before {
  content: "\E023";
}
.simple-icon-crop:before {
  content: "\E024";
}
.simple-icon-chemistry:before {
  content: "\E026";
}
.simple-icon-speedometer:before {
  content: "\E007";
}
.simple-icon-shield:before {
  content: "\E00E";
}
.simple-icon-screen-tablet:before {
  content: "\E00F";
}
.simple-icon-magic-wand:before {
  content: "\E017";
}
.simple-icon-hourglass:before {
  content: "\E018";
}
.simple-icon-graduation:before {
  content: "\E019";
}
.simple-icon-ghost:before {
  content: "\E01A";
}
.simple-icon-game-controller:before {
  content: "\E01B";
}
.simple-icon-fire:before {
  content: "\E01C";
}
.simple-icon-eyeglass:before {
  content: "\E01D";
}
.simple-icon-envelope-open:before {
  content: "\E01E";
}
.simple-icon-envelope-letter:before {
  content: "\E01F";
}
.simple-icon-bell:before {
  content: "\E027";
}
.simple-icon-badge:before {
  content: "\E028";
}
.simple-icon-anchor:before {
  content: "\E029";
}
.simple-icon-wallet:before {
  content: "\E02A";
}
.simple-icon-vector:before {
  content: "\E02B";
}
.simple-icon-speech:before {
  content: "\E02C";
}
.simple-icon-puzzle:before {
  content: "\E02D";
}
.simple-icon-printer:before {
  content: "\E02E";
}
.simple-icon-present:before {
  content: "\E02F";
}
.simple-icon-playlist:before {
  content: "\E030";
}
.simple-icon-pin:before {
  content: "\E031";
}
.simple-icon-picture:before {
  content: "\E032";
}
.simple-icon-handbag:before {
  content: "\E035";
}
.simple-icon-globe-alt:before {
  content: "\E036";
}
.simple-icon-globe:before {
  content: "\E037";
}
.simple-icon-folder-alt:before {
  content: "\E039";
}
.simple-icon-folder:before {
  content: "\E089";
}
.simple-icon-film:before {
  content: "\E03A";
}
.simple-icon-feed:before {
  content: "\E03B";
}
.simple-icon-drop:before {
  content: "\E03E";
}
.simple-icon-drawer:before {
  content: "\E03F";
}
.simple-icon-docs:before {
  content: "\E040";
}
.simple-icon-doc:before {
  content: "\E085";
}
.simple-icon-diamond:before {
  content: "\E043";
}
.simple-icon-cup:before {
  content: "\E044";
}
.simple-icon-calculator:before {
  content: "\E049";
}
.simple-icon-bubbles:before {
  content: "\E04A";
}
.simple-icon-briefcase:before {
  content: "\E04B";
}
.simple-icon-book-open:before {
  content: "\E04C";
}
.simple-icon-basket-loaded:before {
  content: "\E04D";
}
.simple-icon-basket:before {
  content: "\E04E";
}
.simple-icon-bag:before {
  content: "\E04F";
}
.simple-icon-action-undo:before {
  content: "\E050";
}
.simple-icon-action-redo:before {
  content: "\E051";
}
.simple-icon-wrench:before {
  content: "\E052";
}
.simple-icon-umbrella:before {
  content: "\E053";
}
.simple-icon-trash:before {
  content: "\E054";
}
.simple-icon-tag:before {
  content: "\E055";
}
.simple-icon-support:before {
  content: "\E056";
}
.simple-icon-frame:before {
  content: "\E038";
}
.simple-icon-size-fullscreen:before {
  content: "\E057";
}
.simple-icon-size-actual:before {
  content: "\E058";
}
.simple-icon-shuffle:before {
  content: "\E059";
}
.simple-icon-share-alt:before {
  content: "\E05A";
}
.simple-icon-share:before {
  content: "\E05B";
}
.simple-icon-rocket:before {
  content: "\E05C";
}
.simple-icon-question:before {
  content: "\E05D";
}
.simple-icon-pie-chart:before {
  content: "\E05E";
}
.simple-icon-pencil:before {
  content: "\E05F";
}
.simple-icon-note:before {
  content: "\E060";
}
.simple-icon-loop:before {
  content: "\E064";
}
.simple-icon-home:before {
  content: "\E069";
}
.simple-icon-grid:before {
  content: "\E06A";
}
.simple-icon-graph:before {
  content: "\E06B";
}
.simple-icon-microphone:before {
  content: "\E063";
}
.simple-icon-music-tone-alt:before {
  content: "\E061";
}
.simple-icon-music-tone:before {
  content: "\E062";
}
.simple-icon-earphones-alt:before {
  content: "\E03C";
}
.simple-icon-earphones:before {
  content: "\E03D";
}
.simple-icon-equalizer:before {
  content: "\E06C";
}
.simple-icon-like:before {
  content: "\E068";
}
.simple-icon-dislike:before {
  content: "\E06D";
}
.simple-icon-control-start:before {
  content: "\E06F";
}
.simple-icon-control-rewind:before {
  content: "\E070";
}
.simple-icon-control-play:before {
  content: "\E071";
}
.simple-icon-control-pause:before {
  content: "\E072";
}
.simple-icon-control-forward:before {
  content: "\E073";
}
.simple-icon-control-end:before {
  content: "\E074";
}
.simple-icon-volume-1:before {
  content: "\E09F";
}
.simple-icon-volume-2:before {
  content: "\E0A0";
}
.simple-icon-volume-off:before {
  content: "\E0A1";
}
.simple-icon-calendar:before {
  content: "\E075";
}
.simple-icon-bulb:before {
  content: "\E076";
}
.simple-icon-chart:before {
  content: "\E077";
}
.simple-icon-ban:before {
  content: "\E07C";
}
.simple-icon-bubble:before {
  content: "\E07D";
}
.simple-icon-camrecorder:before {
  content: "\E07E";
}
.simple-icon-camera:before {
  content: "\E07F";
}
.simple-icon-cloud-download:before {
  content: "\E083";
}
.simple-icon-cloud-upload:before {
  content: "\E084";
}
.simple-icon-envelope:before {
  content: "\E086";
}
.simple-icon-eye:before {
  content: "\E087";
}
.simple-icon-flag:before {
  content: "\E088";
}
.simple-icon-heart:before {
  content: "\E08A";
}
.simple-icon-info:before {
  content: "\E08B";
}
.simple-icon-key:before {
  content: "\E08C";
}
.simple-icon-link:before {
  content: "\E08D";
}
.simple-icon-lock:before {
  content: "\E08E";
}
.simple-icon-lock-open:before {
  content: "\E08F";
}
.simple-icon-magnifier:before {
  content: "\E090";
}
.simple-icon-magnifier-add:before {
  content: "\E091";
}
.simple-icon-magnifier-remove:before {
  content: "\E092";
}
.simple-icon-paper-clip:before {
  content: "\E093";
}
.simple-icon-paper-plane:before {
  content: "\E094";
}
.simple-icon-power:before {
  content: "\E097";
}
.simple-icon-refresh:before {
  content: "\E098";
}
.simple-icon-reload:before {
  content: "\E099";
}
.simple-icon-settings:before {
  content: "\E09A";
}
.simple-icon-star:before {
  content: "\E09B";
}
.simple-icon-symbol-female:before {
  content: "\E09C";
}
.simple-icon-symbol-male:before {
  content: "\E09D";
}
.simple-icon-target:before {
  content: "\E09E";
}
.simple-icon-credit-card:before {
  content: "\E025";
}
.simple-icon-paypal:before {
  content: "\E608";
}
.simple-icon-social-tumblr:before {
  content: "\E00A";
}
.simple-icon-social-twitter:before {
  content: "\E009";
}
.simple-icon-social-facebook:before {
  content: "\E00B";
}
.simple-icon-social-instagram:before {
  content: "\E609";
}
.simple-icon-social-linkedin:before {
  content: "\E60A";
}
.simple-icon-social-pinterest:before {
  content: "\E60B";
}
.simple-icon-social-github:before {
  content: "\E60C";
}
.simple-icon-social-google:before {
  content: "\E60D";
}
.simple-icon-social-reddit:before {
  content: "\E60E";
}
.simple-icon-social-skype:before {
  content: "\E60F";
}
.simple-icon-social-dribbble:before {
  content: "\E00D";
}
.simple-icon-social-behance:before {
  content: "\E610";
}
.simple-icon-social-foursqare:before {
  content: "\E611";
}
.simple-icon-social-soundcloud:before {
  content: "\E612";
}
.simple-icon-social-spotify:before {
  content: "\E613";
}
.simple-icon-social-stumbleupon:before {
  content: "\E614";
}
.simple-icon-social-youtube:before {
  content: "\E008";
}
.simple-icon-social-dropbox:before {
  content: "\E00C";
}
.simple-icon-social-vkontakte:before {
  content: "\E618";
}
.simple-icon-social-steam:before {
  content: "\E620";
}

@font-face {
	font-family: 'icomoon';
	src:url(/static/media/icomoon.5a93ca37.eot);
	src:url(/static/media/icomoon.5a93ca37.eot?#iefix-rdmvgc) format('embedded-opentype'),
		url(/static/media/icomoon.2864469c.woff) format('woff'),
		url(/static/media/icomoon.f0409337.ttf) format('truetype'),
		url(/static/media/icomoon.b863a3e0.svg#icomoon) format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="iconsmind-"], [class*=" iconsmind-"] {
	font-family: 'icomoon';
	speak: none;
	font-style: normal;
	font-weight: normal;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.iconsmind-A-Z:before {
	content: "\E600";
}
.iconsmind-Aa:before {
	content: "\E601";
}
.iconsmind-Add-Bag:before {
	content: "\E602";
}
.iconsmind-Add-Basket:before {
	content: "\E603";
}
.iconsmind-Add-Cart:before {
	content: "\E604";
}
.iconsmind-Add-File:before {
	content: "\E605";
}
.iconsmind-Add-SpaceAfterParagraph:before {
	content: "\E606";
}
.iconsmind-Add-SpaceBeforeParagraph:before {
	content: "\E607";
}
.iconsmind-Add-User:before {
	content: "\E608";
}
.iconsmind-Add-UserStar:before {
	content: "\E609";
}
.iconsmind-Add-Window:before {
	content: "\E60A";
}
.iconsmind-Add:before {
	content: "\E60B";
}
.iconsmind-Address-Book:before {
	content: "\E60C";
}
.iconsmind-Address-Book2:before {
	content: "\E60D";
}
.iconsmind-Administrator:before {
	content: "\E60E";
}
.iconsmind-Aerobics-2:before {
	content: "\E60F";
}
.iconsmind-Aerobics-3:before {
	content: "\E610";
}
.iconsmind-Aerobics:before {
	content: "\E611";
}
.iconsmind-Affiliate:before {
	content: "\E612";
}
.iconsmind-Aim:before {
	content: "\E613";
}
.iconsmind-Air-Balloon:before {
	content: "\E614";
}
.iconsmind-Airbrush:before {
	content: "\E615";
}
.iconsmind-Airship:before {
	content: "\E616";
}
.iconsmind-Alarm-Clock:before {
	content: "\E617";
}
.iconsmind-Alarm-Clock2:before {
	content: "\E618";
}
.iconsmind-Alarm:before {
	content: "\E619";
}
.iconsmind-Alien-2:before {
	content: "\E61A";
}
.iconsmind-Alien:before {
	content: "\E61B";
}
.iconsmind-Aligator:before {
	content: "\E61C";
}
.iconsmind-Align-Center:before {
	content: "\E61D";
}
.iconsmind-Align-JustifyAll:before {
	content: "\E61E";
}
.iconsmind-Align-JustifyCenter:before {
	content: "\E61F";
}
.iconsmind-Align-JustifyLeft:before {
	content: "\E620";
}
.iconsmind-Align-JustifyRight:before {
	content: "\E621";
}
.iconsmind-Align-Left:before {
	content: "\E622";
}
.iconsmind-Align-Right:before {
	content: "\E623";
}
.iconsmind-Alpha:before {
	content: "\E624";
}
.iconsmind-Ambulance:before {
	content: "\E625";
}
.iconsmind-AMX:before {
	content: "\E626";
}
.iconsmind-Anchor-2:before {
	content: "\E627";
}
.iconsmind-Anchor:before {
	content: "\E628";
}
.iconsmind-Android-Store:before {
	content: "\E629";
}
.iconsmind-Android:before {
	content: "\E62A";
}
.iconsmind-Angel-Smiley:before {
	content: "\E62B";
}
.iconsmind-Angel:before {
	content: "\E62C";
}
.iconsmind-Angry:before {
	content: "\E62D";
}
.iconsmind-Apple-Bite:before {
	content: "\E62E";
}
.iconsmind-Apple-Store:before {
	content: "\E62F";
}
.iconsmind-Apple:before {
	content: "\E630";
}
.iconsmind-Approved-Window:before {
	content: "\E631";
}
.iconsmind-Aquarius-2:before {
	content: "\E632";
}
.iconsmind-Aquarius:before {
	content: "\E633";
}
.iconsmind-Archery-2:before {
	content: "\E634";
}
.iconsmind-Archery:before {
	content: "\E635";
}
.iconsmind-Argentina:before {
	content: "\E636";
}
.iconsmind-Aries-2:before {
	content: "\E637";
}
.iconsmind-Aries:before {
	content: "\E638";
}
.iconsmind-Army-Key:before {
	content: "\E639";
}
.iconsmind-Arrow-Around:before {
	content: "\E63A";
}
.iconsmind-Arrow-Back3:before {
	content: "\E63B";
}
.iconsmind-Arrow-Back:before {
	content: "\E63C";
}
.iconsmind-Arrow-Back2:before {
	content: "\E63D";
}
.iconsmind-Arrow-Barrier:before {
	content: "\E63E";
}
.iconsmind-Arrow-Circle:before {
	content: "\E63F";
}
.iconsmind-Arrow-Cross:before {
	content: "\E640";
}
.iconsmind-Arrow-Down:before {
	content: "\E641";
}
.iconsmind-Arrow-Down2:before {
	content: "\E642";
}
.iconsmind-Arrow-Down3:before {
	content: "\E643";
}
.iconsmind-Arrow-DowninCircle:before {
	content: "\E644";
}
.iconsmind-Arrow-Fork:before {
	content: "\E645";
}
.iconsmind-Arrow-Forward:before {
	content: "\E646";
}
.iconsmind-Arrow-Forward2:before {
	content: "\E647";
}
.iconsmind-Arrow-From:before {
	content: "\E648";
}
.iconsmind-Arrow-Inside:before {
	content: "\E649";
}
.iconsmind-Arrow-Inside45:before {
	content: "\E64A";
}
.iconsmind-Arrow-InsideGap:before {
	content: "\E64B";
}
.iconsmind-Arrow-InsideGap45:before {
	content: "\E64C";
}
.iconsmind-Arrow-Into:before {
	content: "\E64D";
}
.iconsmind-Arrow-Join:before {
	content: "\E64E";
}
.iconsmind-Arrow-Junction:before {
	content: "\E64F";
}
.iconsmind-Arrow-Left:before {
	content: "\E650";
}
.iconsmind-Arrow-Left2:before {
	content: "\E651";
}
.iconsmind-Arrow-LeftinCircle:before {
	content: "\E652";
}
.iconsmind-Arrow-Loop:before {
	content: "\E653";
}
.iconsmind-Arrow-Merge:before {
	content: "\E654";
}
.iconsmind-Arrow-Mix:before {
	content: "\E655";
}
.iconsmind-Arrow-Next:before {
	content: "\E656";
}
.iconsmind-Arrow-OutLeft:before {
	content: "\E657";
}
.iconsmind-Arrow-OutRight:before {
	content: "\E658";
}
.iconsmind-Arrow-Outside:before {
	content: "\E659";
}
.iconsmind-Arrow-Outside45:before {
	content: "\E65A";
}
.iconsmind-Arrow-OutsideGap:before {
	content: "\E65B";
}
.iconsmind-Arrow-OutsideGap45:before {
	content: "\E65C";
}
.iconsmind-Arrow-Over:before {
	content: "\E65D";
}
.iconsmind-Arrow-Refresh:before {
	content: "\E65E";
}
.iconsmind-Arrow-Refresh2:before {
	content: "\E65F";
}
.iconsmind-Arrow-Right:before {
	content: "\E660";
}
.iconsmind-Arrow-Right2:before {
	content: "\E661";
}
.iconsmind-Arrow-RightinCircle:before {
	content: "\E662";
}
.iconsmind-Arrow-Shuffle:before {
	content: "\E663";
}
.iconsmind-Arrow-Squiggly:before {
	content: "\E664";
}
.iconsmind-Arrow-Through:before {
	content: "\E665";
}
.iconsmind-Arrow-To:before {
	content: "\E666";
}
.iconsmind-Arrow-TurnLeft:before {
	content: "\E667";
}
.iconsmind-Arrow-TurnRight:before {
	content: "\E668";
}
.iconsmind-Arrow-Up:before {
	content: "\E669";
}
.iconsmind-Arrow-Up2:before {
	content: "\E66A";
}
.iconsmind-Arrow-Up3:before {
	content: "\E66B";
}
.iconsmind-Arrow-UpinCircle:before {
	content: "\E66C";
}
.iconsmind-Arrow-XLeft:before {
	content: "\E66D";
}
.iconsmind-Arrow-XRight:before {
	content: "\E66E";
}
.iconsmind-Ask:before {
	content: "\E66F";
}
.iconsmind-Assistant:before {
	content: "\E670";
}
.iconsmind-Astronaut:before {
	content: "\E671";
}
.iconsmind-At-Sign:before {
	content: "\E672";
}
.iconsmind-ATM:before {
	content: "\E673";
}
.iconsmind-Atom:before {
	content: "\E674";
}
.iconsmind-Audio:before {
	content: "\E675";
}
.iconsmind-Auto-Flash:before {
	content: "\E676";
}
.iconsmind-Autumn:before {
	content: "\E677";
}
.iconsmind-Baby-Clothes:before {
	content: "\E678";
}
.iconsmind-Baby-Clothes2:before {
	content: "\E679";
}
.iconsmind-Baby-Cry:before {
	content: "\E67A";
}
.iconsmind-Baby:before {
	content: "\E67B";
}
.iconsmind-Back2:before {
	content: "\E67C";
}
.iconsmind-Back-Media:before {
	content: "\E67D";
}
.iconsmind-Back-Music:before {
	content: "\E67E";
}
.iconsmind-Back:before {
	content: "\E67F";
}
.iconsmind-Background:before {
	content: "\E680";
}
.iconsmind-Bacteria:before {
	content: "\E681";
}
.iconsmind-Bag-Coins:before {
	content: "\E682";
}
.iconsmind-Bag-Items:before {
	content: "\E683";
}
.iconsmind-Bag-Quantity:before {
	content: "\E684";
}
.iconsmind-Bag:before {
	content: "\E685";
}
.iconsmind-Bakelite:before {
	content: "\E686";
}
.iconsmind-Ballet-Shoes:before {
	content: "\E687";
}
.iconsmind-Balloon:before {
	content: "\E688";
}
.iconsmind-Banana:before {
	content: "\E689";
}
.iconsmind-Band-Aid:before {
	content: "\E68A";
}
.iconsmind-Bank:before {
	content: "\E68B";
}
.iconsmind-Bar-Chart:before {
	content: "\E68C";
}
.iconsmind-Bar-Chart2:before {
	content: "\E68D";
}
.iconsmind-Bar-Chart3:before {
	content: "\E68E";
}
.iconsmind-Bar-Chart4:before {
	content: "\E68F";
}
.iconsmind-Bar-Chart5:before {
	content: "\E690";
}
.iconsmind-Bar-Code:before {
	content: "\E691";
}
.iconsmind-Barricade-2:before {
	content: "\E692";
}
.iconsmind-Barricade:before {
	content: "\E693";
}
.iconsmind-Baseball:before {
	content: "\E694";
}
.iconsmind-Basket-Ball:before {
	content: "\E695";
}
.iconsmind-Basket-Coins:before {
	content: "\E696";
}
.iconsmind-Basket-Items:before {
	content: "\E697";
}
.iconsmind-Basket-Quantity:before {
	content: "\E698";
}
.iconsmind-Bat-2:before {
	content: "\E699";
}
.iconsmind-Bat:before {
	content: "\E69A";
}
.iconsmind-Bathrobe:before {
	content: "\E69B";
}
.iconsmind-Batman-Mask:before {
	content: "\E69C";
}
.iconsmind-Battery-0:before {
	content: "\E69D";
}
.iconsmind-Battery-25:before {
	content: "\E69E";
}
.iconsmind-Battery-50:before {
	content: "\E69F";
}
.iconsmind-Battery-75:before {
	content: "\E6A0";
}
.iconsmind-Battery-100:before {
	content: "\E6A1";
}
.iconsmind-Battery-Charge:before {
	content: "\E6A2";
}
.iconsmind-Bear:before {
	content: "\E6A3";
}
.iconsmind-Beard-2:before {
	content: "\E6A4";
}
.iconsmind-Beard-3:before {
	content: "\E6A5";
}
.iconsmind-Beard:before {
	content: "\E6A6";
}
.iconsmind-Bebo:before {
	content: "\E6A7";
}
.iconsmind-Bee:before {
	content: "\E6A8";
}
.iconsmind-Beer-Glass:before {
	content: "\E6A9";
}
.iconsmind-Beer:before {
	content: "\E6AA";
}
.iconsmind-Bell-2:before {
	content: "\E6AB";
}
.iconsmind-Bell:before {
	content: "\E6AC";
}
.iconsmind-Belt-2:before {
	content: "\E6AD";
}
.iconsmind-Belt-3:before {
	content: "\E6AE";
}
.iconsmind-Belt:before {
	content: "\E6AF";
}
.iconsmind-Berlin-Tower:before {
	content: "\E6B0";
}
.iconsmind-Beta:before {
	content: "\E6B1";
}
.iconsmind-Betvibes:before {
	content: "\E6B2";
}
.iconsmind-Bicycle-2:before {
	content: "\E6B3";
}
.iconsmind-Bicycle-3:before {
	content: "\E6B4";
}
.iconsmind-Bicycle:before {
	content: "\E6B5";
}
.iconsmind-Big-Bang:before {
	content: "\E6B6";
}
.iconsmind-Big-Data:before {
	content: "\E6B7";
}
.iconsmind-Bike-Helmet:before {
	content: "\E6B8";
}
.iconsmind-Bikini:before {
	content: "\E6B9";
}
.iconsmind-Bilk-Bottle2:before {
	content: "\E6BA";
}
.iconsmind-Billing:before {
	content: "\E6BB";
}
.iconsmind-Bing:before {
	content: "\E6BC";
}
.iconsmind-Binocular:before {
	content: "\E6BD";
}
.iconsmind-Bio-Hazard:before {
	content: "\E6BE";
}
.iconsmind-Biotech:before {
	content: "\E6BF";
}
.iconsmind-Bird-DeliveringLetter:before {
	content: "\E6C0";
}
.iconsmind-Bird:before {
	content: "\E6C1";
}
.iconsmind-Birthday-Cake:before {
	content: "\E6C2";
}
.iconsmind-Bisexual:before {
	content: "\E6C3";
}
.iconsmind-Bishop:before {
	content: "\E6C4";
}
.iconsmind-Bitcoin:before {
	content: "\E6C5";
}
.iconsmind-Black-Cat:before {
	content: "\E6C6";
}
.iconsmind-Blackboard:before {
	content: "\E6C7";
}
.iconsmind-Blinklist:before {
	content: "\E6C8";
}
.iconsmind-Block-Cloud:before {
	content: "\E6C9";
}
.iconsmind-Block-Window:before {
	content: "\E6CA";
}
.iconsmind-Blogger:before {
	content: "\E6CB";
}
.iconsmind-Blood:before {
	content: "\E6CC";
}
.iconsmind-Blouse:before {
	content: "\E6CD";
}
.iconsmind-Blueprint:before {
	content: "\E6CE";
}
.iconsmind-Board:before {
	content: "\E6CF";
}
.iconsmind-Bodybuilding:before {
	content: "\E6D0";
}
.iconsmind-Bold-Text:before {
	content: "\E6D1";
}
.iconsmind-Bone:before {
	content: "\E6D2";
}
.iconsmind-Bones:before {
	content: "\E6D3";
}
.iconsmind-Book:before {
	content: "\E6D4";
}
.iconsmind-Bookmark:before {
	content: "\E6D5";
}
.iconsmind-Books-2:before {
	content: "\E6D6";
}
.iconsmind-Books:before {
	content: "\E6D7";
}
.iconsmind-Boom:before {
	content: "\E6D8";
}
.iconsmind-Boot-2:before {
	content: "\E6D9";
}
.iconsmind-Boot:before {
	content: "\E6DA";
}
.iconsmind-Bottom-ToTop:before {
	content: "\E6DB";
}
.iconsmind-Bow-2:before {
	content: "\E6DC";
}
.iconsmind-Bow-3:before {
	content: "\E6DD";
}
.iconsmind-Bow-4:before {
	content: "\E6DE";
}
.iconsmind-Bow-5:before {
	content: "\E6DF";
}
.iconsmind-Bow-6:before {
	content: "\E6E0";
}
.iconsmind-Bow:before {
	content: "\E6E1";
}
.iconsmind-Bowling-2:before {
	content: "\E6E2";
}
.iconsmind-Bowling:before {
	content: "\E6E3";
}
.iconsmind-Box2:before {
	content: "\E6E4";
}
.iconsmind-Box-Close:before {
	content: "\E6E5";
}
.iconsmind-Box-Full:before {
	content: "\E6E6";
}
.iconsmind-Box-Open:before {
	content: "\E6E7";
}
.iconsmind-Box-withFolders:before {
	content: "\E6E8";
}
.iconsmind-Box:before {
	content: "\E6E9";
}
.iconsmind-Boy:before {
	content: "\E6EA";
}
.iconsmind-Bra:before {
	content: "\E6EB";
}
.iconsmind-Brain-2:before {
	content: "\E6EC";
}
.iconsmind-Brain-3:before {
	content: "\E6ED";
}
.iconsmind-Brain:before {
	content: "\E6EE";
}
.iconsmind-Brazil:before {
	content: "\E6EF";
}
.iconsmind-Bread-2:before {
	content: "\E6F0";
}
.iconsmind-Bread:before {
	content: "\E6F1";
}
.iconsmind-Bridge:before {
	content: "\E6F2";
}
.iconsmind-Brightkite:before {
	content: "\E6F3";
}
.iconsmind-Broke-Link2:before {
	content: "\E6F4";
}
.iconsmind-Broken-Link:before {
	content: "\E6F5";
}
.iconsmind-Broom:before {
	content: "\E6F6";
}
.iconsmind-Brush:before {
	content: "\E6F7";
}
.iconsmind-Bucket:before {
	content: "\E6F8";
}
.iconsmind-Bug:before {
	content: "\E6F9";
}
.iconsmind-Building:before {
	content: "\E6FA";
}
.iconsmind-Bulleted-List:before {
	content: "\E6FB";
}
.iconsmind-Bus-2:before {
	content: "\E6FC";
}
.iconsmind-Bus:before {
	content: "\E6FD";
}
.iconsmind-Business-Man:before {
	content: "\E6FE";
}
.iconsmind-Business-ManWoman:before {
	content: "\E6FF";
}
.iconsmind-Business-Mens:before {
	content: "\E700";
}
.iconsmind-Business-Woman:before {
	content: "\E701";
}
.iconsmind-Butterfly:before {
	content: "\E702";
}
.iconsmind-Button:before {
	content: "\E703";
}
.iconsmind-Cable-Car:before {
	content: "\E704";
}
.iconsmind-Cake:before {
	content: "\E705";
}
.iconsmind-Calculator-2:before {
	content: "\E706";
}
.iconsmind-Calculator-3:before {
	content: "\E707";
}
.iconsmind-Calculator:before {
	content: "\E708";
}
.iconsmind-Calendar-2:before {
	content: "\E709";
}
.iconsmind-Calendar-3:before {
	content: "\E70A";
}
.iconsmind-Calendar-4:before {
	content: "\E70B";
}
.iconsmind-Calendar-Clock:before {
	content: "\E70C";
}
.iconsmind-Calendar:before {
	content: "\E70D";
}
.iconsmind-Camel:before {
	content: "\E70E";
}
.iconsmind-Camera-2:before {
	content: "\E70F";
}
.iconsmind-Camera-3:before {
	content: "\E710";
}
.iconsmind-Camera-4:before {
	content: "\E711";
}
.iconsmind-Camera-5:before {
	content: "\E712";
}
.iconsmind-Camera-Back:before {
	content: "\E713";
}
.iconsmind-Camera:before {
	content: "\E714";
}
.iconsmind-Can-2:before {
	content: "\E715";
}
.iconsmind-Can:before {
	content: "\E716";
}
.iconsmind-Canada:before {
	content: "\E717";
}
.iconsmind-Cancer-2:before {
	content: "\E718";
}
.iconsmind-Cancer-3:before {
	content: "\E719";
}
.iconsmind-Cancer:before {
	content: "\E71A";
}
.iconsmind-Candle:before {
	content: "\E71B";
}
.iconsmind-Candy-Cane:before {
	content: "\E71C";
}
.iconsmind-Candy:before {
	content: "\E71D";
}
.iconsmind-Cannon:before {
	content: "\E71E";
}
.iconsmind-Cap-2:before {
	content: "\E71F";
}
.iconsmind-Cap-3:before {
	content: "\E720";
}
.iconsmind-Cap-Smiley:before {
	content: "\E721";
}
.iconsmind-Cap:before {
	content: "\E722";
}
.iconsmind-Capricorn-2:before {
	content: "\E723";
}
.iconsmind-Capricorn:before {
	content: "\E724";
}
.iconsmind-Car-2:before {
	content: "\E725";
}
.iconsmind-Car-3:before {
	content: "\E726";
}
.iconsmind-Car-Coins:before {
	content: "\E727";
}
.iconsmind-Car-Items:before {
	content: "\E728";
}
.iconsmind-Car-Wheel:before {
	content: "\E729";
}
.iconsmind-Car:before {
	content: "\E72A";
}
.iconsmind-Cardigan:before {
	content: "\E72B";
}
.iconsmind-Cardiovascular:before {
	content: "\E72C";
}
.iconsmind-Cart-Quantity:before {
	content: "\E72D";
}
.iconsmind-Casette-Tape:before {
	content: "\E72E";
}
.iconsmind-Cash-Register:before {
	content: "\E72F";
}
.iconsmind-Cash-register2:before {
	content: "\E730";
}
.iconsmind-Castle:before {
	content: "\E731";
}
.iconsmind-Cat:before {
	content: "\E732";
}
.iconsmind-Cathedral:before {
	content: "\E733";
}
.iconsmind-Cauldron:before {
	content: "\E734";
}
.iconsmind-CD-2:before {
	content: "\E735";
}
.iconsmind-CD-Cover:before {
	content: "\E736";
}
.iconsmind-CD:before {
	content: "\E737";
}
.iconsmind-Cello:before {
	content: "\E738";
}
.iconsmind-Celsius:before {
	content: "\E739";
}
.iconsmind-Chacked-Flag:before {
	content: "\E73A";
}
.iconsmind-Chair:before {
	content: "\E73B";
}
.iconsmind-Charger:before {
	content: "\E73C";
}
.iconsmind-Check-2:before {
	content: "\E73D";
}
.iconsmind-Check:before {
	content: "\E73E";
}
.iconsmind-Checked-User:before {
	content: "\E73F";
}
.iconsmind-Checkmate:before {
	content: "\E740";
}
.iconsmind-Checkout-Bag:before {
	content: "\E741";
}
.iconsmind-Checkout-Basket:before {
	content: "\E742";
}
.iconsmind-Checkout:before {
	content: "\E743";
}
.iconsmind-Cheese:before {
	content: "\E744";
}
.iconsmind-Cheetah:before {
	content: "\E745";
}
.iconsmind-Chef-Hat:before {
	content: "\E746";
}
.iconsmind-Chef-Hat2:before {
	content: "\E747";
}
.iconsmind-Chef:before {
	content: "\E748";
}
.iconsmind-Chemical-2:before {
	content: "\E749";
}
.iconsmind-Chemical-3:before {
	content: "\E74A";
}
.iconsmind-Chemical-4:before {
	content: "\E74B";
}
.iconsmind-Chemical-5:before {
	content: "\E74C";
}
.iconsmind-Chemical:before {
	content: "\E74D";
}
.iconsmind-Chess-Board:before {
	content: "\E74E";
}
.iconsmind-Chess:before {
	content: "\E74F";
}
.iconsmind-Chicken:before {
	content: "\E750";
}
.iconsmind-Chile:before {
	content: "\E751";
}
.iconsmind-Chimney:before {
	content: "\E752";
}
.iconsmind-China:before {
	content: "\E753";
}
.iconsmind-Chinese-Temple:before {
	content: "\E754";
}
.iconsmind-Chip:before {
	content: "\E755";
}
.iconsmind-Chopsticks-2:before {
	content: "\E756";
}
.iconsmind-Chopsticks:before {
	content: "\E757";
}
.iconsmind-Christmas-Ball:before {
	content: "\E758";
}
.iconsmind-Christmas-Bell:before {
	content: "\E759";
}
.iconsmind-Christmas-Candle:before {
	content: "\E75A";
}
.iconsmind-Christmas-Hat:before {
	content: "\E75B";
}
.iconsmind-Christmas-Sleigh:before {
	content: "\E75C";
}
.iconsmind-Christmas-Snowman:before {
	content: "\E75D";
}
.iconsmind-Christmas-Sock:before {
	content: "\E75E";
}
.iconsmind-Christmas-Tree:before {
	content: "\E75F";
}
.iconsmind-Christmas:before {
	content: "\E760";
}
.iconsmind-Chrome:before {
	content: "\E761";
}
.iconsmind-Chrysler-Building:before {
	content: "\E762";
}
.iconsmind-Cinema:before {
	content: "\E763";
}
.iconsmind-Circular-Point:before {
	content: "\E764";
}
.iconsmind-City-Hall:before {
	content: "\E765";
}
.iconsmind-Clamp:before {
	content: "\E766";
}
.iconsmind-Clapperboard-Close:before {
	content: "\E767";
}
.iconsmind-Clapperboard-Open:before {
	content: "\E768";
}
.iconsmind-Claps:before {
	content: "\E769";
}
.iconsmind-Clef:before {
	content: "\E76A";
}
.iconsmind-Clinic:before {
	content: "\E76B";
}
.iconsmind-Clock-2:before {
	content: "\E76C";
}
.iconsmind-Clock-3:before {
	content: "\E76D";
}
.iconsmind-Clock-4:before {
	content: "\E76E";
}
.iconsmind-Clock-Back:before {
	content: "\E76F";
}
.iconsmind-Clock-Forward:before {
	content: "\E770";
}
.iconsmind-Clock:before {
	content: "\E771";
}
.iconsmind-Close-Window:before {
	content: "\E772";
}
.iconsmind-Close:before {
	content: "\E773";
}
.iconsmind-Clothing-Store:before {
	content: "\E774";
}
.iconsmind-Cloud--:before {
	content: "\E775";
}
.iconsmind-Cloud-:before {
	content: "\E776";
}
.iconsmind-Cloud-Camera:before {
	content: "\E777";
}
.iconsmind-Cloud-Computer:before {
	content: "\E778";
}
.iconsmind-Cloud-Email:before {
	content: "\E779";
}
.iconsmind-Cloud-Hail:before {
	content: "\E77A";
}
.iconsmind-Cloud-Laptop:before {
	content: "\E77B";
}
.iconsmind-Cloud-Lock:before {
	content: "\E77C";
}
.iconsmind-Cloud-Moon:before {
	content: "\E77D";
}
.iconsmind-Cloud-Music:before {
	content: "\E77E";
}
.iconsmind-Cloud-Picture:before {
	content: "\E77F";
}
.iconsmind-Cloud-Rain:before {
	content: "\E780";
}
.iconsmind-Cloud-Remove:before {
	content: "\E781";
}
.iconsmind-Cloud-Secure:before {
	content: "\E782";
}
.iconsmind-Cloud-Settings:before {
	content: "\E783";
}
.iconsmind-Cloud-Smartphone:before {
	content: "\E784";
}
.iconsmind-Cloud-Snow:before {
	content: "\E785";
}
.iconsmind-Cloud-Sun:before {
	content: "\E786";
}
.iconsmind-Cloud-Tablet:before {
	content: "\E787";
}
.iconsmind-Cloud-Video:before {
	content: "\E788";
}
.iconsmind-Cloud-Weather:before {
	content: "\E789";
}
.iconsmind-Cloud:before {
	content: "\E78A";
}
.iconsmind-Clouds-Weather:before {
	content: "\E78B";
}
.iconsmind-Clouds:before {
	content: "\E78C";
}
.iconsmind-Clown:before {
	content: "\E78D";
}
.iconsmind-CMYK:before {
	content: "\E78E";
}
.iconsmind-Coat:before {
	content: "\E78F";
}
.iconsmind-Cocktail:before {
	content: "\E790";
}
.iconsmind-Coconut:before {
	content: "\E791";
}
.iconsmind-Code-Window:before {
	content: "\E792";
}
.iconsmind-Coding:before {
	content: "\E793";
}
.iconsmind-Coffee-2:before {
	content: "\E794";
}
.iconsmind-Coffee-Bean:before {
	content: "\E795";
}
.iconsmind-Coffee-Machine:before {
	content: "\E796";
}
.iconsmind-Coffee-toGo:before {
	content: "\E797";
}
.iconsmind-Coffee:before {
	content: "\E798";
}
.iconsmind-Coffin:before {
	content: "\E799";
}
.iconsmind-Coin:before {
	content: "\E79A";
}
.iconsmind-Coins-2:before {
	content: "\E79B";
}
.iconsmind-Coins-3:before {
	content: "\E79C";
}
.iconsmind-Coins:before {
	content: "\E79D";
}
.iconsmind-Colombia:before {
	content: "\E79E";
}
.iconsmind-Colosseum:before {
	content: "\E79F";
}
.iconsmind-Column-2:before {
	content: "\E7A0";
}
.iconsmind-Column-3:before {
	content: "\E7A1";
}
.iconsmind-Column:before {
	content: "\E7A2";
}
.iconsmind-Comb-2:before {
	content: "\E7A3";
}
.iconsmind-Comb:before {
	content: "\E7A4";
}
.iconsmind-Communication-Tower:before {
	content: "\E7A5";
}
.iconsmind-Communication-Tower2:before {
	content: "\E7A6";
}
.iconsmind-Compass-2:before {
	content: "\E7A7";
}
.iconsmind-Compass-3:before {
	content: "\E7A8";
}
.iconsmind-Compass-4:before {
	content: "\E7A9";
}
.iconsmind-Compass-Rose:before {
	content: "\E7AA";
}
.iconsmind-Compass:before {
	content: "\E7AB";
}
.iconsmind-Computer-2:before {
	content: "\E7AC";
}
.iconsmind-Computer-3:before {
	content: "\E7AD";
}
.iconsmind-Computer-Secure:before {
	content: "\E7AE";
}
.iconsmind-Computer:before {
	content: "\E7AF";
}
.iconsmind-Conference:before {
	content: "\E7B0";
}
.iconsmind-Confused:before {
	content: "\E7B1";
}
.iconsmind-Conservation:before {
	content: "\E7B2";
}
.iconsmind-Consulting:before {
	content: "\E7B3";
}
.iconsmind-Contrast:before {
	content: "\E7B4";
}
.iconsmind-Control-2:before {
	content: "\E7B5";
}
.iconsmind-Control:before {
	content: "\E7B6";
}
.iconsmind-Cookie-Man:before {
	content: "\E7B7";
}
.iconsmind-Cookies:before {
	content: "\E7B8";
}
.iconsmind-Cool-Guy:before {
	content: "\E7B9";
}
.iconsmind-Cool:before {
	content: "\E7BA";
}
.iconsmind-Copyright:before {
	content: "\E7BB";
}
.iconsmind-Costume:before {
	content: "\E7BC";
}
.iconsmind-Couple-Sign:before {
	content: "\E7BD";
}
.iconsmind-Cow:before {
	content: "\E7BE";
}
.iconsmind-CPU:before {
	content: "\E7BF";
}
.iconsmind-Crane:before {
	content: "\E7C0";
}
.iconsmind-Cranium:before {
	content: "\E7C1";
}
.iconsmind-Credit-Card:before {
	content: "\E7C2";
}
.iconsmind-Credit-Card2:before {
	content: "\E7C3";
}
.iconsmind-Credit-Card3:before {
	content: "\E7C4";
}
.iconsmind-Cricket:before {
	content: "\E7C5";
}
.iconsmind-Criminal:before {
	content: "\E7C6";
}
.iconsmind-Croissant:before {
	content: "\E7C7";
}
.iconsmind-Crop-2:before {
	content: "\E7C8";
}
.iconsmind-Crop-3:before {
	content: "\E7C9";
}
.iconsmind-Crown-2:before {
	content: "\E7CA";
}
.iconsmind-Crown:before {
	content: "\E7CB";
}
.iconsmind-Crying:before {
	content: "\E7CC";
}
.iconsmind-Cube-Molecule:before {
	content: "\E7CD";
}
.iconsmind-Cube-Molecule2:before {
	content: "\E7CE";
}
.iconsmind-Cupcake:before {
	content: "\E7CF";
}
.iconsmind-Cursor-Click:before {
	content: "\E7D0";
}
.iconsmind-Cursor-Click2:before {
	content: "\E7D1";
}
.iconsmind-Cursor-Move:before {
	content: "\E7D2";
}
.iconsmind-Cursor-Move2:before {
	content: "\E7D3";
}
.iconsmind-Cursor-Select:before {
	content: "\E7D4";
}
.iconsmind-Cursor:before {
	content: "\E7D5";
}
.iconsmind-D-Eyeglasses:before {
	content: "\E7D6";
}
.iconsmind-D-Eyeglasses2:before {
	content: "\E7D7";
}
.iconsmind-Dam:before {
	content: "\E7D8";
}
.iconsmind-Danemark:before {
	content: "\E7D9";
}
.iconsmind-Danger-2:before {
	content: "\E7DA";
}
.iconsmind-Danger:before {
	content: "\E7DB";
}
.iconsmind-Dashboard:before {
	content: "\E7DC";
}
.iconsmind-Data-Backup:before {
	content: "\E7DD";
}
.iconsmind-Data-Block:before {
	content: "\E7DE";
}
.iconsmind-Data-Center:before {
	content: "\E7DF";
}
.iconsmind-Data-Clock:before {
	content: "\E7E0";
}
.iconsmind-Data-Cloud:before {
	content: "\E7E1";
}
.iconsmind-Data-Compress:before {
	content: "\E7E2";
}
.iconsmind-Data-Copy:before {
	content: "\E7E3";
}
.iconsmind-Data-Download:before {
	content: "\E7E4";
}
.iconsmind-Data-Financial:before {
	content: "\E7E5";
}
.iconsmind-Data-Key:before {
	content: "\E7E6";
}
.iconsmind-Data-Lock:before {
	content: "\E7E7";
}
.iconsmind-Data-Network:before {
	content: "\E7E8";
}
.iconsmind-Data-Password:before {
	content: "\E7E9";
}
.iconsmind-Data-Power:before {
	content: "\E7EA";
}
.iconsmind-Data-Refresh:before {
	content: "\E7EB";
}
.iconsmind-Data-Save:before {
	content: "\E7EC";
}
.iconsmind-Data-Search:before {
	content: "\E7ED";
}
.iconsmind-Data-Security:before {
	content: "\E7EE";
}
.iconsmind-Data-Settings:before {
	content: "\E7EF";
}
.iconsmind-Data-Sharing:before {
	content: "\E7F0";
}
.iconsmind-Data-Shield:before {
	content: "\E7F1";
}
.iconsmind-Data-Signal:before {
	content: "\E7F2";
}
.iconsmind-Data-Storage:before {
	content: "\E7F3";
}
.iconsmind-Data-Stream:before {
	content: "\E7F4";
}
.iconsmind-Data-Transfer:before {
	content: "\E7F5";
}
.iconsmind-Data-Unlock:before {
	content: "\E7F6";
}
.iconsmind-Data-Upload:before {
	content: "\E7F7";
}
.iconsmind-Data-Yes:before {
	content: "\E7F8";
}
.iconsmind-Data:before {
	content: "\E7F9";
}
.iconsmind-David-Star:before {
	content: "\E7FA";
}
.iconsmind-Daylight:before {
	content: "\E7FB";
}
.iconsmind-Death:before {
	content: "\E7FC";
}
.iconsmind-Debian:before {
	content: "\E7FD";
}
.iconsmind-Dec:before {
	content: "\E7FE";
}
.iconsmind-Decrase-Inedit:before {
	content: "\E7FF";
}
.iconsmind-Deer-2:before {
	content: "\E800";
}
.iconsmind-Deer:before {
	content: "\E801";
}
.iconsmind-Delete-File:before {
	content: "\E802";
}
.iconsmind-Delete-Window:before {
	content: "\E803";
}
.iconsmind-Delicious:before {
	content: "\E804";
}
.iconsmind-Depression:before {
	content: "\E805";
}
.iconsmind-Deviantart:before {
	content: "\E806";
}
.iconsmind-Device-SyncwithCloud:before {
	content: "\E807";
}
.iconsmind-Diamond:before {
	content: "\E808";
}
.iconsmind-Dice-2:before {
	content: "\E809";
}
.iconsmind-Dice:before {
	content: "\E80A";
}
.iconsmind-Digg:before {
	content: "\E80B";
}
.iconsmind-Digital-Drawing:before {
	content: "\E80C";
}
.iconsmind-Diigo:before {
	content: "\E80D";
}
.iconsmind-Dinosaur:before {
	content: "\E80E";
}
.iconsmind-Diploma-2:before {
	content: "\E80F";
}
.iconsmind-Diploma:before {
	content: "\E810";
}
.iconsmind-Direction-East:before {
	content: "\E811";
}
.iconsmind-Direction-North:before {
	content: "\E812";
}
.iconsmind-Direction-South:before {
	content: "\E813";
}
.iconsmind-Direction-West:before {
	content: "\E814";
}
.iconsmind-Director:before {
	content: "\E815";
}
.iconsmind-Disk:before {
	content: "\E816";
}
.iconsmind-Dj:before {
	content: "\E817";
}
.iconsmind-DNA-2:before {
	content: "\E818";
}
.iconsmind-DNA-Helix:before {
	content: "\E819";
}
.iconsmind-DNA:before {
	content: "\E81A";
}
.iconsmind-Doctor:before {
	content: "\E81B";
}
.iconsmind-Dog:before {
	content: "\E81C";
}
.iconsmind-Dollar-Sign:before {
	content: "\E81D";
}
.iconsmind-Dollar-Sign2:before {
	content: "\E81E";
}
.iconsmind-Dollar:before {
	content: "\E81F";
}
.iconsmind-Dolphin:before {
	content: "\E820";
}
.iconsmind-Domino:before {
	content: "\E821";
}
.iconsmind-Door-Hanger:before {
	content: "\E822";
}
.iconsmind-Door:before {
	content: "\E823";
}
.iconsmind-Doplr:before {
	content: "\E824";
}
.iconsmind-Double-Circle:before {
	content: "\E825";
}
.iconsmind-Double-Tap:before {
	content: "\E826";
}
.iconsmind-Doughnut:before {
	content: "\E827";
}
.iconsmind-Dove:before {
	content: "\E828";
}
.iconsmind-Down-2:before {
	content: "\E829";
}
.iconsmind-Down-3:before {
	content: "\E82A";
}
.iconsmind-Down-4:before {
	content: "\E82B";
}
.iconsmind-Down:before {
	content: "\E82C";
}
.iconsmind-Download-2:before {
	content: "\E82D";
}
.iconsmind-Download-fromCloud:before {
	content: "\E82E";
}
.iconsmind-Download-Window:before {
	content: "\E82F";
}
.iconsmind-Download:before {
	content: "\E830";
}
.iconsmind-Downward:before {
	content: "\E831";
}
.iconsmind-Drag-Down:before {
	content: "\E832";
}
.iconsmind-Drag-Left:before {
	content: "\E833";
}
.iconsmind-Drag-Right:before {
	content: "\E834";
}
.iconsmind-Drag-Up:before {
	content: "\E835";
}
.iconsmind-Drag:before {
	content: "\E836";
}
.iconsmind-Dress:before {
	content: "\E837";
}
.iconsmind-Drill-2:before {
	content: "\E838";
}
.iconsmind-Drill:before {
	content: "\E839";
}
.iconsmind-Drop:before {
	content: "\E83A";
}
.iconsmind-Dropbox:before {
	content: "\E83B";
}
.iconsmind-Drum:before {
	content: "\E83C";
}
.iconsmind-Dry:before {
	content: "\E83D";
}
.iconsmind-Duck:before {
	content: "\E83E";
}
.iconsmind-Dumbbell:before {
	content: "\E83F";
}
.iconsmind-Duplicate-Layer:before {
	content: "\E840";
}
.iconsmind-Duplicate-Window:before {
	content: "\E841";
}
.iconsmind-DVD:before {
	content: "\E842";
}
.iconsmind-Eagle:before {
	content: "\E843";
}
.iconsmind-Ear:before {
	content: "\E844";
}
.iconsmind-Earphones-2:before {
	content: "\E845";
}
.iconsmind-Earphones:before {
	content: "\E846";
}
.iconsmind-Eci-Icon:before {
	content: "\E847";
}
.iconsmind-Edit-Map:before {
	content: "\E848";
}
.iconsmind-Edit:before {
	content: "\E849";
}
.iconsmind-Eggs:before {
	content: "\E84A";
}
.iconsmind-Egypt:before {
	content: "\E84B";
}
.iconsmind-Eifel-Tower:before {
	content: "\E84C";
}
.iconsmind-eject-2:before {
	content: "\E84D";
}
.iconsmind-Eject:before {
	content: "\E84E";
}
.iconsmind-El-Castillo:before {
	content: "\E84F";
}
.iconsmind-Elbow:before {
	content: "\E850";
}
.iconsmind-Electric-Guitar:before {
	content: "\E851";
}
.iconsmind-Electricity:before {
	content: "\E852";
}
.iconsmind-Elephant:before {
	content: "\E853";
}
.iconsmind-Email:before {
	content: "\E854";
}
.iconsmind-Embassy:before {
	content: "\E855";
}
.iconsmind-Empire-StateBuilding:before {
	content: "\E856";
}
.iconsmind-Empty-Box:before {
	content: "\E857";
}
.iconsmind-End2:before {
	content: "\E858";
}
.iconsmind-End-2:before {
	content: "\E859";
}
.iconsmind-End:before {
	content: "\E85A";
}
.iconsmind-Endways:before {
	content: "\E85B";
}
.iconsmind-Engineering:before {
	content: "\E85C";
}
.iconsmind-Envelope-2:before {
	content: "\E85D";
}
.iconsmind-Envelope:before {
	content: "\E85E";
}
.iconsmind-Environmental-2:before {
	content: "\E85F";
}
.iconsmind-Environmental-3:before {
	content: "\E860";
}
.iconsmind-Environmental:before {
	content: "\E861";
}
.iconsmind-Equalizer:before {
	content: "\E862";
}
.iconsmind-Eraser-2:before {
	content: "\E863";
}
.iconsmind-Eraser-3:before {
	content: "\E864";
}
.iconsmind-Eraser:before {
	content: "\E865";
}
.iconsmind-Error-404Window:before {
	content: "\E866";
}
.iconsmind-Euro-Sign:before {
	content: "\E867";
}
.iconsmind-Euro-Sign2:before {
	content: "\E868";
}
.iconsmind-Euro:before {
	content: "\E869";
}
.iconsmind-Evernote:before {
	content: "\E86A";
}
.iconsmind-Evil:before {
	content: "\E86B";
}
.iconsmind-Explode:before {
	content: "\E86C";
}
.iconsmind-Eye-2:before {
	content: "\E86D";
}
.iconsmind-Eye-Blind:before {
	content: "\E86E";
}
.iconsmind-Eye-Invisible:before {
	content: "\E86F";
}
.iconsmind-Eye-Scan:before {
	content: "\E870";
}
.iconsmind-Eye-Visible:before {
	content: "\E871";
}
.iconsmind-Eye:before {
	content: "\E872";
}
.iconsmind-Eyebrow-2:before {
	content: "\E873";
}
.iconsmind-Eyebrow-3:before {
	content: "\E874";
}
.iconsmind-Eyebrow:before {
	content: "\E875";
}
.iconsmind-Eyeglasses-Smiley:before {
	content: "\E876";
}
.iconsmind-Eyeglasses-Smiley2:before {
	content: "\E877";
}
.iconsmind-Face-Style:before {
	content: "\E878";
}
.iconsmind-Face-Style2:before {
	content: "\E879";
}
.iconsmind-Face-Style3:before {
	content: "\E87A";
}
.iconsmind-Face-Style4:before {
	content: "\E87B";
}
.iconsmind-Face-Style5:before {
	content: "\E87C";
}
.iconsmind-Face-Style6:before {
	content: "\E87D";
}
.iconsmind-Facebook-2:before {
	content: "\E87E";
}
.iconsmind-Facebook:before {
	content: "\E87F";
}
.iconsmind-Factory-2:before {
	content: "\E880";
}
.iconsmind-Factory:before {
	content: "\E881";
}
.iconsmind-Fahrenheit:before {
	content: "\E882";
}
.iconsmind-Family-Sign:before {
	content: "\E883";
}
.iconsmind-Fan:before {
	content: "\E884";
}
.iconsmind-Farmer:before {
	content: "\E885";
}
.iconsmind-Fashion:before {
	content: "\E886";
}
.iconsmind-Favorite-Window:before {
	content: "\E887";
}
.iconsmind-Fax:before {
	content: "\E888";
}
.iconsmind-Feather:before {
	content: "\E889";
}
.iconsmind-Feedburner:before {
	content: "\E88A";
}
.iconsmind-Female-2:before {
	content: "\E88B";
}
.iconsmind-Female-Sign:before {
	content: "\E88C";
}
.iconsmind-Female:before {
	content: "\E88D";
}
.iconsmind-File-Block:before {
	content: "\E88E";
}
.iconsmind-File-Bookmark:before {
	content: "\E88F";
}
.iconsmind-File-Chart:before {
	content: "\E890";
}
.iconsmind-File-Clipboard:before {
	content: "\E891";
}
.iconsmind-File-ClipboardFileText:before {
	content: "\E892";
}
.iconsmind-File-ClipboardTextImage:before {
	content: "\E893";
}
.iconsmind-File-Cloud:before {
	content: "\E894";
}
.iconsmind-File-Copy:before {
	content: "\E895";
}
.iconsmind-File-Copy2:before {
	content: "\E896";
}
.iconsmind-File-CSV:before {
	content: "\E897";
}
.iconsmind-File-Download:before {
	content: "\E898";
}
.iconsmind-File-Edit:before {
	content: "\E899";
}
.iconsmind-File-Excel:before {
	content: "\E89A";
}
.iconsmind-File-Favorite:before {
	content: "\E89B";
}
.iconsmind-File-Fire:before {
	content: "\E89C";
}
.iconsmind-File-Graph:before {
	content: "\E89D";
}
.iconsmind-File-Hide:before {
	content: "\E89E";
}
.iconsmind-File-Horizontal:before {
	content: "\E89F";
}
.iconsmind-File-HorizontalText:before {
	content: "\E8A0";
}
.iconsmind-File-HTML:before {
	content: "\E8A1";
}
.iconsmind-File-JPG:before {
	content: "\E8A2";
}
.iconsmind-File-Link:before {
	content: "\E8A3";
}
.iconsmind-File-Loading:before {
	content: "\E8A4";
}
.iconsmind-File-Lock:before {
	content: "\E8A5";
}
.iconsmind-File-Love:before {
	content: "\E8A6";
}
.iconsmind-File-Music:before {
	content: "\E8A7";
}
.iconsmind-File-Network:before {
	content: "\E8A8";
}
.iconsmind-File-Pictures:before {
	content: "\E8A9";
}
.iconsmind-File-Pie:before {
	content: "\E8AA";
}
.iconsmind-File-Presentation:before {
	content: "\E8AB";
}
.iconsmind-File-Refresh:before {
	content: "\E8AC";
}
.iconsmind-File-Search:before {
	content: "\E8AD";
}
.iconsmind-File-Settings:before {
	content: "\E8AE";
}
.iconsmind-File-Share:before {
	content: "\E8AF";
}
.iconsmind-File-TextImage:before {
	content: "\E8B0";
}
.iconsmind-File-Trash:before {
	content: "\E8B1";
}
.iconsmind-File-TXT:before {
	content: "\E8B2";
}
.iconsmind-File-Upload:before {
	content: "\E8B3";
}
.iconsmind-File-Video:before {
	content: "\E8B4";
}
.iconsmind-File-Word:before {
	content: "\E8B5";
}
.iconsmind-File-Zip:before {
	content: "\E8B6";
}
.iconsmind-File:before {
	content: "\E8B7";
}
.iconsmind-Files:before {
	content: "\E8B8";
}
.iconsmind-Film-Board:before {
	content: "\E8B9";
}
.iconsmind-Film-Cartridge:before {
	content: "\E8BA";
}
.iconsmind-Film-Strip:before {
	content: "\E8BB";
}
.iconsmind-Film-Video:before {
	content: "\E8BC";
}
.iconsmind-Film:before {
	content: "\E8BD";
}
.iconsmind-Filter-2:before {
	content: "\E8BE";
}
.iconsmind-Filter:before {
	content: "\E8BF";
}
.iconsmind-Financial:before {
	content: "\E8C0";
}
.iconsmind-Find-User:before {
	content: "\E8C1";
}
.iconsmind-Finger-DragFourSides:before {
	content: "\E8C2";
}
.iconsmind-Finger-DragTwoSides:before {
	content: "\E8C3";
}
.iconsmind-Finger-Print:before {
	content: "\E8C4";
}
.iconsmind-Finger:before {
	content: "\E8C5";
}
.iconsmind-Fingerprint-2:before {
	content: "\E8C6";
}
.iconsmind-Fingerprint:before {
	content: "\E8C7";
}
.iconsmind-Fire-Flame:before {
	content: "\E8C8";
}
.iconsmind-Fire-Flame2:before {
	content: "\E8C9";
}
.iconsmind-Fire-Hydrant:before {
	content: "\E8CA";
}
.iconsmind-Fire-Staion:before {
	content: "\E8CB";
}
.iconsmind-Firefox:before {
	content: "\E8CC";
}
.iconsmind-Firewall:before {
	content: "\E8CD";
}
.iconsmind-First-Aid:before {
	content: "\E8CE";
}
.iconsmind-First:before {
	content: "\E8CF";
}
.iconsmind-Fish-Food:before {
	content: "\E8D0";
}
.iconsmind-Fish:before {
	content: "\E8D1";
}
.iconsmind-Fit-To:before {
	content: "\E8D2";
}
.iconsmind-Fit-To2:before {
	content: "\E8D3";
}
.iconsmind-Five-Fingers:before {
	content: "\E8D4";
}
.iconsmind-Five-FingersDrag:before {
	content: "\E8D5";
}
.iconsmind-Five-FingersDrag2:before {
	content: "\E8D6";
}
.iconsmind-Five-FingersTouch:before {
	content: "\E8D7";
}
.iconsmind-Flag-2:before {
	content: "\E8D8";
}
.iconsmind-Flag-3:before {
	content: "\E8D9";
}
.iconsmind-Flag-4:before {
	content: "\E8DA";
}
.iconsmind-Flag-5:before {
	content: "\E8DB";
}
.iconsmind-Flag-6:before {
	content: "\E8DC";
}
.iconsmind-Flag:before {
	content: "\E8DD";
}
.iconsmind-Flamingo:before {
	content: "\E8DE";
}
.iconsmind-Flash-2:before {
	content: "\E8DF";
}
.iconsmind-Flash-Video:before {
	content: "\E8E0";
}
.iconsmind-Flash:before {
	content: "\E8E1";
}
.iconsmind-Flashlight:before {
	content: "\E8E2";
}
.iconsmind-Flask-2:before {
	content: "\E8E3";
}
.iconsmind-Flask:before {
	content: "\E8E4";
}
.iconsmind-Flick:before {
	content: "\E8E5";
}
.iconsmind-Flickr:before {
	content: "\E8E6";
}
.iconsmind-Flowerpot:before {
	content: "\E8E7";
}
.iconsmind-Fluorescent:before {
	content: "\E8E8";
}
.iconsmind-Fog-Day:before {
	content: "\E8E9";
}
.iconsmind-Fog-Night:before {
	content: "\E8EA";
}
.iconsmind-Folder-Add:before {
	content: "\E8EB";
}
.iconsmind-Folder-Archive:before {
	content: "\E8EC";
}
.iconsmind-Folder-Binder:before {
	content: "\E8ED";
}
.iconsmind-Folder-Binder2:before {
	content: "\E8EE";
}
.iconsmind-Folder-Block:before {
	content: "\E8EF";
}
.iconsmind-Folder-Bookmark:before {
	content: "\E8F0";
}
.iconsmind-Folder-Close:before {
	content: "\E8F1";
}
.iconsmind-Folder-Cloud:before {
	content: "\E8F2";
}
.iconsmind-Folder-Delete:before {
	content: "\E8F3";
}
.iconsmind-Folder-Download:before {
	content: "\E8F4";
}
.iconsmind-Folder-Edit:before {
	content: "\E8F5";
}
.iconsmind-Folder-Favorite:before {
	content: "\E8F6";
}
.iconsmind-Folder-Fire:before {
	content: "\E8F7";
}
.iconsmind-Folder-Hide:before {
	content: "\E8F8";
}
.iconsmind-Folder-Link:before {
	content: "\E8F9";
}
.iconsmind-Folder-Loading:before {
	content: "\E8FA";
}
.iconsmind-Folder-Lock:before {
	content: "\E8FB";
}
.iconsmind-Folder-Love:before {
	content: "\E8FC";
}
.iconsmind-Folder-Music:before {
	content: "\E8FD";
}
.iconsmind-Folder-Network:before {
	content: "\E8FE";
}
.iconsmind-Folder-Open:before {
	content: "\E8FF";
}
.iconsmind-Folder-Open2:before {
	content: "\E900";
}
.iconsmind-Folder-Organizing:before {
	content: "\E901";
}
.iconsmind-Folder-Pictures:before {
	content: "\E902";
}
.iconsmind-Folder-Refresh:before {
	content: "\E903";
}
.iconsmind-Folder-Remove-:before {
	content: "\E904";
}
.iconsmind-Folder-Search:before {
	content: "\E905";
}
.iconsmind-Folder-Settings:before {
	content: "\E906";
}
.iconsmind-Folder-Share:before {
	content: "\E907";
}
.iconsmind-Folder-Trash:before {
	content: "\E908";
}
.iconsmind-Folder-Upload:before {
	content: "\E909";
}
.iconsmind-Folder-Video:before {
	content: "\E90A";
}
.iconsmind-Folder-WithDocument:before {
	content: "\E90B";
}
.iconsmind-Folder-Zip:before {
	content: "\E90C";
}
.iconsmind-Folder:before {
	content: "\E90D";
}
.iconsmind-Folders:before {
	content: "\E90E";
}
.iconsmind-Font-Color:before {
	content: "\E90F";
}
.iconsmind-Font-Name:before {
	content: "\E910";
}
.iconsmind-Font-Size:before {
	content: "\E911";
}
.iconsmind-Font-Style:before {
	content: "\E912";
}
.iconsmind-Font-StyleSubscript:before {
	content: "\E913";
}
.iconsmind-Font-StyleSuperscript:before {
	content: "\E914";
}
.iconsmind-Font-Window:before {
	content: "\E915";
}
.iconsmind-Foot-2:before {
	content: "\E916";
}
.iconsmind-Foot:before {
	content: "\E917";
}
.iconsmind-Football-2:before {
	content: "\E918";
}
.iconsmind-Football:before {
	content: "\E919";
}
.iconsmind-Footprint-2:before {
	content: "\E91A";
}
.iconsmind-Footprint-3:before {
	content: "\E91B";
}
.iconsmind-Footprint:before {
	content: "\E91C";
}
.iconsmind-Forest:before {
	content: "\E91D";
}
.iconsmind-Fork:before {
	content: "\E91E";
}
.iconsmind-Formspring:before {
	content: "\E91F";
}
.iconsmind-Formula:before {
	content: "\E920";
}
.iconsmind-Forsquare:before {
	content: "\E921";
}
.iconsmind-Forward:before {
	content: "\E922";
}
.iconsmind-Fountain-Pen:before {
	content: "\E923";
}
.iconsmind-Four-Fingers:before {
	content: "\E924";
}
.iconsmind-Four-FingersDrag:before {
	content: "\E925";
}
.iconsmind-Four-FingersDrag2:before {
	content: "\E926";
}
.iconsmind-Four-FingersTouch:before {
	content: "\E927";
}
.iconsmind-Fox:before {
	content: "\E928";
}
.iconsmind-Frankenstein:before {
	content: "\E929";
}
.iconsmind-French-Fries:before {
	content: "\E92A";
}
.iconsmind-Friendfeed:before {
	content: "\E92B";
}
.iconsmind-Friendster:before {
	content: "\E92C";
}
.iconsmind-Frog:before {
	content: "\E92D";
}
.iconsmind-Fruits:before {
	content: "\E92E";
}
.iconsmind-Fuel:before {
	content: "\E92F";
}
.iconsmind-Full-Bag:before {
	content: "\E930";
}
.iconsmind-Full-Basket:before {
	content: "\E931";
}
.iconsmind-Full-Cart:before {
	content: "\E932";
}
.iconsmind-Full-Moon:before {
	content: "\E933";
}
.iconsmind-Full-Screen:before {
	content: "\E934";
}
.iconsmind-Full-Screen2:before {
	content: "\E935";
}
.iconsmind-Full-View:before {
	content: "\E936";
}
.iconsmind-Full-View2:before {
	content: "\E937";
}
.iconsmind-Full-ViewWindow:before {
	content: "\E938";
}
.iconsmind-Function:before {
	content: "\E939";
}
.iconsmind-Funky:before {
	content: "\E93A";
}
.iconsmind-Funny-Bicycle:before {
	content: "\E93B";
}
.iconsmind-Furl:before {
	content: "\E93C";
}
.iconsmind-Gamepad-2:before {
	content: "\E93D";
}
.iconsmind-Gamepad:before {
	content: "\E93E";
}
.iconsmind-Gas-Pump:before {
	content: "\E93F";
}
.iconsmind-Gaugage-2:before {
	content: "\E940";
}
.iconsmind-Gaugage:before {
	content: "\E941";
}
.iconsmind-Gay:before {
	content: "\E942";
}
.iconsmind-Gear-2:before {
	content: "\E943";
}
.iconsmind-Gear:before {
	content: "\E944";
}
.iconsmind-Gears-2:before {
	content: "\E945";
}
.iconsmind-Gears:before {
	content: "\E946";
}
.iconsmind-Geek-2:before {
	content: "\E947";
}
.iconsmind-Geek:before {
	content: "\E948";
}
.iconsmind-Gemini-2:before {
	content: "\E949";
}
.iconsmind-Gemini:before {
	content: "\E94A";
}
.iconsmind-Genius:before {
	content: "\E94B";
}
.iconsmind-Gentleman:before {
	content: "\E94C";
}
.iconsmind-Geo--:before {
	content: "\E94D";
}
.iconsmind-Geo-:before {
	content: "\E94E";
}
.iconsmind-Geo-Close:before {
	content: "\E94F";
}
.iconsmind-Geo-Love:before {
	content: "\E950";
}
.iconsmind-Geo-Number:before {
	content: "\E951";
}
.iconsmind-Geo-Star:before {
	content: "\E952";
}
.iconsmind-Geo:before {
	content: "\E953";
}
.iconsmind-Geo2--:before {
	content: "\E954";
}
.iconsmind-Geo2-:before {
	content: "\E955";
}
.iconsmind-Geo2-Close:before {
	content: "\E956";
}
.iconsmind-Geo2-Love:before {
	content: "\E957";
}
.iconsmind-Geo2-Number:before {
	content: "\E958";
}
.iconsmind-Geo2-Star:before {
	content: "\E959";
}
.iconsmind-Geo2:before {
	content: "\E95A";
}
.iconsmind-Geo3--:before {
	content: "\E95B";
}
.iconsmind-Geo3-:before {
	content: "\E95C";
}
.iconsmind-Geo3-Close:before {
	content: "\E95D";
}
.iconsmind-Geo3-Love:before {
	content: "\E95E";
}
.iconsmind-Geo3-Number:before {
	content: "\E95F";
}
.iconsmind-Geo3-Star:before {
	content: "\E960";
}
.iconsmind-Geo3:before {
	content: "\E961";
}
.iconsmind-Gey:before {
	content: "\E962";
}
.iconsmind-Gift-Box:before {
	content: "\E963";
}
.iconsmind-Giraffe:before {
	content: "\E964";
}
.iconsmind-Girl:before {
	content: "\E965";
}
.iconsmind-Glass-Water:before {
	content: "\E966";
}
.iconsmind-Glasses-2:before {
	content: "\E967";
}
.iconsmind-Glasses-3:before {
	content: "\E968";
}
.iconsmind-Glasses:before {
	content: "\E969";
}
.iconsmind-Global-Position:before {
	content: "\E96A";
}
.iconsmind-Globe-2:before {
	content: "\E96B";
}
.iconsmind-Globe:before {
	content: "\E96C";
}
.iconsmind-Gloves:before {
	content: "\E96D";
}
.iconsmind-Go-Bottom:before {
	content: "\E96E";
}
.iconsmind-Go-Top:before {
	content: "\E96F";
}
.iconsmind-Goggles:before {
	content: "\E970";
}
.iconsmind-Golf-2:before {
	content: "\E971";
}
.iconsmind-Golf:before {
	content: "\E972";
}
.iconsmind-Google-Buzz:before {
	content: "\E973";
}
.iconsmind-Google-Drive:before {
	content: "\E974";
}
.iconsmind-Google-Play:before {
	content: "\E975";
}
.iconsmind-Google-Plus:before {
	content: "\E976";
}
.iconsmind-Google:before {
	content: "\E977";
}
.iconsmind-Gopro:before {
	content: "\E978";
}
.iconsmind-Gorilla:before {
	content: "\E979";
}
.iconsmind-Gowalla:before {
	content: "\E97A";
}
.iconsmind-Grave:before {
	content: "\E97B";
}
.iconsmind-Graveyard:before {
	content: "\E97C";
}
.iconsmind-Greece:before {
	content: "\E97D";
}
.iconsmind-Green-Energy:before {
	content: "\E97E";
}
.iconsmind-Green-House:before {
	content: "\E97F";
}
.iconsmind-Guitar:before {
	content: "\E980";
}
.iconsmind-Gun-2:before {
	content: "\E981";
}
.iconsmind-Gun-3:before {
	content: "\E982";
}
.iconsmind-Gun:before {
	content: "\E983";
}
.iconsmind-Gymnastics:before {
	content: "\E984";
}
.iconsmind-Hair-2:before {
	content: "\E985";
}
.iconsmind-Hair-3:before {
	content: "\E986";
}
.iconsmind-Hair-4:before {
	content: "\E987";
}
.iconsmind-Hair:before {
	content: "\E988";
}
.iconsmind-Half-Moon:before {
	content: "\E989";
}
.iconsmind-Halloween-HalfMoon:before {
	content: "\E98A";
}
.iconsmind-Halloween-Moon:before {
	content: "\E98B";
}
.iconsmind-Hamburger:before {
	content: "\E98C";
}
.iconsmind-Hammer:before {
	content: "\E98D";
}
.iconsmind-Hand-Touch:before {
	content: "\E98E";
}
.iconsmind-Hand-Touch2:before {
	content: "\E98F";
}
.iconsmind-Hand-TouchSmartphone:before {
	content: "\E990";
}
.iconsmind-Hand:before {
	content: "\E991";
}
.iconsmind-Hands:before {
	content: "\E992";
}
.iconsmind-Handshake:before {
	content: "\E993";
}
.iconsmind-Hanger:before {
	content: "\E994";
}
.iconsmind-Happy:before {
	content: "\E995";
}
.iconsmind-Hat-2:before {
	content: "\E996";
}
.iconsmind-Hat:before {
	content: "\E997";
}
.iconsmind-Haunted-House:before {
	content: "\E998";
}
.iconsmind-HD-Video:before {
	content: "\E999";
}
.iconsmind-HD:before {
	content: "\E99A";
}
.iconsmind-HDD:before {
	content: "\E99B";
}
.iconsmind-Headphone:before {
	content: "\E99C";
}
.iconsmind-Headphones:before {
	content: "\E99D";
}
.iconsmind-Headset:before {
	content: "\E99E";
}
.iconsmind-Heart-2:before {
	content: "\E99F";
}
.iconsmind-Heart:before {
	content: "\E9A0";
}
.iconsmind-Heels-2:before {
	content: "\E9A1";
}
.iconsmind-Heels:before {
	content: "\E9A2";
}
.iconsmind-Height-Window:before {
	content: "\E9A3";
}
.iconsmind-Helicopter-2:before {
	content: "\E9A4";
}
.iconsmind-Helicopter:before {
	content: "\E9A5";
}
.iconsmind-Helix-2:before {
	content: "\E9A6";
}
.iconsmind-Hello:before {
	content: "\E9A7";
}
.iconsmind-Helmet-2:before {
	content: "\E9A8";
}
.iconsmind-Helmet-3:before {
	content: "\E9A9";
}
.iconsmind-Helmet:before {
	content: "\E9AA";
}
.iconsmind-Hipo:before {
	content: "\E9AB";
}
.iconsmind-Hipster-Glasses:before {
	content: "\E9AC";
}
.iconsmind-Hipster-Glasses2:before {
	content: "\E9AD";
}
.iconsmind-Hipster-Glasses3:before {
	content: "\E9AE";
}
.iconsmind-Hipster-Headphones:before {
	content: "\E9AF";
}
.iconsmind-Hipster-Men:before {
	content: "\E9B0";
}
.iconsmind-Hipster-Men2:before {
	content: "\E9B1";
}
.iconsmind-Hipster-Men3:before {
	content: "\E9B2";
}
.iconsmind-Hipster-Sunglasses:before {
	content: "\E9B3";
}
.iconsmind-Hipster-Sunglasses2:before {
	content: "\E9B4";
}
.iconsmind-Hipster-Sunglasses3:before {
	content: "\E9B5";
}
.iconsmind-Hokey:before {
	content: "\E9B6";
}
.iconsmind-Holly:before {
	content: "\E9B7";
}
.iconsmind-Home-2:before {
	content: "\E9B8";
}
.iconsmind-Home-3:before {
	content: "\E9B9";
}
.iconsmind-Home-4:before {
	content: "\E9BA";
}
.iconsmind-Home-5:before {
	content: "\E9BB";
}
.iconsmind-Home-Window:before {
	content: "\E9BC";
}
.iconsmind-Home:before {
	content: "\E9BD";
}
.iconsmind-Homosexual:before {
	content: "\E9BE";
}
.iconsmind-Honey:before {
	content: "\E9BF";
}
.iconsmind-Hong-Kong:before {
	content: "\E9C0";
}
.iconsmind-Hoodie:before {
	content: "\E9C1";
}
.iconsmind-Horror:before {
	content: "\E9C2";
}
.iconsmind-Horse:before {
	content: "\E9C3";
}
.iconsmind-Hospital-2:before {
	content: "\E9C4";
}
.iconsmind-Hospital:before {
	content: "\E9C5";
}
.iconsmind-Host:before {
	content: "\E9C6";
}
.iconsmind-Hot-Dog:before {
	content: "\E9C7";
}
.iconsmind-Hotel:before {
	content: "\E9C8";
}
.iconsmind-Hour:before {
	content: "\E9C9";
}
.iconsmind-Hub:before {
	content: "\E9CA";
}
.iconsmind-Humor:before {
	content: "\E9CB";
}
.iconsmind-Hurt:before {
	content: "\E9CC";
}
.iconsmind-Ice-Cream:before {
	content: "\E9CD";
}
.iconsmind-ICQ:before {
	content: "\E9CE";
}
.iconsmind-ID-2:before {
	content: "\E9CF";
}
.iconsmind-ID-3:before {
	content: "\E9D0";
}
.iconsmind-ID-Card:before {
	content: "\E9D1";
}
.iconsmind-Idea-2:before {
	content: "\E9D2";
}
.iconsmind-Idea-3:before {
	content: "\E9D3";
}
.iconsmind-Idea-4:before {
	content: "\E9D4";
}
.iconsmind-Idea-5:before {
	content: "\E9D5";
}
.iconsmind-Idea:before {
	content: "\E9D6";
}
.iconsmind-Identification-Badge:before {
	content: "\E9D7";
}
.iconsmind-ImDB:before {
	content: "\E9D8";
}
.iconsmind-Inbox-Empty:before {
	content: "\E9D9";
}
.iconsmind-Inbox-Forward:before {
	content: "\E9DA";
}
.iconsmind-Inbox-Full:before {
	content: "\E9DB";
}
.iconsmind-Inbox-Into:before {
	content: "\E9DC";
}
.iconsmind-Inbox-Out:before {
	content: "\E9DD";
}
.iconsmind-Inbox-Reply:before {
	content: "\E9DE";
}
.iconsmind-Inbox:before {
	content: "\E9DF";
}
.iconsmind-Increase-Inedit:before {
	content: "\E9E0";
}
.iconsmind-Indent-FirstLine:before {
	content: "\E9E1";
}
.iconsmind-Indent-LeftMargin:before {
	content: "\E9E2";
}
.iconsmind-Indent-RightMargin:before {
	content: "\E9E3";
}
.iconsmind-India:before {
	content: "\E9E4";
}
.iconsmind-Info-Window:before {
	content: "\E9E5";
}
.iconsmind-Information:before {
	content: "\E9E6";
}
.iconsmind-Inifity:before {
	content: "\E9E7";
}
.iconsmind-Instagram:before {
	content: "\E9E8";
}
.iconsmind-Internet-2:before {
	content: "\E9E9";
}
.iconsmind-Internet-Explorer:before {
	content: "\E9EA";
}
.iconsmind-Internet-Smiley:before {
	content: "\E9EB";
}
.iconsmind-Internet:before {
	content: "\E9EC";
}
.iconsmind-iOS-Apple:before {
	content: "\E9ED";
}
.iconsmind-Israel:before {
	content: "\E9EE";
}
.iconsmind-Italic-Text:before {
	content: "\E9EF";
}
.iconsmind-Jacket-2:before {
	content: "\E9F0";
}
.iconsmind-Jacket:before {
	content: "\E9F1";
}
.iconsmind-Jamaica:before {
	content: "\E9F2";
}
.iconsmind-Japan:before {
	content: "\E9F3";
}
.iconsmind-Japanese-Gate:before {
	content: "\E9F4";
}
.iconsmind-Jeans:before {
	content: "\E9F5";
}
.iconsmind-Jeep-2:before {
	content: "\E9F6";
}
.iconsmind-Jeep:before {
	content: "\E9F7";
}
.iconsmind-Jet:before {
	content: "\E9F8";
}
.iconsmind-Joystick:before {
	content: "\E9F9";
}
.iconsmind-Juice:before {
	content: "\E9FA";
}
.iconsmind-Jump-Rope:before {
	content: "\E9FB";
}
.iconsmind-Kangoroo:before {
	content: "\E9FC";
}
.iconsmind-Kenya:before {
	content: "\E9FD";
}
.iconsmind-Key-2:before {
	content: "\E9FE";
}
.iconsmind-Key-3:before {
	content: "\E9FF";
}
.iconsmind-Key-Lock:before {
	content: "\EA00";
}
.iconsmind-Key:before {
	content: "\EA01";
}
.iconsmind-Keyboard:before {
	content: "\EA02";
}
.iconsmind-Keyboard3:before {
	content: "\EA03";
}
.iconsmind-Keypad:before {
	content: "\EA04";
}
.iconsmind-King-2:before {
	content: "\EA05";
}
.iconsmind-King:before {
	content: "\EA06";
}
.iconsmind-Kiss:before {
	content: "\EA07";
}
.iconsmind-Knee:before {
	content: "\EA08";
}
.iconsmind-Knife-2:before {
	content: "\EA09";
}
.iconsmind-Knife:before {
	content: "\EA0A";
}
.iconsmind-Knight:before {
	content: "\EA0B";
}
.iconsmind-Koala:before {
	content: "\EA0C";
}
.iconsmind-Korea:before {
	content: "\EA0D";
}
.iconsmind-Lamp:before {
	content: "\EA0E";
}
.iconsmind-Landscape-2:before {
	content: "\EA0F";
}
.iconsmind-Landscape:before {
	content: "\EA10";
}
.iconsmind-Lantern:before {
	content: "\EA11";
}
.iconsmind-Laptop-2:before {
	content: "\EA12";
}
.iconsmind-Laptop-3:before {
	content: "\EA13";
}
.iconsmind-Laptop-Phone:before {
	content: "\EA14";
}
.iconsmind-Laptop-Secure:before {
	content: "\EA15";
}
.iconsmind-Laptop-Tablet:before {
	content: "\EA16";
}
.iconsmind-Laptop:before {
	content: "\EA17";
}
.iconsmind-Laser:before {
	content: "\EA18";
}
.iconsmind-Last-FM:before {
	content: "\EA19";
}
.iconsmind-Last:before {
	content: "\EA1A";
}
.iconsmind-Laughing:before {
	content: "\EA1B";
}
.iconsmind-Layer-1635:before {
	content: "\EA1C";
}
.iconsmind-Layer-1646:before {
	content: "\EA1D";
}
.iconsmind-Layer-Backward:before {
	content: "\EA1E";
}
.iconsmind-Layer-Forward:before {
	content: "\EA1F";
}
.iconsmind-Leafs-2:before {
	content: "\EA20";
}
.iconsmind-Leafs:before {
	content: "\EA21";
}
.iconsmind-Leaning-Tower:before {
	content: "\EA22";
}
.iconsmind-Left--Right:before {
	content: "\EA23";
}
.iconsmind-Left--Right3:before {
	content: "\EA24";
}
.iconsmind-Left-2:before {
	content: "\EA25";
}
.iconsmind-Left-3:before {
	content: "\EA26";
}
.iconsmind-Left-4:before {
	content: "\EA27";
}
.iconsmind-Left-ToRight:before {
	content: "\EA28";
}
.iconsmind-Left:before {
	content: "\EA29";
}
.iconsmind-Leg-2:before {
	content: "\EA2A";
}
.iconsmind-Leg:before {
	content: "\EA2B";
}
.iconsmind-Lego:before {
	content: "\EA2C";
}
.iconsmind-Lemon:before {
	content: "\EA2D";
}
.iconsmind-Len-2:before {
	content: "\EA2E";
}
.iconsmind-Len-3:before {
	content: "\EA2F";
}
.iconsmind-Len:before {
	content: "\EA30";
}
.iconsmind-Leo-2:before {
	content: "\EA31";
}
.iconsmind-Leo:before {
	content: "\EA32";
}
.iconsmind-Leopard:before {
	content: "\EA33";
}
.iconsmind-Lesbian:before {
	content: "\EA34";
}
.iconsmind-Lesbians:before {
	content: "\EA35";
}
.iconsmind-Letter-Close:before {
	content: "\EA36";
}
.iconsmind-Letter-Open:before {
	content: "\EA37";
}
.iconsmind-Letter-Sent:before {
	content: "\EA38";
}
.iconsmind-Libra-2:before {
	content: "\EA39";
}
.iconsmind-Libra:before {
	content: "\EA3A";
}
.iconsmind-Library-2:before {
	content: "\EA3B";
}
.iconsmind-Library:before {
	content: "\EA3C";
}
.iconsmind-Life-Jacket:before {
	content: "\EA3D";
}
.iconsmind-Life-Safer:before {
	content: "\EA3E";
}
.iconsmind-Light-Bulb:before {
	content: "\EA3F";
}
.iconsmind-Light-Bulb2:before {
	content: "\EA40";
}
.iconsmind-Light-BulbLeaf:before {
	content: "\EA41";
}
.iconsmind-Lighthouse:before {
	content: "\EA42";
}
.iconsmind-Like-2:before {
	content: "\EA43";
}
.iconsmind-Like:before {
	content: "\EA44";
}
.iconsmind-Line-Chart:before {
	content: "\EA45";
}
.iconsmind-Line-Chart2:before {
	content: "\EA46";
}
.iconsmind-Line-Chart3:before {
	content: "\EA47";
}
.iconsmind-Line-Chart4:before {
	content: "\EA48";
}
.iconsmind-Line-Spacing:before {
	content: "\EA49";
}
.iconsmind-Line-SpacingText:before {
	content: "\EA4A";
}
.iconsmind-Link-2:before {
	content: "\EA4B";
}
.iconsmind-Link:before {
	content: "\EA4C";
}
.iconsmind-Linkedin-2:before {
	content: "\EA4D";
}
.iconsmind-Linkedin:before {
	content: "\EA4E";
}
.iconsmind-Linux:before {
	content: "\EA4F";
}
.iconsmind-Lion:before {
	content: "\EA50";
}
.iconsmind-Livejournal:before {
	content: "\EA51";
}
.iconsmind-Loading-2:before {
	content: "\EA52";
}
.iconsmind-Loading-3:before {
	content: "\EA53";
}
.iconsmind-Loading-Window:before {
	content: "\EA54";
}
.iconsmind-Loading:before {
	content: "\EA55";
}
.iconsmind-Location-2:before {
	content: "\EA56";
}
.iconsmind-Location:before {
	content: "\EA57";
}
.iconsmind-Lock-2:before {
	content: "\EA58";
}
.iconsmind-Lock-3:before {
	content: "\EA59";
}
.iconsmind-Lock-User:before {
	content: "\EA5A";
}
.iconsmind-Lock-Window:before {
	content: "\EA5B";
}
.iconsmind-Lock:before {
	content: "\EA5C";
}
.iconsmind-Lollipop-2:before {
	content: "\EA5D";
}
.iconsmind-Lollipop-3:before {
	content: "\EA5E";
}
.iconsmind-Lollipop:before {
	content: "\EA5F";
}
.iconsmind-Loop:before {
	content: "\EA60";
}
.iconsmind-Loud:before {
	content: "\EA61";
}
.iconsmind-Loudspeaker:before {
	content: "\EA62";
}
.iconsmind-Love-2:before {
	content: "\EA63";
}
.iconsmind-Love-User:before {
	content: "\EA64";
}
.iconsmind-Love-Window:before {
	content: "\EA65";
}
.iconsmind-Love:before {
	content: "\EA66";
}
.iconsmind-Lowercase-Text:before {
	content: "\EA67";
}
.iconsmind-Luggafe-Front:before {
	content: "\EA68";
}
.iconsmind-Luggage-2:before {
	content: "\EA69";
}
.iconsmind-Macro:before {
	content: "\EA6A";
}
.iconsmind-Magic-Wand:before {
	content: "\EA6B";
}
.iconsmind-Magnet:before {
	content: "\EA6C";
}
.iconsmind-Magnifi-Glass-:before {
	content: "\EA6D";
}
.iconsmind-Magnifi-Glass:before {
	content: "\EA6E";
}
.iconsmind-Magnifi-Glass2:before {
	content: "\EA6F";
}
.iconsmind-Mail-2:before {
	content: "\EA70";
}
.iconsmind-Mail-3:before {
	content: "\EA71";
}
.iconsmind-Mail-Add:before {
	content: "\EA72";
}
.iconsmind-Mail-Attachement:before {
	content: "\EA73";
}
.iconsmind-Mail-Block:before {
	content: "\EA74";
}
.iconsmind-Mail-Delete:before {
	content: "\EA75";
}
.iconsmind-Mail-Favorite:before {
	content: "\EA76";
}
.iconsmind-Mail-Forward:before {
	content: "\EA77";
}
.iconsmind-Mail-Gallery:before {
	content: "\EA78";
}
.iconsmind-Mail-Inbox:before {
	content: "\EA79";
}
.iconsmind-Mail-Link:before {
	content: "\EA7A";
}
.iconsmind-Mail-Lock:before {
	content: "\EA7B";
}
.iconsmind-Mail-Love:before {
	content: "\EA7C";
}
.iconsmind-Mail-Money:before {
	content: "\EA7D";
}
.iconsmind-Mail-Open:before {
	content: "\EA7E";
}
.iconsmind-Mail-Outbox:before {
	content: "\EA7F";
}
.iconsmind-Mail-Password:before {
	content: "\EA80";
}
.iconsmind-Mail-Photo:before {
	content: "\EA81";
}
.iconsmind-Mail-Read:before {
	content: "\EA82";
}
.iconsmind-Mail-Removex:before {
	content: "\EA83";
}
.iconsmind-Mail-Reply:before {
	content: "\EA84";
}
.iconsmind-Mail-ReplyAll:before {
	content: "\EA85";
}
.iconsmind-Mail-Search:before {
	content: "\EA86";
}
.iconsmind-Mail-Send:before {
	content: "\EA87";
}
.iconsmind-Mail-Settings:before {
	content: "\EA88";
}
.iconsmind-Mail-Unread:before {
	content: "\EA89";
}
.iconsmind-Mail-Video:before {
	content: "\EA8A";
}
.iconsmind-Mail-withAtSign:before {
	content: "\EA8B";
}
.iconsmind-Mail-WithCursors:before {
	content: "\EA8C";
}
.iconsmind-Mail:before {
	content: "\EA8D";
}
.iconsmind-Mailbox-Empty:before {
	content: "\EA8E";
}
.iconsmind-Mailbox-Full:before {
	content: "\EA8F";
}
.iconsmind-Male-2:before {
	content: "\EA90";
}
.iconsmind-Male-Sign:before {
	content: "\EA91";
}
.iconsmind-Male:before {
	content: "\EA92";
}
.iconsmind-MaleFemale:before {
	content: "\EA93";
}
.iconsmind-Man-Sign:before {
	content: "\EA94";
}
.iconsmind-Management:before {
	content: "\EA95";
}
.iconsmind-Mans-Underwear:before {
	content: "\EA96";
}
.iconsmind-Mans-Underwear2:before {
	content: "\EA97";
}
.iconsmind-Map-Marker:before {
	content: "\EA98";
}
.iconsmind-Map-Marker2:before {
	content: "\EA99";
}
.iconsmind-Map-Marker3:before {
	content: "\EA9A";
}
.iconsmind-Map:before {
	content: "\EA9B";
}
.iconsmind-Map2:before {
	content: "\EA9C";
}
.iconsmind-Marker-2:before {
	content: "\EA9D";
}
.iconsmind-Marker-3:before {
	content: "\EA9E";
}
.iconsmind-Marker:before {
	content: "\EA9F";
}
.iconsmind-Martini-Glass:before {
	content: "\EAA0";
}
.iconsmind-Mask:before {
	content: "\EAA1";
}
.iconsmind-Master-Card:before {
	content: "\EAA2";
}
.iconsmind-Maximize-Window:before {
	content: "\EAA3";
}
.iconsmind-Maximize:before {
	content: "\EAA4";
}
.iconsmind-Medal-2:before {
	content: "\EAA5";
}
.iconsmind-Medal-3:before {
	content: "\EAA6";
}
.iconsmind-Medal:before {
	content: "\EAA7";
}
.iconsmind-Medical-Sign:before {
	content: "\EAA8";
}
.iconsmind-Medicine-2:before {
	content: "\EAA9";
}
.iconsmind-Medicine-3:before {
	content: "\EAAA";
}
.iconsmind-Medicine:before {
	content: "\EAAB";
}
.iconsmind-Megaphone:before {
	content: "\EAAC";
}
.iconsmind-Memory-Card:before {
	content: "\EAAD";
}
.iconsmind-Memory-Card2:before {
	content: "\EAAE";
}
.iconsmind-Memory-Card3:before {
	content: "\EAAF";
}
.iconsmind-Men:before {
	content: "\EAB0";
}
.iconsmind-Menorah:before {
	content: "\EAB1";
}
.iconsmind-Mens:before {
	content: "\EAB2";
}
.iconsmind-Metacafe:before {
	content: "\EAB3";
}
.iconsmind-Mexico:before {
	content: "\EAB4";
}
.iconsmind-Mic:before {
	content: "\EAB5";
}
.iconsmind-Microphone-2:before {
	content: "\EAB6";
}
.iconsmind-Microphone-3:before {
	content: "\EAB7";
}
.iconsmind-Microphone-4:before {
	content: "\EAB8";
}
.iconsmind-Microphone-5:before {
	content: "\EAB9";
}
.iconsmind-Microphone-6:before {
	content: "\EABA";
}
.iconsmind-Microphone-7:before {
	content: "\EABB";
}
.iconsmind-Microphone:before {
	content: "\EABC";
}
.iconsmind-Microscope:before {
	content: "\EABD";
}
.iconsmind-Milk-Bottle:before {
	content: "\EABE";
}
.iconsmind-Mine:before {
	content: "\EABF";
}
.iconsmind-Minimize-Maximize-Close-Window:before {
	content: "\EAC0";
}
.iconsmind-Minimize-Window:before {
	content: "\EAC1";
}
.iconsmind-Minimize:before {
	content: "\EAC2";
}
.iconsmind-Mirror:before {
	content: "\EAC3";
}
.iconsmind-Mixer:before {
	content: "\EAC4";
}
.iconsmind-Mixx:before {
	content: "\EAC5";
}
.iconsmind-Money-2:before {
	content: "\EAC6";
}
.iconsmind-Money-Bag:before {
	content: "\EAC7";
}
.iconsmind-Money-Smiley:before {
	content: "\EAC8";
}
.iconsmind-Money:before {
	content: "\EAC9";
}
.iconsmind-Monitor-2:before {
	content: "\EACA";
}
.iconsmind-Monitor-3:before {
	content: "\EACB";
}
.iconsmind-Monitor-4:before {
	content: "\EACC";
}
.iconsmind-Monitor-5:before {
	content: "\EACD";
}
.iconsmind-Monitor-Analytics:before {
	content: "\EACE";
}
.iconsmind-Monitor-Laptop:before {
	content: "\EACF";
}
.iconsmind-Monitor-phone:before {
	content: "\EAD0";
}
.iconsmind-Monitor-Tablet:before {
	content: "\EAD1";
}
.iconsmind-Monitor-Vertical:before {
	content: "\EAD2";
}
.iconsmind-Monitor:before {
	content: "\EAD3";
}
.iconsmind-Monitoring:before {
	content: "\EAD4";
}
.iconsmind-Monkey:before {
	content: "\EAD5";
}
.iconsmind-Monster:before {
	content: "\EAD6";
}
.iconsmind-Morocco:before {
	content: "\EAD7";
}
.iconsmind-Motorcycle:before {
	content: "\EAD8";
}
.iconsmind-Mouse-2:before {
	content: "\EAD9";
}
.iconsmind-Mouse-3:before {
	content: "\EADA";
}
.iconsmind-Mouse-4:before {
	content: "\EADB";
}
.iconsmind-Mouse-Pointer:before {
	content: "\EADC";
}
.iconsmind-Mouse:before {
	content: "\EADD";
}
.iconsmind-Moustache-Smiley:before {
	content: "\EADE";
}
.iconsmind-Movie-Ticket:before {
	content: "\EADF";
}
.iconsmind-Movie:before {
	content: "\EAE0";
}
.iconsmind-Mp3-File:before {
	content: "\EAE1";
}
.iconsmind-Museum:before {
	content: "\EAE2";
}
.iconsmind-Mushroom:before {
	content: "\EAE3";
}
.iconsmind-Music-Note:before {
	content: "\EAE4";
}
.iconsmind-Music-Note2:before {
	content: "\EAE5";
}
.iconsmind-Music-Note3:before {
	content: "\EAE6";
}
.iconsmind-Music-Note4:before {
	content: "\EAE7";
}
.iconsmind-Music-Player:before {
	content: "\EAE8";
}
.iconsmind-Mustache-2:before {
	content: "\EAE9";
}
.iconsmind-Mustache-3:before {
	content: "\EAEA";
}
.iconsmind-Mustache-4:before {
	content: "\EAEB";
}
.iconsmind-Mustache-5:before {
	content: "\EAEC";
}
.iconsmind-Mustache-6:before {
	content: "\EAED";
}
.iconsmind-Mustache-7:before {
	content: "\EAEE";
}
.iconsmind-Mustache-8:before {
	content: "\EAEF";
}
.iconsmind-Mustache:before {
	content: "\EAF0";
}
.iconsmind-Mute:before {
	content: "\EAF1";
}
.iconsmind-Myspace:before {
	content: "\EAF2";
}
.iconsmind-Navigat-Start:before {
	content: "\EAF3";
}
.iconsmind-Navigate-End:before {
	content: "\EAF4";
}
.iconsmind-Navigation-LeftWindow:before {
	content: "\EAF5";
}
.iconsmind-Navigation-RightWindow:before {
	content: "\EAF6";
}
.iconsmind-Nepal:before {
	content: "\EAF7";
}
.iconsmind-Netscape:before {
	content: "\EAF8";
}
.iconsmind-Network-Window:before {
	content: "\EAF9";
}
.iconsmind-Network:before {
	content: "\EAFA";
}
.iconsmind-Neutron:before {
	content: "\EAFB";
}
.iconsmind-New-Mail:before {
	content: "\EAFC";
}
.iconsmind-New-Tab:before {
	content: "\EAFD";
}
.iconsmind-Newspaper-2:before {
	content: "\EAFE";
}
.iconsmind-Newspaper:before {
	content: "\EAFF";
}
.iconsmind-Newsvine:before {
	content: "\EB00";
}
.iconsmind-Next2:before {
	content: "\EB01";
}
.iconsmind-Next-3:before {
	content: "\EB02";
}
.iconsmind-Next-Music:before {
	content: "\EB03";
}
.iconsmind-Next:before {
	content: "\EB04";
}
.iconsmind-No-Battery:before {
	content: "\EB05";
}
.iconsmind-No-Drop:before {
	content: "\EB06";
}
.iconsmind-No-Flash:before {
	content: "\EB07";
}
.iconsmind-No-Smoking:before {
	content: "\EB08";
}
.iconsmind-Noose:before {
	content: "\EB09";
}
.iconsmind-Normal-Text:before {
	content: "\EB0A";
}
.iconsmind-Note:before {
	content: "\EB0B";
}
.iconsmind-Notepad-2:before {
	content: "\EB0C";
}
.iconsmind-Notepad:before {
	content: "\EB0D";
}
.iconsmind-Nuclear:before {
	content: "\EB0E";
}
.iconsmind-Numbering-List:before {
	content: "\EB0F";
}
.iconsmind-Nurse:before {
	content: "\EB10";
}
.iconsmind-Office-Lamp:before {
	content: "\EB11";
}
.iconsmind-Office:before {
	content: "\EB12";
}
.iconsmind-Oil:before {
	content: "\EB13";
}
.iconsmind-Old-Camera:before {
	content: "\EB14";
}
.iconsmind-Old-Cassette:before {
	content: "\EB15";
}
.iconsmind-Old-Clock:before {
	content: "\EB16";
}
.iconsmind-Old-Radio:before {
	content: "\EB17";
}
.iconsmind-Old-Sticky:before {
	content: "\EB18";
}
.iconsmind-Old-Sticky2:before {
	content: "\EB19";
}
.iconsmind-Old-Telephone:before {
	content: "\EB1A";
}
.iconsmind-Old-TV:before {
	content: "\EB1B";
}
.iconsmind-On-Air:before {
	content: "\EB1C";
}
.iconsmind-On-Off-2:before {
	content: "\EB1D";
}
.iconsmind-On-Off-3:before {
	content: "\EB1E";
}
.iconsmind-On-off:before {
	content: "\EB1F";
}
.iconsmind-One-Finger:before {
	content: "\EB20";
}
.iconsmind-One-FingerTouch:before {
	content: "\EB21";
}
.iconsmind-One-Window:before {
	content: "\EB22";
}
.iconsmind-Open-Banana:before {
	content: "\EB23";
}
.iconsmind-Open-Book:before {
	content: "\EB24";
}
.iconsmind-Opera-House:before {
	content: "\EB25";
}
.iconsmind-Opera:before {
	content: "\EB26";
}
.iconsmind-Optimization:before {
	content: "\EB27";
}
.iconsmind-Orientation-2:before {
	content: "\EB28";
}
.iconsmind-Orientation-3:before {
	content: "\EB29";
}
.iconsmind-Orientation:before {
	content: "\EB2A";
}
.iconsmind-Orkut:before {
	content: "\EB2B";
}
.iconsmind-Ornament:before {
	content: "\EB2C";
}
.iconsmind-Over-Time:before {
	content: "\EB2D";
}
.iconsmind-Over-Time2:before {
	content: "\EB2E";
}
.iconsmind-Owl:before {
	content: "\EB2F";
}
.iconsmind-Pac-Man:before {
	content: "\EB30";
}
.iconsmind-Paint-Brush:before {
	content: "\EB31";
}
.iconsmind-Paint-Bucket:before {
	content: "\EB32";
}
.iconsmind-Paintbrush:before {
	content: "\EB33";
}
.iconsmind-Palette:before {
	content: "\EB34";
}
.iconsmind-Palm-Tree:before {
	content: "\EB35";
}
.iconsmind-Panda:before {
	content: "\EB36";
}
.iconsmind-Panorama:before {
	content: "\EB37";
}
.iconsmind-Pantheon:before {
	content: "\EB38";
}
.iconsmind-Pantone:before {
	content: "\EB39";
}
.iconsmind-Pants:before {
	content: "\EB3A";
}
.iconsmind-Paper-Plane:before {
	content: "\EB3B";
}
.iconsmind-Paper:before {
	content: "\EB3C";
}
.iconsmind-Parasailing:before {
	content: "\EB3D";
}
.iconsmind-Parrot:before {
	content: "\EB3E";
}
.iconsmind-Password-2shopping:before {
	content: "\EB3F";
}
.iconsmind-Password-Field:before {
	content: "\EB40";
}
.iconsmind-Password-shopping:before {
	content: "\EB41";
}
.iconsmind-Password:before {
	content: "\EB42";
}
.iconsmind-pause-2:before {
	content: "\EB43";
}
.iconsmind-Pause:before {
	content: "\EB44";
}
.iconsmind-Paw:before {
	content: "\EB45";
}
.iconsmind-Pawn:before {
	content: "\EB46";
}
.iconsmind-Paypal:before {
	content: "\EB47";
}
.iconsmind-Pen-2:before {
	content: "\EB48";
}
.iconsmind-Pen-3:before {
	content: "\EB49";
}
.iconsmind-Pen-4:before {
	content: "\EB4A";
}
.iconsmind-Pen-5:before {
	content: "\EB4B";
}
.iconsmind-Pen-6:before {
	content: "\EB4C";
}
.iconsmind-Pen:before {
	content: "\EB4D";
}
.iconsmind-Pencil-Ruler:before {
	content: "\EB4E";
}
.iconsmind-Pencil:before {
	content: "\EB4F";
}
.iconsmind-Penguin:before {
	content: "\EB50";
}
.iconsmind-Pentagon:before {
	content: "\EB51";
}
.iconsmind-People-onCloud:before {
	content: "\EB52";
}
.iconsmind-Pepper-withFire:before {
	content: "\EB53";
}
.iconsmind-Pepper:before {
	content: "\EB54";
}
.iconsmind-Petrol:before {
	content: "\EB55";
}
.iconsmind-Petronas-Tower:before {
	content: "\EB56";
}
.iconsmind-Philipines:before {
	content: "\EB57";
}
.iconsmind-Phone-2:before {
	content: "\EB58";
}
.iconsmind-Phone-3:before {
	content: "\EB59";
}
.iconsmind-Phone-3G:before {
	content: "\EB5A";
}
.iconsmind-Phone-4G:before {
	content: "\EB5B";
}
.iconsmind-Phone-Simcard:before {
	content: "\EB5C";
}
.iconsmind-Phone-SMS:before {
	content: "\EB5D";
}
.iconsmind-Phone-Wifi:before {
	content: "\EB5E";
}
.iconsmind-Phone:before {
	content: "\EB5F";
}
.iconsmind-Photo-2:before {
	content: "\EB60";
}
.iconsmind-Photo-3:before {
	content: "\EB61";
}
.iconsmind-Photo-Album:before {
	content: "\EB62";
}
.iconsmind-Photo-Album2:before {
	content: "\EB63";
}
.iconsmind-Photo-Album3:before {
	content: "\EB64";
}
.iconsmind-Photo:before {
	content: "\EB65";
}
.iconsmind-Photos:before {
	content: "\EB66";
}
.iconsmind-Physics:before {
	content: "\EB67";
}
.iconsmind-Pi:before {
	content: "\EB68";
}
.iconsmind-Piano:before {
	content: "\EB69";
}
.iconsmind-Picasa:before {
	content: "\EB6A";
}
.iconsmind-Pie-Chart:before {
	content: "\EB6B";
}
.iconsmind-Pie-Chart2:before {
	content: "\EB6C";
}
.iconsmind-Pie-Chart3:before {
	content: "\EB6D";
}
.iconsmind-Pilates-2:before {
	content: "\EB6E";
}
.iconsmind-Pilates-3:before {
	content: "\EB6F";
}
.iconsmind-Pilates:before {
	content: "\EB70";
}
.iconsmind-Pilot:before {
	content: "\EB71";
}
.iconsmind-Pinch:before {
	content: "\EB72";
}
.iconsmind-Ping-Pong:before {
	content: "\EB73";
}
.iconsmind-Pinterest:before {
	content: "\EB74";
}
.iconsmind-Pipe:before {
	content: "\EB75";
}
.iconsmind-Pipette:before {
	content: "\EB76";
}
.iconsmind-Piramids:before {
	content: "\EB77";
}
.iconsmind-Pisces-2:before {
	content: "\EB78";
}
.iconsmind-Pisces:before {
	content: "\EB79";
}
.iconsmind-Pizza-Slice:before {
	content: "\EB7A";
}
.iconsmind-Pizza:before {
	content: "\EB7B";
}
.iconsmind-Plane-2:before {
	content: "\EB7C";
}
.iconsmind-Plane:before {
	content: "\EB7D";
}
.iconsmind-Plant:before {
	content: "\EB7E";
}
.iconsmind-Plasmid:before {
	content: "\EB7F";
}
.iconsmind-Plaster:before {
	content: "\EB80";
}
.iconsmind-Plastic-CupPhone:before {
	content: "\EB81";
}
.iconsmind-Plastic-CupPhone2:before {
	content: "\EB82";
}
.iconsmind-Plate:before {
	content: "\EB83";
}
.iconsmind-Plates:before {
	content: "\EB84";
}
.iconsmind-Plaxo:before {
	content: "\EB85";
}
.iconsmind-Play-Music:before {
	content: "\EB86";
}
.iconsmind-Plug-In:before {
	content: "\EB87";
}
.iconsmind-Plug-In2:before {
	content: "\EB88";
}
.iconsmind-Plurk:before {
	content: "\EB89";
}
.iconsmind-Pointer:before {
	content: "\EB8A";
}
.iconsmind-Poland:before {
	content: "\EB8B";
}
.iconsmind-Police-Man:before {
	content: "\EB8C";
}
.iconsmind-Police-Station:before {
	content: "\EB8D";
}
.iconsmind-Police-Woman:before {
	content: "\EB8E";
}
.iconsmind-Police:before {
	content: "\EB8F";
}
.iconsmind-Polo-Shirt:before {
	content: "\EB90";
}
.iconsmind-Portrait:before {
	content: "\EB91";
}
.iconsmind-Portugal:before {
	content: "\EB92";
}
.iconsmind-Post-Mail:before {
	content: "\EB93";
}
.iconsmind-Post-Mail2:before {
	content: "\EB94";
}
.iconsmind-Post-Office:before {
	content: "\EB95";
}
.iconsmind-Post-Sign:before {
	content: "\EB96";
}
.iconsmind-Post-Sign2ways:before {
	content: "\EB97";
}
.iconsmind-Posterous:before {
	content: "\EB98";
}
.iconsmind-Pound-Sign:before {
	content: "\EB99";
}
.iconsmind-Pound-Sign2:before {
	content: "\EB9A";
}
.iconsmind-Pound:before {
	content: "\EB9B";
}
.iconsmind-Power-2:before {
	content: "\EB9C";
}
.iconsmind-Power-3:before {
	content: "\EB9D";
}
.iconsmind-Power-Cable:before {
	content: "\EB9E";
}
.iconsmind-Power-Station:before {
	content: "\EB9F";
}
.iconsmind-Power:before {
	content: "\EBA0";
}
.iconsmind-Prater:before {
	content: "\EBA1";
}
.iconsmind-Present:before {
	content: "\EBA2";
}
.iconsmind-Presents:before {
	content: "\EBA3";
}
.iconsmind-Press:before {
	content: "\EBA4";
}
.iconsmind-Preview:before {
	content: "\EBA5";
}
.iconsmind-Previous:before {
	content: "\EBA6";
}
.iconsmind-Pricing:before {
	content: "\EBA7";
}
.iconsmind-Printer:before {
	content: "\EBA8";
}
.iconsmind-Professor:before {
	content: "\EBA9";
}
.iconsmind-Profile:before {
	content: "\EBAA";
}
.iconsmind-Project:before {
	content: "\EBAB";
}
.iconsmind-Projector-2:before {
	content: "\EBAC";
}
.iconsmind-Projector:before {
	content: "\EBAD";
}
.iconsmind-Pulse:before {
	content: "\EBAE";
}
.iconsmind-Pumpkin:before {
	content: "\EBAF";
}
.iconsmind-Punk:before {
	content: "\EBB0";
}
.iconsmind-Punker:before {
	content: "\EBB1";
}
.iconsmind-Puzzle:before {
	content: "\EBB2";
}
.iconsmind-QIK:before {
	content: "\EBB3";
}
.iconsmind-QR-Code:before {
	content: "\EBB4";
}
.iconsmind-Queen-2:before {
	content: "\EBB5";
}
.iconsmind-Queen:before {
	content: "\EBB6";
}
.iconsmind-Quill-2:before {
	content: "\EBB7";
}
.iconsmind-Quill-3:before {
	content: "\EBB8";
}
.iconsmind-Quill:before {
	content: "\EBB9";
}
.iconsmind-Quotes-2:before {
	content: "\EBBA";
}
.iconsmind-Quotes:before {
	content: "\EBBB";
}
.iconsmind-Radio:before {
	content: "\EBBC";
}
.iconsmind-Radioactive:before {
	content: "\EBBD";
}
.iconsmind-Rafting:before {
	content: "\EBBE";
}
.iconsmind-Rain-Drop:before {
	content: "\EBBF";
}
.iconsmind-Rainbow-2:before {
	content: "\EBC0";
}
.iconsmind-Rainbow:before {
	content: "\EBC1";
}
.iconsmind-Ram:before {
	content: "\EBC2";
}
.iconsmind-Razzor-Blade:before {
	content: "\EBC3";
}
.iconsmind-Receipt-2:before {
	content: "\EBC4";
}
.iconsmind-Receipt-3:before {
	content: "\EBC5";
}
.iconsmind-Receipt-4:before {
	content: "\EBC6";
}
.iconsmind-Receipt:before {
	content: "\EBC7";
}
.iconsmind-Record2:before {
	content: "\EBC8";
}
.iconsmind-Record-3:before {
	content: "\EBC9";
}
.iconsmind-Record-Music:before {
	content: "\EBCA";
}
.iconsmind-Record:before {
	content: "\EBCB";
}
.iconsmind-Recycling-2:before {
	content: "\EBCC";
}
.iconsmind-Recycling:before {
	content: "\EBCD";
}
.iconsmind-Reddit:before {
	content: "\EBCE";
}
.iconsmind-Redhat:before {
	content: "\EBCF";
}
.iconsmind-Redirect:before {
	content: "\EBD0";
}
.iconsmind-Redo:before {
	content: "\EBD1";
}
.iconsmind-Reel:before {
	content: "\EBD2";
}
.iconsmind-Refinery:before {
	content: "\EBD3";
}
.iconsmind-Refresh-Window:before {
	content: "\EBD4";
}
.iconsmind-Refresh:before {
	content: "\EBD5";
}
.iconsmind-Reload-2:before {
	content: "\EBD6";
}
.iconsmind-Reload-3:before {
	content: "\EBD7";
}
.iconsmind-Reload:before {
	content: "\EBD8";
}
.iconsmind-Remote-Controll:before {
	content: "\EBD9";
}
.iconsmind-Remote-Controll2:before {
	content: "\EBDA";
}
.iconsmind-Remove-Bag:before {
	content: "\EBDB";
}
.iconsmind-Remove-Basket:before {
	content: "\EBDC";
}
.iconsmind-Remove-Cart:before {
	content: "\EBDD";
}
.iconsmind-Remove-File:before {
	content: "\EBDE";
}
.iconsmind-Remove-User:before {
	content: "\EBDF";
}
.iconsmind-Remove-Window:before {
	content: "\EBE0";
}
.iconsmind-Remove:before {
	content: "\EBE1";
}
.iconsmind-Rename:before {
	content: "\EBE2";
}
.iconsmind-Repair:before {
	content: "\EBE3";
}
.iconsmind-Repeat-2:before {
	content: "\EBE4";
}
.iconsmind-Repeat-3:before {
	content: "\EBE5";
}
.iconsmind-Repeat-4:before {
	content: "\EBE6";
}
.iconsmind-Repeat-5:before {
	content: "\EBE7";
}
.iconsmind-Repeat-6:before {
	content: "\EBE8";
}
.iconsmind-Repeat-7:before {
	content: "\EBE9";
}
.iconsmind-Repeat:before {
	content: "\EBEA";
}
.iconsmind-Reset:before {
	content: "\EBEB";
}
.iconsmind-Resize:before {
	content: "\EBEC";
}
.iconsmind-Restore-Window:before {
	content: "\EBED";
}
.iconsmind-Retouching:before {
	content: "\EBEE";
}
.iconsmind-Retro-Camera:before {
	content: "\EBEF";
}
.iconsmind-Retro:before {
	content: "\EBF0";
}
.iconsmind-Retweet:before {
	content: "\EBF1";
}
.iconsmind-Reverbnation:before {
	content: "\EBF2";
}
.iconsmind-Rewind:before {
	content: "\EBF3";
}
.iconsmind-RGB:before {
	content: "\EBF4";
}
.iconsmind-Ribbon-2:before {
	content: "\EBF5";
}
.iconsmind-Ribbon-3:before {
	content: "\EBF6";
}
.iconsmind-Ribbon:before {
	content: "\EBF7";
}
.iconsmind-Right-2:before {
	content: "\EBF8";
}
.iconsmind-Right-3:before {
	content: "\EBF9";
}
.iconsmind-Right-4:before {
	content: "\EBFA";
}
.iconsmind-Right-ToLeft:before {
	content: "\EBFB";
}
.iconsmind-Right:before {
	content: "\EBFC";
}
.iconsmind-Road-2:before {
	content: "\EBFD";
}
.iconsmind-Road-3:before {
	content: "\EBFE";
}
.iconsmind-Road:before {
	content: "\EBFF";
}
.iconsmind-Robot-2:before {
	content: "\EC00";
}
.iconsmind-Robot:before {
	content: "\EC01";
}
.iconsmind-Rock-andRoll:before {
	content: "\EC02";
}
.iconsmind-Rocket:before {
	content: "\EC03";
}
.iconsmind-Roller:before {
	content: "\EC04";
}
.iconsmind-Roof:before {
	content: "\EC05";
}
.iconsmind-Rook:before {
	content: "\EC06";
}
.iconsmind-Rotate-Gesture:before {
	content: "\EC07";
}
.iconsmind-Rotate-Gesture2:before {
	content: "\EC08";
}
.iconsmind-Rotate-Gesture3:before {
	content: "\EC09";
}
.iconsmind-Rotation-390:before {
	content: "\EC0A";
}
.iconsmind-Rotation:before {
	content: "\EC0B";
}
.iconsmind-Router-2:before {
	content: "\EC0C";
}
.iconsmind-Router:before {
	content: "\EC0D";
}
.iconsmind-RSS:before {
	content: "\EC0E";
}
.iconsmind-Ruler-2:before {
	content: "\EC0F";
}
.iconsmind-Ruler:before {
	content: "\EC10";
}
.iconsmind-Running-Shoes:before {
	content: "\EC11";
}
.iconsmind-Running:before {
	content: "\EC12";
}
.iconsmind-Safari:before {
	content: "\EC13";
}
.iconsmind-Safe-Box:before {
	content: "\EC14";
}
.iconsmind-Safe-Box2:before {
	content: "\EC15";
}
.iconsmind-Safety-PinClose:before {
	content: "\EC16";
}
.iconsmind-Safety-PinOpen:before {
	content: "\EC17";
}
.iconsmind-Sagittarus-2:before {
	content: "\EC18";
}
.iconsmind-Sagittarus:before {
	content: "\EC19";
}
.iconsmind-Sailing-Ship:before {
	content: "\EC1A";
}
.iconsmind-Sand-watch:before {
	content: "\EC1B";
}
.iconsmind-Sand-watch2:before {
	content: "\EC1C";
}
.iconsmind-Santa-Claus:before {
	content: "\EC1D";
}
.iconsmind-Santa-Claus2:before {
	content: "\EC1E";
}
.iconsmind-Santa-onSled:before {
	content: "\EC1F";
}
.iconsmind-Satelite-2:before {
	content: "\EC20";
}
.iconsmind-Satelite:before {
	content: "\EC21";
}
.iconsmind-Save-Window:before {
	content: "\EC22";
}
.iconsmind-Save:before {
	content: "\EC23";
}
.iconsmind-Saw:before {
	content: "\EC24";
}
.iconsmind-Saxophone:before {
	content: "\EC25";
}
.iconsmind-Scale:before {
	content: "\EC26";
}
.iconsmind-Scarf:before {
	content: "\EC27";
}
.iconsmind-Scissor:before {
	content: "\EC28";
}
.iconsmind-Scooter-Front:before {
	content: "\EC29";
}
.iconsmind-Scooter:before {
	content: "\EC2A";
}
.iconsmind-Scorpio-2:before {
	content: "\EC2B";
}
.iconsmind-Scorpio:before {
	content: "\EC2C";
}
.iconsmind-Scotland:before {
	content: "\EC2D";
}
.iconsmind-Screwdriver:before {
	content: "\EC2E";
}
.iconsmind-Scroll-Fast:before {
	content: "\EC2F";
}
.iconsmind-Scroll:before {
	content: "\EC30";
}
.iconsmind-Scroller-2:before {
	content: "\EC31";
}
.iconsmind-Scroller:before {
	content: "\EC32";
}
.iconsmind-Sea-Dog:before {
	content: "\EC33";
}
.iconsmind-Search-onCloud:before {
	content: "\EC34";
}
.iconsmind-Search-People:before {
	content: "\EC35";
}
.iconsmind-secound:before {
	content: "\EC36";
}
.iconsmind-secound2:before {
	content: "\EC37";
}
.iconsmind-Security-Block:before {
	content: "\EC38";
}
.iconsmind-Security-Bug:before {
	content: "\EC39";
}
.iconsmind-Security-Camera:before {
	content: "\EC3A";
}
.iconsmind-Security-Check:before {
	content: "\EC3B";
}
.iconsmind-Security-Settings:before {
	content: "\EC3C";
}
.iconsmind-Security-Smiley:before {
	content: "\EC3D";
}
.iconsmind-Securiy-Remove:before {
	content: "\EC3E";
}
.iconsmind-Seed:before {
	content: "\EC3F";
}
.iconsmind-Selfie:before {
	content: "\EC40";
}
.iconsmind-Serbia:before {
	content: "\EC41";
}
.iconsmind-Server-2:before {
	content: "\EC42";
}
.iconsmind-Server:before {
	content: "\EC43";
}
.iconsmind-Servers:before {
	content: "\EC44";
}
.iconsmind-Settings-Window:before {
	content: "\EC45";
}
.iconsmind-Sewing-Machine:before {
	content: "\EC46";
}
.iconsmind-Sexual:before {
	content: "\EC47";
}
.iconsmind-Share-onCloud:before {
	content: "\EC48";
}
.iconsmind-Share-Window:before {
	content: "\EC49";
}
.iconsmind-Share:before {
	content: "\EC4A";
}
.iconsmind-Sharethis:before {
	content: "\EC4B";
}
.iconsmind-Shark:before {
	content: "\EC4C";
}
.iconsmind-Sheep:before {
	content: "\EC4D";
}
.iconsmind-Sheriff-Badge:before {
	content: "\EC4E";
}
.iconsmind-Shield:before {
	content: "\EC4F";
}
.iconsmind-Ship-2:before {
	content: "\EC50";
}
.iconsmind-Ship:before {
	content: "\EC51";
}
.iconsmind-Shirt:before {
	content: "\EC52";
}
.iconsmind-Shoes-2:before {
	content: "\EC53";
}
.iconsmind-Shoes-3:before {
	content: "\EC54";
}
.iconsmind-Shoes:before {
	content: "\EC55";
}
.iconsmind-Shop-2:before {
	content: "\EC56";
}
.iconsmind-Shop-3:before {
	content: "\EC57";
}
.iconsmind-Shop-4:before {
	content: "\EC58";
}
.iconsmind-Shop:before {
	content: "\EC59";
}
.iconsmind-Shopping-Bag:before {
	content: "\EC5A";
}
.iconsmind-Shopping-Basket:before {
	content: "\EC5B";
}
.iconsmind-Shopping-Cart:before {
	content: "\EC5C";
}
.iconsmind-Short-Pants:before {
	content: "\EC5D";
}
.iconsmind-Shoutwire:before {
	content: "\EC5E";
}
.iconsmind-Shovel:before {
	content: "\EC5F";
}
.iconsmind-Shuffle-2:before {
	content: "\EC60";
}
.iconsmind-Shuffle-3:before {
	content: "\EC61";
}
.iconsmind-Shuffle-4:before {
	content: "\EC62";
}
.iconsmind-Shuffle:before {
	content: "\EC63";
}
.iconsmind-Shutter:before {
	content: "\EC64";
}
.iconsmind-Sidebar-Window:before {
	content: "\EC65";
}
.iconsmind-Signal:before {
	content: "\EC66";
}
.iconsmind-Singapore:before {
	content: "\EC67";
}
.iconsmind-Skate-Shoes:before {
	content: "\EC68";
}
.iconsmind-Skateboard-2:before {
	content: "\EC69";
}
.iconsmind-Skateboard:before {
	content: "\EC6A";
}
.iconsmind-Skeleton:before {
	content: "\EC6B";
}
.iconsmind-Ski:before {
	content: "\EC6C";
}
.iconsmind-Skirt:before {
	content: "\EC6D";
}
.iconsmind-Skrill:before {
	content: "\EC6E";
}
.iconsmind-Skull:before {
	content: "\EC6F";
}
.iconsmind-Skydiving:before {
	content: "\EC70";
}
.iconsmind-Skype:before {
	content: "\EC71";
}
.iconsmind-Sled-withGifts:before {
	content: "\EC72";
}
.iconsmind-Sled:before {
	content: "\EC73";
}
.iconsmind-Sleeping:before {
	content: "\EC74";
}
.iconsmind-Sleet:before {
	content: "\EC75";
}
.iconsmind-Slippers:before {
	content: "\EC76";
}
.iconsmind-Smart:before {
	content: "\EC77";
}
.iconsmind-Smartphone-2:before {
	content: "\EC78";
}
.iconsmind-Smartphone-3:before {
	content: "\EC79";
}
.iconsmind-Smartphone-4:before {
	content: "\EC7A";
}
.iconsmind-Smartphone-Secure:before {
	content: "\EC7B";
}
.iconsmind-Smartphone:before {
	content: "\EC7C";
}
.iconsmind-Smile:before {
	content: "\EC7D";
}
.iconsmind-Smoking-Area:before {
	content: "\EC7E";
}
.iconsmind-Smoking-Pipe:before {
	content: "\EC7F";
}
.iconsmind-Snake:before {
	content: "\EC80";
}
.iconsmind-Snorkel:before {
	content: "\EC81";
}
.iconsmind-Snow-2:before {
	content: "\EC82";
}
.iconsmind-Snow-Dome:before {
	content: "\EC83";
}
.iconsmind-Snow-Storm:before {
	content: "\EC84";
}
.iconsmind-Snow:before {
	content: "\EC85";
}
.iconsmind-Snowflake-2:before {
	content: "\EC86";
}
.iconsmind-Snowflake-3:before {
	content: "\EC87";
}
.iconsmind-Snowflake-4:before {
	content: "\EC88";
}
.iconsmind-Snowflake:before {
	content: "\EC89";
}
.iconsmind-Snowman:before {
	content: "\EC8A";
}
.iconsmind-Soccer-Ball:before {
	content: "\EC8B";
}
.iconsmind-Soccer-Shoes:before {
	content: "\EC8C";
}
.iconsmind-Socks:before {
	content: "\EC8D";
}
.iconsmind-Solar:before {
	content: "\EC8E";
}
.iconsmind-Sound-Wave:before {
	content: "\EC8F";
}
.iconsmind-Sound:before {
	content: "\EC90";
}
.iconsmind-Soundcloud:before {
	content: "\EC91";
}
.iconsmind-Soup:before {
	content: "\EC92";
}
.iconsmind-South-Africa:before {
	content: "\EC93";
}
.iconsmind-Space-Needle:before {
	content: "\EC94";
}
.iconsmind-Spain:before {
	content: "\EC95";
}
.iconsmind-Spam-Mail:before {
	content: "\EC96";
}
.iconsmind-Speach-Bubble:before {
	content: "\EC97";
}
.iconsmind-Speach-Bubble2:before {
	content: "\EC98";
}
.iconsmind-Speach-Bubble3:before {
	content: "\EC99";
}
.iconsmind-Speach-Bubble4:before {
	content: "\EC9A";
}
.iconsmind-Speach-Bubble5:before {
	content: "\EC9B";
}
.iconsmind-Speach-Bubble6:before {
	content: "\EC9C";
}
.iconsmind-Speach-Bubble7:before {
	content: "\EC9D";
}
.iconsmind-Speach-Bubble8:before {
	content: "\EC9E";
}
.iconsmind-Speach-Bubble9:before {
	content: "\EC9F";
}
.iconsmind-Speach-Bubble10:before {
	content: "\ECA0";
}
.iconsmind-Speach-Bubble11:before {
	content: "\ECA1";
}
.iconsmind-Speach-Bubble12:before {
	content: "\ECA2";
}
.iconsmind-Speach-Bubble13:before {
	content: "\ECA3";
}
.iconsmind-Speach-BubbleAsking:before {
	content: "\ECA4";
}
.iconsmind-Speach-BubbleComic:before {
	content: "\ECA5";
}
.iconsmind-Speach-BubbleComic2:before {
	content: "\ECA6";
}
.iconsmind-Speach-BubbleComic3:before {
	content: "\ECA7";
}
.iconsmind-Speach-BubbleComic4:before {
	content: "\ECA8";
}
.iconsmind-Speach-BubbleDialog:before {
	content: "\ECA9";
}
.iconsmind-Speach-Bubbles:before {
	content: "\ECAA";
}
.iconsmind-Speak-2:before {
	content: "\ECAB";
}
.iconsmind-Speak:before {
	content: "\ECAC";
}
.iconsmind-Speaker-2:before {
	content: "\ECAD";
}
.iconsmind-Speaker:before {
	content: "\ECAE";
}
.iconsmind-Spell-Check:before {
	content: "\ECAF";
}
.iconsmind-Spell-CheckABC:before {
	content: "\ECB0";
}
.iconsmind-Spermium:before {
	content: "\ECB1";
}
.iconsmind-Spider:before {
	content: "\ECB2";
}
.iconsmind-Spiderweb:before {
	content: "\ECB3";
}
.iconsmind-Split-FourSquareWindow:before {
	content: "\ECB4";
}
.iconsmind-Split-Horizontal:before {
	content: "\ECB5";
}
.iconsmind-Split-Horizontal2Window:before {
	content: "\ECB6";
}
.iconsmind-Split-Vertical:before {
	content: "\ECB7";
}
.iconsmind-Split-Vertical2:before {
	content: "\ECB8";
}
.iconsmind-Split-Window:before {
	content: "\ECB9";
}
.iconsmind-Spoder:before {
	content: "\ECBA";
}
.iconsmind-Spoon:before {
	content: "\ECBB";
}
.iconsmind-Sport-Mode:before {
	content: "\ECBC";
}
.iconsmind-Sports-Clothings1:before {
	content: "\ECBD";
}
.iconsmind-Sports-Clothings2:before {
	content: "\ECBE";
}
.iconsmind-Sports-Shirt:before {
	content: "\ECBF";
}
.iconsmind-Spot:before {
	content: "\ECC0";
}
.iconsmind-Spray:before {
	content: "\ECC1";
}
.iconsmind-Spread:before {
	content: "\ECC2";
}
.iconsmind-Spring:before {
	content: "\ECC3";
}
.iconsmind-Spurl:before {
	content: "\ECC4";
}
.iconsmind-Spy:before {
	content: "\ECC5";
}
.iconsmind-Squirrel:before {
	content: "\ECC6";
}
.iconsmind-SSL:before {
	content: "\ECC7";
}
.iconsmind-St-BasilsCathedral:before {
	content: "\ECC8";
}
.iconsmind-St-PaulsCathedral:before {
	content: "\ECC9";
}
.iconsmind-Stamp-2:before {
	content: "\ECCA";
}
.iconsmind-Stamp:before {
	content: "\ECCB";
}
.iconsmind-Stapler:before {
	content: "\ECCC";
}
.iconsmind-Star-Track:before {
	content: "\ECCD";
}
.iconsmind-Star:before {
	content: "\ECCE";
}
.iconsmind-Starfish:before {
	content: "\ECCF";
}
.iconsmind-Start2:before {
	content: "\ECD0";
}
.iconsmind-Start-3:before {
	content: "\ECD1";
}
.iconsmind-Start-ways:before {
	content: "\ECD2";
}
.iconsmind-Start:before {
	content: "\ECD3";
}
.iconsmind-Statistic:before {
	content: "\ECD4";
}
.iconsmind-Stethoscope:before {
	content: "\ECD5";
}
.iconsmind-stop--2:before {
	content: "\ECD6";
}
.iconsmind-Stop-Music:before {
	content: "\ECD7";
}
.iconsmind-Stop:before {
	content: "\ECD8";
}
.iconsmind-Stopwatch-2:before {
	content: "\ECD9";
}
.iconsmind-Stopwatch:before {
	content: "\ECDA";
}
.iconsmind-Storm:before {
	content: "\ECDB";
}
.iconsmind-Street-View:before {
	content: "\ECDC";
}
.iconsmind-Street-View2:before {
	content: "\ECDD";
}
.iconsmind-Strikethrough-Text:before {
	content: "\ECDE";
}
.iconsmind-Stroller:before {
	content: "\ECDF";
}
.iconsmind-Structure:before {
	content: "\ECE0";
}
.iconsmind-Student-Female:before {
	content: "\ECE1";
}
.iconsmind-Student-Hat:before {
	content: "\ECE2";
}
.iconsmind-Student-Hat2:before {
	content: "\ECE3";
}
.iconsmind-Student-Male:before {
	content: "\ECE4";
}
.iconsmind-Student-MaleFemale:before {
	content: "\ECE5";
}
.iconsmind-Students:before {
	content: "\ECE6";
}
.iconsmind-Studio-Flash:before {
	content: "\ECE7";
}
.iconsmind-Studio-Lightbox:before {
	content: "\ECE8";
}
.iconsmind-Stumbleupon:before {
	content: "\ECE9";
}
.iconsmind-Suit:before {
	content: "\ECEA";
}
.iconsmind-Suitcase:before {
	content: "\ECEB";
}
.iconsmind-Sum-2:before {
	content: "\ECEC";
}
.iconsmind-Sum:before {
	content: "\ECED";
}
.iconsmind-Summer:before {
	content: "\ECEE";
}
.iconsmind-Sun-CloudyRain:before {
	content: "\ECEF";
}
.iconsmind-Sun:before {
	content: "\ECF0";
}
.iconsmind-Sunglasses-2:before {
	content: "\ECF1";
}
.iconsmind-Sunglasses-3:before {
	content: "\ECF2";
}
.iconsmind-Sunglasses-Smiley:before {
	content: "\ECF3";
}
.iconsmind-Sunglasses-Smiley2:before {
	content: "\ECF4";
}
.iconsmind-Sunglasses-W:before {
	content: "\ECF5";
}
.iconsmind-Sunglasses-W2:before {
	content: "\ECF6";
}
.iconsmind-Sunglasses-W3:before {
	content: "\ECF7";
}
.iconsmind-Sunglasses:before {
	content: "\ECF8";
}
.iconsmind-Sunrise:before {
	content: "\ECF9";
}
.iconsmind-Sunset:before {
	content: "\ECFA";
}
.iconsmind-Superman:before {
	content: "\ECFB";
}
.iconsmind-Support:before {
	content: "\ECFC";
}
.iconsmind-Surprise:before {
	content: "\ECFD";
}
.iconsmind-Sushi:before {
	content: "\ECFE";
}
.iconsmind-Sweden:before {
	content: "\ECFF";
}
.iconsmind-Swimming-Short:before {
	content: "\ED00";
}
.iconsmind-Swimming:before {
	content: "\ED01";
}
.iconsmind-Swimmwear:before {
	content: "\ED02";
}
.iconsmind-Switch:before {
	content: "\ED03";
}
.iconsmind-Switzerland:before {
	content: "\ED04";
}
.iconsmind-Sync-Cloud:before {
	content: "\ED05";
}
.iconsmind-Sync:before {
	content: "\ED06";
}
.iconsmind-Synchronize-2:before {
	content: "\ED07";
}
.iconsmind-Synchronize:before {
	content: "\ED08";
}
.iconsmind-T-Shirt:before {
	content: "\ED09";
}
.iconsmind-Tablet-2:before {
	content: "\ED0A";
}
.iconsmind-Tablet-3:before {
	content: "\ED0B";
}
.iconsmind-Tablet-Orientation:before {
	content: "\ED0C";
}
.iconsmind-Tablet-Phone:before {
	content: "\ED0D";
}
.iconsmind-Tablet-Secure:before {
	content: "\ED0E";
}
.iconsmind-Tablet-Vertical:before {
	content: "\ED0F";
}
.iconsmind-Tablet:before {
	content: "\ED10";
}
.iconsmind-Tactic:before {
	content: "\ED11";
}
.iconsmind-Tag-2:before {
	content: "\ED12";
}
.iconsmind-Tag-3:before {
	content: "\ED13";
}
.iconsmind-Tag-4:before {
	content: "\ED14";
}
.iconsmind-Tag-5:before {
	content: "\ED15";
}
.iconsmind-Tag:before {
	content: "\ED16";
}
.iconsmind-Taj-Mahal:before {
	content: "\ED17";
}
.iconsmind-Talk-Man:before {
	content: "\ED18";
}
.iconsmind-Tap:before {
	content: "\ED19";
}
.iconsmind-Target-Market:before {
	content: "\ED1A";
}
.iconsmind-Target:before {
	content: "\ED1B";
}
.iconsmind-Taurus-2:before {
	content: "\ED1C";
}
.iconsmind-Taurus:before {
	content: "\ED1D";
}
.iconsmind-Taxi-2:before {
	content: "\ED1E";
}
.iconsmind-Taxi-Sign:before {
	content: "\ED1F";
}
.iconsmind-Taxi:before {
	content: "\ED20";
}
.iconsmind-Teacher:before {
	content: "\ED21";
}
.iconsmind-Teapot:before {
	content: "\ED22";
}
.iconsmind-Technorati:before {
	content: "\ED23";
}
.iconsmind-Teddy-Bear:before {
	content: "\ED24";
}
.iconsmind-Tee-Mug:before {
	content: "\ED25";
}
.iconsmind-Telephone-2:before {
	content: "\ED26";
}
.iconsmind-Telephone:before {
	content: "\ED27";
}
.iconsmind-Telescope:before {
	content: "\ED28";
}
.iconsmind-Temperature-2:before {
	content: "\ED29";
}
.iconsmind-Temperature-3:before {
	content: "\ED2A";
}
.iconsmind-Temperature:before {
	content: "\ED2B";
}
.iconsmind-Temple:before {
	content: "\ED2C";
}
.iconsmind-Tennis-Ball:before {
	content: "\ED2D";
}
.iconsmind-Tennis:before {
	content: "\ED2E";
}
.iconsmind-Tent:before {
	content: "\ED2F";
}
.iconsmind-Test-Tube:before {
	content: "\ED30";
}
.iconsmind-Test-Tube2:before {
	content: "\ED31";
}
.iconsmind-Testimonal:before {
	content: "\ED32";
}
.iconsmind-Text-Box:before {
	content: "\ED33";
}
.iconsmind-Text-Effect:before {
	content: "\ED34";
}
.iconsmind-Text-HighlightColor:before {
	content: "\ED35";
}
.iconsmind-Text-Paragraph:before {
	content: "\ED36";
}
.iconsmind-Thailand:before {
	content: "\ED37";
}
.iconsmind-The-WhiteHouse:before {
	content: "\ED38";
}
.iconsmind-This-SideUp:before {
	content: "\ED39";
}
.iconsmind-Thread:before {
	content: "\ED3A";
}
.iconsmind-Three-ArrowFork:before {
	content: "\ED3B";
}
.iconsmind-Three-Fingers:before {
	content: "\ED3C";
}
.iconsmind-Three-FingersDrag:before {
	content: "\ED3D";
}
.iconsmind-Three-FingersDrag2:before {
	content: "\ED3E";
}
.iconsmind-Three-FingersTouch:before {
	content: "\ED3F";
}
.iconsmind-Thumb:before {
	content: "\ED40";
}
.iconsmind-Thumbs-DownSmiley:before {
	content: "\ED41";
}
.iconsmind-Thumbs-UpSmiley:before {
	content: "\ED42";
}
.iconsmind-Thunder:before {
	content: "\ED43";
}
.iconsmind-Thunderstorm:before {
	content: "\ED44";
}
.iconsmind-Ticket:before {
	content: "\ED45";
}
.iconsmind-Tie-2:before {
	content: "\ED46";
}
.iconsmind-Tie-3:before {
	content: "\ED47";
}
.iconsmind-Tie-4:before {
	content: "\ED48";
}
.iconsmind-Tie:before {
	content: "\ED49";
}
.iconsmind-Tiger:before {
	content: "\ED4A";
}
.iconsmind-Time-Backup:before {
	content: "\ED4B";
}
.iconsmind-Time-Bomb:before {
	content: "\ED4C";
}
.iconsmind-Time-Clock:before {
	content: "\ED4D";
}
.iconsmind-Time-Fire:before {
	content: "\ED4E";
}
.iconsmind-Time-Machine:before {
	content: "\ED4F";
}
.iconsmind-Time-Window:before {
	content: "\ED50";
}
.iconsmind-Timer-2:before {
	content: "\ED51";
}
.iconsmind-Timer:before {
	content: "\ED52";
}
.iconsmind-To-Bottom:before {
	content: "\ED53";
}
.iconsmind-To-Bottom2:before {
	content: "\ED54";
}
.iconsmind-To-Left:before {
	content: "\ED55";
}
.iconsmind-To-Right:before {
	content: "\ED56";
}
.iconsmind-To-Top:before {
	content: "\ED57";
}
.iconsmind-To-Top2:before {
	content: "\ED58";
}
.iconsmind-Token-:before {
	content: "\ED59";
}
.iconsmind-Tomato:before {
	content: "\ED5A";
}
.iconsmind-Tongue:before {
	content: "\ED5B";
}
.iconsmind-Tooth-2:before {
	content: "\ED5C";
}
.iconsmind-Tooth:before {
	content: "\ED5D";
}
.iconsmind-Top-ToBottom:before {
	content: "\ED5E";
}
.iconsmind-Touch-Window:before {
	content: "\ED5F";
}
.iconsmind-Tourch:before {
	content: "\ED60";
}
.iconsmind-Tower-2:before {
	content: "\ED61";
}
.iconsmind-Tower-Bridge:before {
	content: "\ED62";
}
.iconsmind-Tower:before {
	content: "\ED63";
}
.iconsmind-Trace:before {
	content: "\ED64";
}
.iconsmind-Tractor:before {
	content: "\ED65";
}
.iconsmind-traffic-Light:before {
	content: "\ED66";
}
.iconsmind-Traffic-Light2:before {
	content: "\ED67";
}
.iconsmind-Train-2:before {
	content: "\ED68";
}
.iconsmind-Train:before {
	content: "\ED69";
}
.iconsmind-Tram:before {
	content: "\ED6A";
}
.iconsmind-Transform-2:before {
	content: "\ED6B";
}
.iconsmind-Transform-3:before {
	content: "\ED6C";
}
.iconsmind-Transform-4:before {
	content: "\ED6D";
}
.iconsmind-Transform:before {
	content: "\ED6E";
}
.iconsmind-Trash-withMen:before {
	content: "\ED6F";
}
.iconsmind-Tree-2:before {
	content: "\ED70";
}
.iconsmind-Tree-3:before {
	content: "\ED71";
}
.iconsmind-Tree-4:before {
	content: "\ED72";
}
.iconsmind-Tree-5:before {
	content: "\ED73";
}
.iconsmind-Tree:before {
	content: "\ED74";
}
.iconsmind-Trekking:before {
	content: "\ED75";
}
.iconsmind-Triangle-ArrowDown:before {
	content: "\ED76";
}
.iconsmind-Triangle-ArrowLeft:before {
	content: "\ED77";
}
.iconsmind-Triangle-ArrowRight:before {
	content: "\ED78";
}
.iconsmind-Triangle-ArrowUp:before {
	content: "\ED79";
}
.iconsmind-Tripod-2:before {
	content: "\ED7A";
}
.iconsmind-Tripod-andVideo:before {
	content: "\ED7B";
}
.iconsmind-Tripod-withCamera:before {
	content: "\ED7C";
}
.iconsmind-Tripod-withGopro:before {
	content: "\ED7D";
}
.iconsmind-Trophy-2:before {
	content: "\ED7E";
}
.iconsmind-Trophy:before {
	content: "\ED7F";
}
.iconsmind-Truck:before {
	content: "\ED80";
}
.iconsmind-Trumpet:before {
	content: "\ED81";
}
.iconsmind-Tumblr:before {
	content: "\ED82";
}
.iconsmind-Turkey:before {
	content: "\ED83";
}
.iconsmind-Turn-Down:before {
	content: "\ED84";
}
.iconsmind-Turn-Down2:before {
	content: "\ED85";
}
.iconsmind-Turn-DownFromLeft:before {
	content: "\ED86";
}
.iconsmind-Turn-DownFromRight:before {
	content: "\ED87";
}
.iconsmind-Turn-Left:before {
	content: "\ED88";
}
.iconsmind-Turn-Left3:before {
	content: "\ED89";
}
.iconsmind-Turn-Right:before {
	content: "\ED8A";
}
.iconsmind-Turn-Right3:before {
	content: "\ED8B";
}
.iconsmind-Turn-Up:before {
	content: "\ED8C";
}
.iconsmind-Turn-Up2:before {
	content: "\ED8D";
}
.iconsmind-Turtle:before {
	content: "\ED8E";
}
.iconsmind-Tuxedo:before {
	content: "\ED8F";
}
.iconsmind-TV:before {
	content: "\ED90";
}
.iconsmind-Twister:before {
	content: "\ED91";
}
.iconsmind-Twitter-2:before {
	content: "\ED92";
}
.iconsmind-Twitter:before {
	content: "\ED93";
}
.iconsmind-Two-Fingers:before {
	content: "\ED94";
}
.iconsmind-Two-FingersDrag:before {
	content: "\ED95";
}
.iconsmind-Two-FingersDrag2:before {
	content: "\ED96";
}
.iconsmind-Two-FingersScroll:before {
	content: "\ED97";
}
.iconsmind-Two-FingersTouch:before {
	content: "\ED98";
}
.iconsmind-Two-Windows:before {
	content: "\ED99";
}
.iconsmind-Type-Pass:before {
	content: "\ED9A";
}
.iconsmind-Ukraine:before {
	content: "\ED9B";
}
.iconsmind-Umbrela:before {
	content: "\ED9C";
}
.iconsmind-Umbrella-2:before {
	content: "\ED9D";
}
.iconsmind-Umbrella-3:before {
	content: "\ED9E";
}
.iconsmind-Under-LineText:before {
	content: "\ED9F";
}
.iconsmind-Undo:before {
	content: "\EDA0";
}
.iconsmind-United-Kingdom:before {
	content: "\EDA1";
}
.iconsmind-United-States:before {
	content: "\EDA2";
}
.iconsmind-University-2:before {
	content: "\EDA3";
}
.iconsmind-University:before {
	content: "\EDA4";
}
.iconsmind-Unlike-2:before {
	content: "\EDA5";
}
.iconsmind-Unlike:before {
	content: "\EDA6";
}
.iconsmind-Unlock-2:before {
	content: "\EDA7";
}
.iconsmind-Unlock-3:before {
	content: "\EDA8";
}
.iconsmind-Unlock:before {
	content: "\EDA9";
}
.iconsmind-Up--Down:before {
	content: "\EDAA";
}
.iconsmind-Up--Down3:before {
	content: "\EDAB";
}
.iconsmind-Up-2:before {
	content: "\EDAC";
}
.iconsmind-Up-3:before {
	content: "\EDAD";
}
.iconsmind-Up-4:before {
	content: "\EDAE";
}
.iconsmind-Up:before {
	content: "\EDAF";
}
.iconsmind-Upgrade:before {
	content: "\EDB0";
}
.iconsmind-Upload-2:before {
	content: "\EDB1";
}
.iconsmind-Upload-toCloud:before {
	content: "\EDB2";
}
.iconsmind-Upload-Window:before {
	content: "\EDB3";
}
.iconsmind-Upload:before {
	content: "\EDB4";
}
.iconsmind-Uppercase-Text:before {
	content: "\EDB5";
}
.iconsmind-Upward:before {
	content: "\EDB6";
}
.iconsmind-URL-Window:before {
	content: "\EDB7";
}
.iconsmind-Usb-2:before {
	content: "\EDB8";
}
.iconsmind-Usb-Cable:before {
	content: "\EDB9";
}
.iconsmind-Usb:before {
	content: "\EDBA";
}
.iconsmind-User:before {
	content: "\EDBB";
}
.iconsmind-Ustream:before {
	content: "\EDBC";
}
.iconsmind-Vase:before {
	content: "\EDBD";
}
.iconsmind-Vector-2:before {
	content: "\EDBE";
}
.iconsmind-Vector-3:before {
	content: "\EDBF";
}
.iconsmind-Vector-4:before {
	content: "\EDC0";
}
.iconsmind-Vector-5:before {
	content: "\EDC1";
}
.iconsmind-Vector:before {
	content: "\EDC2";
}
.iconsmind-Venn-Diagram:before {
	content: "\EDC3";
}
.iconsmind-Vest-2:before {
	content: "\EDC4";
}
.iconsmind-Vest:before {
	content: "\EDC5";
}
.iconsmind-Viddler:before {
	content: "\EDC6";
}
.iconsmind-Video-2:before {
	content: "\EDC7";
}
.iconsmind-Video-3:before {
	content: "\EDC8";
}
.iconsmind-Video-4:before {
	content: "\EDC9";
}
.iconsmind-Video-5:before {
	content: "\EDCA";
}
.iconsmind-Video-6:before {
	content: "\EDCB";
}
.iconsmind-Video-GameController:before {
	content: "\EDCC";
}
.iconsmind-Video-Len:before {
	content: "\EDCD";
}
.iconsmind-Video-Len2:before {
	content: "\EDCE";
}
.iconsmind-Video-Photographer:before {
	content: "\EDCF";
}
.iconsmind-Video-Tripod:before {
	content: "\EDD0";
}
.iconsmind-Video:before {
	content: "\EDD1";
}
.iconsmind-Vietnam:before {
	content: "\EDD2";
}
.iconsmind-View-Height:before {
	content: "\EDD3";
}
.iconsmind-View-Width:before {
	content: "\EDD4";
}
.iconsmind-Vimeo:before {
	content: "\EDD5";
}
.iconsmind-Virgo-2:before {
	content: "\EDD6";
}
.iconsmind-Virgo:before {
	content: "\EDD7";
}
.iconsmind-Virus-2:before {
	content: "\EDD8";
}
.iconsmind-Virus-3:before {
	content: "\EDD9";
}
.iconsmind-Virus:before {
	content: "\EDDA";
}
.iconsmind-Visa:before {
	content: "\EDDB";
}
.iconsmind-Voice:before {
	content: "\EDDC";
}
.iconsmind-Voicemail:before {
	content: "\EDDD";
}
.iconsmind-Volleyball:before {
	content: "\EDDE";
}
.iconsmind-Volume-Down:before {
	content: "\EDDF";
}
.iconsmind-Volume-Up:before {
	content: "\EDE0";
}
.iconsmind-VPN:before {
	content: "\EDE1";
}
.iconsmind-Wacom-Tablet:before {
	content: "\EDE2";
}
.iconsmind-Waiter:before {
	content: "\EDE3";
}
.iconsmind-Walkie-Talkie:before {
	content: "\EDE4";
}
.iconsmind-Wallet-2:before {
	content: "\EDE5";
}
.iconsmind-Wallet-3:before {
	content: "\EDE6";
}
.iconsmind-Wallet:before {
	content: "\EDE7";
}
.iconsmind-Warehouse:before {
	content: "\EDE8";
}
.iconsmind-Warning-Window:before {
	content: "\EDE9";
}
.iconsmind-Watch-2:before {
	content: "\EDEA";
}
.iconsmind-Watch-3:before {
	content: "\EDEB";
}
.iconsmind-Watch:before {
	content: "\EDEC";
}
.iconsmind-Wave-2:before {
	content: "\EDED";
}
.iconsmind-Wave:before {
	content: "\EDEE";
}
.iconsmind-Webcam:before {
	content: "\EDEF";
}
.iconsmind-weight-Lift:before {
	content: "\EDF0";
}
.iconsmind-Wheelbarrow:before {
	content: "\EDF1";
}
.iconsmind-Wheelchair:before {
	content: "\EDF2";
}
.iconsmind-Width-Window:before {
	content: "\EDF3";
}
.iconsmind-Wifi-2:before {
	content: "\EDF4";
}
.iconsmind-Wifi-Keyboard:before {
	content: "\EDF5";
}
.iconsmind-Wifi:before {
	content: "\EDF6";
}
.iconsmind-Wind-Turbine:before {
	content: "\EDF7";
}
.iconsmind-Windmill:before {
	content: "\EDF8";
}
.iconsmind-Window-2:before {
	content: "\EDF9";
}
.iconsmind-Window:before {
	content: "\EDFA";
}
.iconsmind-Windows-2:before {
	content: "\EDFB";
}
.iconsmind-Windows-Microsoft:before {
	content: "\EDFC";
}
.iconsmind-Windows:before {
	content: "\EDFD";
}
.iconsmind-Windsock:before {
	content: "\EDFE";
}
.iconsmind-Windy:before {
	content: "\EDFF";
}
.iconsmind-Wine-Bottle:before {
	content: "\EE00";
}
.iconsmind-Wine-Glass:before {
	content: "\EE01";
}
.iconsmind-Wink:before {
	content: "\EE02";
}
.iconsmind-Winter-2:before {
	content: "\EE03";
}
.iconsmind-Winter:before {
	content: "\EE04";
}
.iconsmind-Wireless:before {
	content: "\EE05";
}
.iconsmind-Witch-Hat:before {
	content: "\EE06";
}
.iconsmind-Witch:before {
	content: "\EE07";
}
.iconsmind-Wizard:before {
	content: "\EE08";
}
.iconsmind-Wolf:before {
	content: "\EE09";
}
.iconsmind-Woman-Sign:before {
	content: "\EE0A";
}
.iconsmind-WomanMan:before {
	content: "\EE0B";
}
.iconsmind-Womans-Underwear:before {
	content: "\EE0C";
}
.iconsmind-Womans-Underwear2:before {
	content: "\EE0D";
}
.iconsmind-Women:before {
	content: "\EE0E";
}
.iconsmind-Wonder-Woman:before {
	content: "\EE0F";
}
.iconsmind-Wordpress:before {
	content: "\EE10";
}
.iconsmind-Worker-Clothes:before {
	content: "\EE11";
}
.iconsmind-Worker:before {
	content: "\EE12";
}
.iconsmind-Wrap-Text:before {
	content: "\EE13";
}
.iconsmind-Wreath:before {
	content: "\EE14";
}
.iconsmind-Wrench:before {
	content: "\EE15";
}
.iconsmind-X-Box:before {
	content: "\EE16";
}
.iconsmind-X-ray:before {
	content: "\EE17";
}
.iconsmind-Xanga:before {
	content: "\EE18";
}
.iconsmind-Xing:before {
	content: "\EE19";
}
.iconsmind-Yacht:before {
	content: "\EE1A";
}
.iconsmind-Yahoo-Buzz:before {
	content: "\EE1B";
}
.iconsmind-Yahoo:before {
	content: "\EE1C";
}
.iconsmind-Yelp:before {
	content: "\EE1D";
}
.iconsmind-Yes:before {
	content: "\EE1E";
}
.iconsmind-Ying-Yang:before {
	content: "\EE1F";
}
.iconsmind-Youtube:before {
	content: "\EE20";
}
.iconsmind-Z-A:before {
	content: "\EE21";
}
.iconsmind-Zebra:before {
	content: "\EE22";
}
.iconsmind-Zombie:before {
	content: "\EE23";
}
.iconsmind-Zoom-Gesture:before {
	content: "\EE24";
}
.iconsmind-Zootool:before {
	content: "\EE25";
}

/* 01.Base */
html {
  width: 100% !important;
  height: 100% !important;
  background: #fbfbff !important; }

#root {
  height: 100% !important; }

body {
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background: #fbfbff !important; }
  body.background {
    height: 100% !important; }
    body.background main {
      margin: 0 !important;
      height: 100% !important; }
      body.background main .container {
        height: 100% !important; }

.fixed-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

hr {
  border-top: 1px solid #ced4da; }

.disable-text-selection {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

::-moz-selection {
  background: #b5bcbd; }

::selection {
  background: #b5bcbd; }

::-moz-selection {
  background: #b5bcbd; }

.scrollbar-container {
  margin-right: -15px;
  padding-right: 15px;
  position: relative; }

.ps__rail-y {
  width: 5px; }

.ps__thumb-y {
  left: 0; }

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 5px;
  left: 0; }

.ps__thumb-y {
  background-color: #ced4da; }

.logo-single {
  width: 110px;
  height: 35px;
  background: url("/images/brand/logow.svg") no-repeat;
  background-position: center center;
  display: inline-block;
  margin-bottom: 60px; }

.list-item-heading {
  font-size: 1rem; }

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.no-transition {
  -webkit-transition: none !important;
  transition: none !important; }

a {
  color: #495057;
  -webkit-transition: color 200ms;
  transition: color 200ms; }
  a:hover, a:active {
    text-decoration: none;
    text-decoration: initial;
    color: #3949ab; }

.view-icon {
  font-size: 20px;
  color: #8898aa; }
  .view-icon:hover {
    color: #3949ab; }
  .view-icon.s {
    font-size: 18px; }

#displayOptions a {
  cursor: pointer; }

#displayOptions a.active i {
  color: #3949ab; }

#displayOptions button {
  border-color: #8898aa;
  color: #8898aa; }
  #displayOptions button:hover {
    background-color: #3949ab;
    border-color: #3949ab;
    color: #ffffff; }

#displayOptions .btn-outline-dark:not(:disabled):not(.disabled):active,
#displayOptions .btn-outline-dark:not(:disabled):not(.disabled).active,
#displayOptions .show > .btn-outline-dark.dropdown-toggle {
  background-color: #3949ab;
  border-color: #3949ab;
  color: #ffffff; }

#displayOptions .view-icon svg {
  width: 19px; }

#displayOptions .view-icon .view-icon-svg {
  fill: #8898aa; }

#displayOptions .view-icon:hover .view-icon-svg, #displayOptions .view-icon.active .view-icon-svg {
  fill: #3949ab; }

.main-heading {
  border-bottom: 1px solid #ced4da; }

.separator {
  border-bottom: 1px solid #ced4da; }

.alert-dismissible .close {
  padding: 0.5rem 1.25rem; }

.rounded {
  border-radius: 50px !important; }

.img-thumbnail {
  border-radius: 0.375rem;
  padding: 0;
  border: medium none currentColor;
  border: initial; }

.list-thumbnail {
  border-radius: 0.375rem;
  padding: 0;
  border: medium none currentColor;
  border: initial;
  height: auto;
  max-width: unset;
  height: 85px;
  object-fit: cover;
  width: unset !important; }
  @media (max-width: 991px) {
    .list-thumbnail {
      height: 80px; } }
  @media (max-width: 575px) {
    .list-thumbnail {
      height: 70px; } }
  @media (max-width: 991px) {
    .list-thumbnail.responsive {
      width: unset;
      height: 136px; } }
  @media (max-width: 575px) {
    .list-thumbnail.responsive {
      width: 110px !important;
      height: 100%; } }
  @media (max-width: 419px) {
    .list-thumbnail.responsive {
      width: 90px !important;
      height: 100%; } }
  .list-thumbnail.small {
    height: 60px;
    font-size: 1rem; }
    @media (max-width: 991px) {
      .list-thumbnail.small {
        height: 55px; } }
    @media (max-width: 575px) {
      .list-thumbnail.small {
        height: 50px; } }
  .list-thumbnail.xsmall {
    height: 40px;
    font-size: 1rem; }
    @media (max-width: 991px) {
      .list-thumbnail.xsmall {
        height: 40px; } }
    @media (max-width: 575px) {
      .list-thumbnail.xsmall {
        height: 40px; } }

.list-thumbnail-letters {
  width: 85px;
  height: 85px;
  background: #3949ab;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.25rem;
  color: #ffffff; }
  @media (max-width: 991px) {
    .list-thumbnail-letters {
      width: 80px;
      height: 80px; } }
  @media (max-width: 575px) {
    .list-thumbnail-letters {
      width: 70px;
      height: 70px; } }
  .list-thumbnail-letters.small {
    width: 60px;
    height: 60px;
    font-size: 1rem; }
    @media (max-width: 991px) {
      .list-thumbnail-letters.small {
        width: 55px;
        height: 55px; } }
    @media (max-width: 575px) {
      .list-thumbnail-letters.small {
        width: 50px;
        height: 50px; } }

.white {
  color: #ffffff; }

/* 02.Buttons */
.btn-arrow {
  display: inline-block;
  text-align: center;
  border-radius: 30px !important;
  width: 42px;
  height: 42px;
  line-height: 24px; }

.btn-arrow i {
  font-size: 15px;
  display: inline-block;
  text-align: center; }

.btn-sm.btn-arrow {
  width: 34px;
  height: 34px;
  line-height: 17px; }

.btn-sm.btn-arrow i {
  font-size: 13px;
  line-height: 10px; }

.btn-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  -webkit-transition: background-color box-shadow 0.1s linear;
  transition: background-color box-shadow 0.1s linear; }
  .btn-shadow:hover, .btn-shadow:focus {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15), 0 4px 6px 2px rgba(0, 0, 0, 0.15) !important; }

.btn-empty {
  background: transparent !important; }

.check-button {
  cursor: default !important; }

.check-button.btn-primary {
  background-color: #3949ab !important;
  border-color: #3949ab !important;
  opacity: 1; }

.check-button .custom-control {
  min-height: 1.1rem;
  margin-top: -7px; }

.dropdown-menu {
  font-size: 0.875rem;
  border-radius: 0.375rem;
  background: #ffffff;
  border-color: rgba(73, 80, 87, 0.15); }

.dropdown-item {
  padding: 0.75rem 1.5rem;
  color: #495057; }

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: #fbfbff;
  color: #495057; }

.dropdown-item.active,
.dropdown-item:active {
  color: #ffffff;
  text-decoration: none;
  background-color: #3949ab; }

.dropdown-divider {
  border-color: #ced4da; }

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #2a357d;
  border-color: #2a357d;
  color: #ffffff; }

.btn-multiple-state {
  position: relative;
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms; }
  .btn-multiple-state .spinner,
  .btn-multiple-state .icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition: opacity 500ms;
    transition: opacity 500ms;
    color: #ffffff; }
  .btn-multiple-state .icon i {
    vertical-align: text-bottom;
    font-size: 18px; }
  .btn-multiple-state .label {
    -webkit-transition: opacity 500ms;
    transition: opacity 500ms; }
  .btn-multiple-state.show-spinner .label {
    opacity: 0; }
  .btn-multiple-state.show-spinner .spinner {
    opacity: 1;
    visibility: visible; }
  .btn-multiple-state.show-success .label {
    opacity: 0; }
  .btn-multiple-state.show-success .icon.success {
    opacity: 1;
    visibility: visible; }
  .btn-multiple-state.show-fail .label {
    opacity: 0; }
  .btn-multiple-state.show-fail .icon.fail {
    opacity: 1;
    visibility: visible; }
  .btn-multiple-state.btn-primary:disabled {
    opacity: 1;
    background: #2c3985;
    border-color: #2c3985; }
  .btn-multiple-state.btn-secondary:disabled {
    opacity: 1;
    border-color: #20638f; }

.icon-button {
  padding: 0;
  font-size: 14px;
  width: 34px;
  height: 34px;
  line-height: 34px; }
  .icon-button.large {
    width: 44px;
    height: 44px;
    font-size: 18px; }
  .icon-button.small-icon {
    font-size: 12px;
    line-height: 32px; }

.top-right-button {
  width: calc(100% - 80px); }
  @media (max-width: 575px) {
    .top-right-button {
      padding-left: 0;
      padding-right: 0; } }

.top-right-button-single {
  width: unset; }
  @media (max-width: 991px) {
    .top-right-button-single {
      width: calc(100%); } }

/* 03.Widths-Spacing */
.w-10 {
  width: 10% !important; }

.w-90 {
  width: 90% !important; }

.w-12 {
  width: 12% !important; }

.w-88 {
  width: 88% !important; }

.w-15 {
  width: 15% !important; }

.w-85 {
  width: 85% !important; }

.w-20 {
  width: 20% !important; }

.w-80 {
  width: 80% !important; }

.w-30 {
  width: 30% !important; }

.w-70 {
  width: 70% !important; }

.w-40 {
  width: 40% !important; }

.w-60 {
  width: 60% !important; }

@media (max-width: 767px) {
  .w-xs-100 {
    width: 100% !important; } }

@media (max-width: 991px) {
  .w-sm-100 {
    width: 100% !important; } }

.r-0 {
  right: 0; }

.l-0 {
  left: 0; }

.min-width-zero {
  min-width: 0; }

@media (max-width: 575px) {
  .float-none-xs {
    float: none !important;
    float: initial !important; } }

/* 04.Borders */
.border {
  border: 1px solid #ecf0f1 !important; }

.border-right {
  border-right: 1px solid #ecf0f1 !important; }

.border-left {
  border-left: 1px solid #ecf0f1 !important; }

.border-top {
  border-top: 1px solid #ecf0f1 !important; }

.border-bottom {
  border-bottom: 1px solid #ecf0f1 !important; }

.border-primary {
  border-color: #3949ab !important; }

.border-secondary {
  border-color: #495057 !important; }

/* 05.Badges */
.badge {
  padding: 0.55em 0.75em 0.6em 0.75em;
  font-size: 75%; }
  .badge.badge-pill {
    padding-right: 1.25em;
    padding-left: 1.25em; }
  .badge.badge-top-left {
    top: 3px;
    left: -9px; }
  .badge.badge-top-left-2 {
    top: 40px;
    left: -7px; }
  .badge.badge-top-right {
    top: 8px;
    right: -7px; }
  .badge.badge-top-right-2 {
    top: 40px;
    right: -7px; }

/* 06.Breadcrumb */
@media (max-width: 991px) {
  .breadcrumb-container .breadcrumb {
    padding: 0; } }

.breadcrumb {
  background-color: transparent;
  margin-bottom: 0.5rem; }

.breadcrumb-item + .breadcrumb-item::before {
  content: "|"; }

/* 07.Tabs */
.nav-tabs.separator-tabs {
  border-bottom: 1px solid #ced4da; }

.nav-tabs .nav-link {
  border: medium none currentColor;
  border: initial;
  padding-top: 1rem; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: #ffffff; }

.nav-tabs .nav-link.active::before,
.nav-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: #3949ab;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 5px;
  left: 0;
  top: 0; }

.nav-tabs.separator-tabs .nav-link.active::before,
.nav-tabs.separator-tabs .nav-item.show .nav-link::before {
  content: " ";
  background: #3949ab;
  border-radius: 10px;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  top: unset; }

.nav-tabs.separator-tabs .nav-link {
  border: medium none currentColor;
  border: initial;
  padding-top: 1rem;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial;
  padding-left: 0;
  padding-top: 0.5rem;
  padding-right: 0;
  margin-right: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #8898aa; }

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: medium none currentColor;
  border: initial;
  position: relative;
  color: #3949ab; }

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border: medium none currentColor;
  border: initial;
  color: #3949ab; }

.react-siema-container {
  overflow: hidden;
  padding-bottom: 15px;
  padding-top: 5px; }

.slider-nav .left-arrow,
.slider-nav .right-arrow {
  font-size: 20px;
  color: #3949ab;
  display: inline-block;
  vertical-align: middle;
  margin: 0 15px;
  padding-top: 14px; }

.slider-nav .slider-dot-container {
  display: inline-block; }

.slider-nav .btn:hover, .slider-nav .btn:focus, .slider-nav .btn:active {
  text-decoration: none;
  text-decoration: initial; }

.slider-dot {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background: #ced4da;
  outline: medium none invert !important;
  outline: initial !important;
  border: medium none currentColor;
  border: initial;
  margin: 0 5px;
  padding: 0;
  cursor: pointer; }
  .slider-dot.active {
    background: #3949ab; }

.react-siema-container .card .card-body {
  flex-direction: column;
  display: flex;
  justify-content: space-between; }

.react-siema-container .card .w-50 {
  display: flex; }

/* 09.Navbar*/
.navbar {
  background: #ffffff;
  height: 58px;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .navbar #notificationDropdown {
    width: 260px;
    padding: 1.5rem;
    height: 280px;
    right: 15px; }
  .navbar #iconMenuDropdown {
    width: 320px;
    padding: 1.5rem;
    padding-bottom: 0; }
  .navbar .icon-menu-item {
    width: 90px;
    display: inline-block;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #495057;
    font-weight: 600;
    vertical-align: top; }
    .navbar .icon-menu-item i {
      font-size: 32px;
      line-height: 42px; }
    .navbar .icon-menu-item span {
      text-align: center;
      padding: 0 10px;
      line-height: 14px; }
    .navbar .icon-menu-item:hover, .navbar .icon-menu-item:focus {
      color: #3949ab; }
  .navbar .menu-button-mobile {
    color: #8898aa;
    text-align: center; }
    .navbar .menu-button-mobile svg {
      height: 12px;
      fill: #495057; }
    @media (max-width: 767px) {
      .navbar .menu-button-mobile {
        width: 60px !important; } }
  .navbar .menu-button {
    color: #8898aa;
    width: 100px;
    text-align: center; }
    .navbar .menu-button svg {
      height: 12px; }
    .navbar .menu-button .main {
      fill: #495057;
      -webkit-transition: fill 300ms;
      transition: fill 300ms; }
    .navbar .menu-button .sub {
      fill: #495057;
      -webkit-transition: fill 300ms;
      transition: fill 300ms; }
    .navbar .menu-button:hover {
      color: #3949ab; }
    @media (max-width: 767px) {
      .navbar .menu-button {
        width: 60px; } }
  .navbar .navbar-logo {
    width: 100px;
    height: 32px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto; }
    .navbar .navbar-logo .logo {
      width: 100%;
      height: 100%;
      background: url("/images/brand/logow.svg") no-repeat;
      background-position: center center; }
    .navbar .navbar-logo .logo-mobile {
      width: 100%;
      height: 100%;
      background: url("/images/brand/logow.svg") no-repeat;
      background-position: center center; }
    @media (max-width: 767px) {
      .navbar .navbar-logo {
        width: 80px; } }
    @media (max-width: 419px) {
      .navbar .navbar-logo {
        width: 20px; } }
  .navbar .language-button {
    background: #fbfbff;
    border: medium none currentColor;
    border: initial;
    font-size: 0.875rem;
    color: #8898aa;
    padding: .6rem 1rem; }
    @media (max-width: 575px) {
      .navbar .language-button {
        padding-left: 0.75rem;
        padding-right: 0.75rem; } }
  .navbar .search {
    position: relative;
    width: 180px;
    border-radius: 20px;
    background: #fbfbff; }
    .navbar .search input {
      border: medium none currentColor;
      border: initial;
      background: transparent;
      outline: medium none invert !important;
      outline: initial !important;
      padding: 0.5rem 1rem;
      line-height: 2;
      font-size: 0.875rem;
      width: 93%;
      color: #495057; }
    .navbar .search .search-icon {
      font-size: 17px;
      border-radius: 10px;
      color: #8898aa;
      position: absolute;
      width: 40px;
      height: 40px;
      bottom: -8px;
      right: 3px;
      text-align: center;
      cursor: pointer; }
      .navbar .search .search-icon:hover {
        color: #3949ab; }
    @media (max-width: 767px) {
      .navbar .search {
        width: 30px;
        height: 30px;
        background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
        background: initial;
        margin-left: 0.6rem;
        color: rgba(73, 80, 87, 0.7); }
        .navbar .search input {
          display: none; }
        .navbar .search .search-icon {
          font-size: 17px;
          width: 30px;
          height: 30px;
          bottom: -3px;
          right: 0;
          color: inherit; }
        .navbar .search.mobile-view {
          display: block;
          width: 100%;
          position: fixed;
          z-index: 2;
          background: #ffffff;
          left: 0;
          top: 0;
          height: 58px;
          margin-left: 15px; }
          .navbar .search.mobile-view input {
            display: block;
            width: 100%;
            height: 70px;
            padding-left: 0; }
          .navbar .search.mobile-view span {
            top: 50%;
            -webkit-transform: translateY(-50%);
                    transform: translateY(-50%);
            right: 25px; } }
  .navbar .header-icons {
    margin-right: 1rem; }
    @media (max-width: 575px) {
      .navbar .header-icons {
        margin-right: 0; } }
  .navbar .header-icon {
    font-size: 16px;
    color: #8898aa;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    vertical-align: baseline;
    vertical-align: initial; }
    @media (max-width: 575px) {
      .navbar .header-icon {
        padding-left: 0.3rem;
        padding-right: 0.3rem; } }
    .navbar .header-icon:hover {
      color: #3949ab; }
    .navbar .header-icon#fullScreenButton i:last-of-type {
      display: none; }
    .navbar .header-icon#notificationButton:after {
      font-size: 10px;
      content: "3";
      color: #3949ab;
      border: 1px solid #3949ab;
      border-radius: 10px;
      position: absolute;
      width: 15px;
      height: 15px;
      text-align: center;
      font-weight: 700;
      top: 2px;
      right: 3px;
      line-height: 14px; }
      @media (max-width: 575px) {
        .navbar .header-icon#notificationButton:after {
          right: -1px; } }
  .navbar .user {
    color: #949da5;
    position: relative; }
    .navbar .user img {
      margin-left: 10px;
      border-radius: 30px;
      width: 36px; }
    @media (max-width: 767px) {
      .navbar .user {
        margin-left: 0;
        margin-left: initial; }
        .navbar .user .name {
          display: none; }
        .navbar .user:after {
          font-size: 11px;
          width: 14px;
          height: 14px;
          bottom: -3px;
          right: -3px; } }
  @media (max-width: 767px) {
    .navbar {
      height: 58px; } }

#app-container.sub-hidden .menu-button .sub,
#app-container.menu-sub-hidden .menu-button .sub {
  fill: #8898aa; }

#app-container.main-hidden .menu-button .main,
#app-container.main-hidden .menu-button .sub,
#app-container.menu-hidden .menu-button .main,
#app-container.menu-hidden .menu-button .sub {
  fill: #8898aa; }

/* 10.Menu*/
#app-container .sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  padding-top: 58px;
  z-index: 4;
  height: calc(100% - 58px); }
  #app-container .sidebar .scrollbar-container {
    margin-right: 0;
    padding-right: 0; }
  #app-container .sidebar .main-menu {
    width: 100px;
    height: calc(100% - 58px);
    background: #ffffff;
    z-index: 3;
    position: fixed;
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    padding-top: 2px;
    left: 0; }
    #app-container .sidebar .main-menu .scroll {
      padding-right: unset;
      margin-right: unset;
      height: 100%; }
      #app-container .sidebar .main-menu .scroll .ps__thumb-y {
        right: 0; }
    #app-container .sidebar .main-menu.main-hidden {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px); }
    #app-container .sidebar .main-menu ul li {
      position: relative; }
      #app-container .sidebar .main-menu ul li span {
        text-align: center;
        padding: 0 10px;
        line-height: 14px; }
      #app-container .sidebar .main-menu ul li a {
        height: 95px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        border-bottom: 1px solid #ecf0f1;
        color: #495057;
        -webkit-transition: color 300ms;
        transition: color 300ms;
        -webkit-transition: background 300ms;
        transition: background 300ms; }
        #app-container .sidebar .main-menu ul li a:hover, #app-container .sidebar .main-menu ul li a:focus {
          color: #3949ab;
          background: #fbfbff; }
      #app-container .sidebar .main-menu ul li i {
        font-size: 28px;
        line-height: 42px; }
      #app-container .sidebar .main-menu ul li.active a {
        color: #3949ab; }
      #app-container .sidebar .main-menu ul li.active:after {
        content: " ";
        background: #3949ab;
        border-radius: 1px;
        position: absolute;
        width: 6px;
        height: 85px;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        left: 0; }
    @media (max-width: 767px) {
      #app-container .sidebar .main-menu {
        width: 100px;
        height: calc(100% - 58px); }
        #app-container .sidebar .main-menu ul li i {
          font-size: 28px;
          line-height: 38px; }
        #app-container .sidebar .main-menu ul li a {
          height: 95px; }
        #app-container .sidebar .main-menu ul li.active:after {
          width: 3px;
          height: 60px; } }
  #app-container .sidebar .sub-menu {
    width: 200px;
    background: #ffffff;
    z-index: 2;
    position: fixed;
    left: 100px;
    border-left: 1px solid #ecf0f1;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    -webkit-transition: -webkit-transform 300ms;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms;
    height: calc(100% - 58px); }
    #app-container .sidebar .sub-menu .scroll {
      height: 100%;
      margin-top: 2px;
      margin-right: unset;
      padding-right: unset; }
      #app-container .sidebar .sub-menu .scroll .ps__thumb-y {
        right: 0; }
    #app-container .sidebar .sub-menu .ps {
      padding-top: 25px;
      padding-bottom: 25px; }
    #app-container .sidebar .sub-menu ul {
      display: none; }
      #app-container .sidebar .sub-menu ul li {
        margin-bottom: 10px;
        margin-left: 15px; }
        #app-container .sidebar .sub-menu ul li a {
          font-size: 13px;
          display: block;
          padding: 8px 0;
          font-weight: 600; }
        #app-container .sidebar .sub-menu ul li i {
          font-size: 1.3em;
          margin-right: 10px;
          color: #8898aa;
          vertical-align: middle; }
        #app-container .sidebar .sub-menu ul li span {
          vertical-align: middle;
          padding-top: 3px;
          display: inline-block; }
        #app-container .sidebar .sub-menu ul li.active i,
        #app-container .sidebar .sub-menu ul li.active a {
          color: #3949ab; }
        @media (max-width: 767px) {
          #app-container .sidebar .sub-menu ul li {
            margin-left: 15px; } }
    @media (max-width: 767px) {
      #app-container .sidebar .sub-menu {
        left: 100px;
        width: 190px;
        height: calc(100% - 58px); } }
  @media (max-width: 767px) {
    #app-container .sidebar {
      padding-top: 58px;
      box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); } }

#app-container.sub-hidden .sub-menu,
#app-container.menu-sub-hidden .sub-menu,
#app-container.menu-hidden .sub-menu {
  -webkit-transform: translateX(-200px);
          transform: translateX(-200px); }
  @media (max-width: 767px) {
    #app-container.sub-hidden .sub-menu,
    #app-container.menu-sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      -webkit-transform: translateX(-190px);
              transform: translateX(-190px); } }

#app-container.main-hidden .main-menu,
#app-container.menu-hidden .main-menu {
  -webkit-transform: translateX(-100px);
          transform: translateX(-100px); }
  @media (max-width: 767px) {
    #app-container.main-hidden .main-menu,
    #app-container.menu-hidden .main-menu {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px); } }

#app-container.main-hidden.sub-hidden .sub-menu,
#app-container.menu-hidden .sub-menu {
  -webkit-transform: translateX(-300px);
          transform: translateX(-300px); }
  @media (max-width: 767px) {
    #app-container.main-hidden.sub-hidden .sub-menu,
    #app-container.menu-hidden .sub-menu {
      -webkit-transform: translateX(-290px);
              transform: translateX(-290px); } }

#app-container.menu-mobile .main-menu {
  -webkit-transform: translateX(-100px);
          transform: translateX(-100px); }

#app-container.menu-mobile .sub-menu {
  -webkit-transform: translateX(-340px);
          transform: translateX(-340px); }

#app-container.main-show-temporary .main-menu {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

#app-container.main-show-temporary .sub-menu {
  -webkit-transform: translateX(-200px);
          transform: translateX(-200px); }

@media (max-width: 767px) {
  #app-container.main-show-temporary .sub-menu {
    -webkit-transform: translateX(-190px);
            transform: translateX(-190px); } }

#app-container.sub-show-temporary .sub-menu, #app-container.menu-mobile.sub-show-temporary .sub-menu {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

/* 11.Apps*/
.app-menu {
  z-index: 1;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  width: 280px;
  float: right;
  background: #ffffff;
  -webkit-transition: -webkit-transform 300ms;
  transition: -webkit-transform 300ms;
  transition: transform 300ms;
  transition: transform 300ms, -webkit-transform 300ms;
  height: calc(100% - 58px);
  position: fixed;
  right: 0;
  top: 58px; }
  @media (max-width: 767px) {
    .app-menu {
      top: 58px;
      height: calc(100% - 58px); } }
  .app-menu .scrollbar-container {
    margin-right: unset;
    padding-right: unset; }
    .app-menu .scrollbar-container .ps__rail-y {
      right: 2px !important; }
  .app-menu.shown {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .app-menu .app-menu-button {
    cursor: pointer;
    position: absolute;
    left: -28px;
    background: #ffffff;
    top: 25px;
    padding: 12px 8px 12px 5px;
    border-radius: 0.2rem;
    color: #8898aa !important;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
    font-size: 15px;
    line-height: 0; }
    @media (max-width: 767px) {
      .app-menu .app-menu-button {
        top: -2px; } }
  .app-menu ul li {
    margin-bottom: 5px; }
    .app-menu ul li a {
      font-size: 13px;
      display: block;
      padding: 3px 0; }
      .app-menu ul li a:hover i {
        color: #3949ab; }
    .app-menu ul li i {
      font-size: 1.2em;
      margin-right: 10px;
      color: #8898aa;
      vertical-align: unset; }
      @media (max-width: 767px) {
        .app-menu ul li i {
          font-size: 20px; } }
    .app-menu ul li.active i,
    .app-menu ul li.active a {
      color: #3949ab; }

.app-row {
  padding-right: 280px; }
  @media (max-width: 1199px) {
    .app-row {
      padding-right: 0; } }

/* 12.Chat App*/
.chat-app .scrollbar-container {
  padding-left: 10px;
  margin-left: -10px;
  padding-right: 10px;
  margin-right: 0;
  height: calc(100vh - 58px - 270px); }
  @media (max-width: 767px) {
    .chat-app .scrollbar-container {
      height: calc(100vh - 58px - 220px); } }
  .chat-app .scrollbar-container .ps__thumb-y {
    right: 10px; }

.chat-app .chat-text-left {
  padding-left: 64px; }

.chat-app .chat-text-right {
  padding-right: 64px; }

@media (max-width: 767px) {
  .chat-app .list-item-heading {
    font-size: 0.9rem; } }

@media (max-width: 767px) {
  .chat-app .card .card-body {
    padding: 0.75rem; } }

.chat-app-tab-content {
  height: calc(100% - 45px); }

.chat-app-tab-pane {
  height: calc(100% - 45px); }

.chat-input-container {
  width: 100%;
  height: 90px;
  background: #ffffff;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  padding-left: 340px;
  padding-right: 320px; }
  @media (max-width: 767px) {
    .chat-input-container {
      padding-right: 15px;
      padding-left: 15px; } }
  .chat-input-container input {
    border: medium none currentColor;
    border: initial;
    width: unset;
    height: 90px;
    padding-left: 0;
    display: inline-block; }
  @media (max-width: 991px) {
    .chat-input-container .send-button {
      padding-left: 0.7rem;
      padding-right: 0.7rem; } }

/* 13.Survey App*/
.survey-app .answers .badge {
  color: #8898aa;
  font-size: 13px;
  padding-left: 0.75em;
  padding-right: 0.75em; }

.survey-app .answers input {
  padding-right: 70px; }

.survey-app .question.edit-quesiton .edit-mode {
  display: block; }

.survey-app .question.edit-quesiton .view-mode {
  display: none; }

.survey-app .question.edit-quesiton .view-button {
  display: inline-block; }

.survey-app .question.edit-quesiton .edit-button {
  display: none; }

.survey-app .question.view-quesiton .edit-mode {
  display: none; }

.survey-app .question.view-quesiton .view-mode {
  display: block; }

.survey-app .question.view-quesiton .view-button {
  display: none; }

.survey-app .question.view-quesiton .edit-button {
  display: inline-block; }

.survey-app .survey-question-types .btn-group-icon {
  font-size: 21px;
  line-height: 28px; }

.survey-app .survey-question-types .btn {
  padding-bottom: 0.3rem; }

.input-icons {
  position: absolute;
  right: 0;
  top: 9px; }

/* 14.Todo App*/
.heading-icon {
  font-size: 20px;
  color: #3949ab;
  margin-right: 5px;
  line-height: inherit;
  vertical-align: middle; }

.todo-details {
  margin-top: 20px;
  margin-left: 40px; }

.heading-number {
  border: 1px solid #3949ab;
  padding: 4px;
  vertical-align: middle;
  margin-right: 10px;
  border-radius: 20px;
  width: 34px;
  height: 34px;
  text-align: center;
  color: #3949ab; }

/* 15.Main*/
main {
  margin-left: 340px;
  margin-top: 80px;
  margin-right: 0;
  margin-bottom: 20px; }
  main.sub-hidden {
    margin-left: 140px; }
  main.main-hidden {
    margin-left: 40px; }
  main .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  @media (max-width: 767px) {
    main {
      margin-left: 0px !important;
      margin-right: 0px !important;
      margin-top: 80px !important;
      margin-bottom: 0; } }
  @media (max-width: 575px) {
    main {
      margin-bottom: 0; } }

#app-container.sub-hidden main,
#app-container.menu-sub-hidden main,
#app-container.menu-hidden main {
  margin-left: 100px; }

#app-container.main-hidden main,
#app-container.menu-hidden main {
  margin-left: 0; }

/* 16.Cards*/
.card {
  border: medium none currentColor;
  border: initial;
  background: #ffffff;
  border-radius: 0.375rem;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .card .card-header .card-icon {
    right: 5px;
    top: 5px; }
    .card .card-header .card-icon i {
      font-size: 12px;
      color: #ced4da; }
  .card .card-subtitle {
    margin: 0;
    margin-bottom: 1rem; }
  .card .card-header .handle {
    cursor: default; }
  .card .card-title {
    margin-bottom: 2rem; }
    @media (max-width: 767px) {
      .card .card-title {
        margin-bottom: 1.25rem; } }

.card-img {
  border-radius: calc(0.25rem - 1px);
  height: 100%;
  object-fit: cover;
  max-height: 200px;
  width: unset; }

.card-img-bottom {
  width: 100%;
  border-bottom-left-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.15rem - 1px);
  border-top-right-radius: calc(0.15rem - 1px); }

.card-img-left {
  width: 100%;
  border-top-left-radius: calc(0.15rem - 1px);
  border-bottom-left-radius: calc(0.15rem - 1px);
  height: 100%;
  object-fit: cover; }

.card-img-right {
  width: 100%;
  border-top-right-radius: calc(0.15rem - 1px);
  border-bottom-right-radius: calc(0.15rem - 1px);
  height: 100%;
  object-fit: cover; }

.card-img-overlay {
  background: rgba(0, 0, 0, 0.5);
  padding: 1.75rem; }
  @media (max-width: 575px) {
    .card-img-overlay {
      padding: 1rem; } }

/* 17.Dashboard*/
.dashboard-line-chart {
  height: 283px; }

.dashboard-quick-post {
  min-height: 263px; }

.dashboard-list-with-thumbs {
  height: 400px; }

.dashboard-logs {
  height: 400px; }

.dashboard-list-with-user {
  height: 270px; }

.dashboard-donut-chart {
  height: 270px; }

.dashboard-small-chart {
  height: 150px; }
  .dashboard-small-chart .chart {
    height: 75px; }
  .dashboard-small-chart .lead {
    font-size: 1.4rem; }

.dashboard-small-chart-analytics {
  height: 180px; }
  .dashboard-small-chart-analytics .chart {
    height: 85px; }
  .dashboard-small-chart-analytics .lead {
    font-size: 1.4rem; }

.dashboard-filled-line-chart {
  height: 340px; }
  .dashboard-filled-line-chart .chart {
    height: 200px; }

.dashboard-sq-banner {
  background-image: -webkit-linear-gradient(left bottom, #2980b9, #3498db, #3949ab);
  background-image: linear-gradient(to right top, #2980b9, #3498db, #3949ab);
  background-size: cover;
  height: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-size: 350% auto;
  cursor: pointer; }
  .dashboard-sq-banner .lead {
    line-height: 2.3rem; }
  .dashboard-sq-banner:hover {
    background-position: right top; }

.dashboard-link-list {
  height: 385px; }

.dashboard-progress {
  height: 385px; }

.dashboard-top-rated {
  height: 300px; }
  @media (max-width: 991px) {
    .dashboard-top-rated {
      height: unset; } }
  .dashboard-top-rated .react-siema-container {
    margin-right: -0.5rem;
    margin-left: -0.5rem; }
    .dashboard-top-rated .react-siema-container img {
      height: 120px;
      display: flex;
      object-fit: cover;
      width: 100%; }
    .dashboard-top-rated .react-siema-container .react-rater {
      display: inline-block !important; }

.dashboard-search {
  height: 650px;
  background: url(/assets/img/plane.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #ffffff; }
  .dashboard-search .card-body {
    padding: 120px 50px 30px 50px; }
    @media (max-width: 1439px) {
      .dashboard-search .card-body {
        padding: 80px 30px 30px 30px; } }
  .dashboard-search .form-container {
    height: 400px;
    border-radius: 0.375rem;
    box-shadow: 0px -10px 15px 0px rgba(0, 0, 0, 0.04);
    padding: 2rem;
    background-color: #ffffff; }

.icon-cards-row {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -10px; }
  .icon-cards-row .react-siema-container {
    padding-bottom: 0px;
    padding-top: 10px; }
    .icon-cards-row .react-siema-container .icon-row-item {
      padding-left: 5px;
      padding-right: 5px; }
  .icon-cards-row [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px; }
  .icon-cards-row .card-body {
    padding: 2rem 0.5rem; }
  .icon-cards-row .card-text {
    color: #8898aa;
    height: 30px;
    line-height: 26px; }
  .icon-cards-row .lead {
    color: #3949ab;
    margin-bottom: 0; }
  .icon-cards-row i {
    font-size: 46px;
    line-height: 66px;
    color: #3949ab; }
  .icon-cards-row .card {
    -webkit-transition: box-shadow 1000ms;
    transition: box-shadow 1000ms;
    cursor: pointer; }
  @media (max-width: 1439px) {
    .icon-cards-row .card-text {
      height: 48px;
      line-height: 18px; }
    .icon-cards-row .lead {
      font-size: 1.6rem; }
    .icon-cards-row i {
      font-size: 32px;
      line-height: 47px; } }

.log-indicator {
  width: 13px;
  height: 13px;
  border: 2px solid #3949ab;
  border-radius: 14px;
  display: inline-block; }

/* 18.Calendar */
.big-calendar-header {
  margin-bottom: 1em; }

.rbc-month-header {
  min-height: 50px; }

.rbc-today {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial; }

.calendar-prev-btn,
.calendar-next-btn {
  outline: medium none invert !important;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important;
  border-radius: 40px !important;
  text-align: center;
  min-width: 30px;
  height: 30px;
  padding: 0.55rem 0;
  background: #3949ab;
  color: #ffffff;
  border: 1px solid #3949ab;
  line-height: 0.9 !important;
  font-size: 0.76rem;
  font-weight: normal !important; }
  .calendar-prev-btn span,
  .calendar-next-btn span {
    line-height: 1 !important;
    font-size: 0.76rem;
    font-weight: normal !important; }
  .calendar-prev-btn:hover,
  .calendar-next-btn:hover {
    background-color: transparent;
    border-color: #5262c5;
    color: #3949ab; }

.calendar-prev-btn {
  margin-right: 5px; }

.calendar-today-btn {
  padding: 0.4em 1.3em !important;
  height: unset !important; }

.rbc-month-row {
  min-height: 5em;
  z-index: 1; }
  @media (max-width: 575px) {
    .rbc-month-row {
      min-height: 3em; } }

.rbc-month-view {
  border: medium none currentColor;
  border: initial; }

.rbc-off-range-bg {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial; }

.rbc-off-range {
  color: #495057;
  opacity: 0.3; }

.rbc-day-bg + .rbc-day-bg,
.rbc-month-row + .rbc-month-row,
.rbc-header + .rbc-header,
.rbc-header {
  border-color: #ecf0f1 !important; }

.rbc-header {
  padding: 15px 5px;
  color: #3949ab; }

.rbc-date-cell {
  padding: 10px; }
  .rbc-date-cell a {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    text-align: center;
    vertical-align: middle;
    padding: 5px;
    font-weight: normal;
    font-weight: initial;
    display: inline-block; }

.rbc-date-cell.rbc-now a {
  background: #3949ab;
  color: #ffffff; }

.rbc-event {
  font-size: 0.85em;
  border-radius: 25px;
  text-align: center;
  padding: 0px 5px;
  background: #2980b9; }

.fc-basic-view .fc-body .fc-row {
  min-height: 6em; }
  @media (max-width: 575px) {
    .fc-basic-view .fc-body .fc-row {
      min-height: 3em; } }

.fc-bootstrap4 .fc-day-top .fc-day-number {
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  vertical-align: middle;
  padding: 5px; }

.fc-bootstrap4 td.fc-today {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial; }
  .fc-bootstrap4 td.fc-today .fc-day-number {
    background: #3949ab;
    color: #ffffff; }

.fc-day-grid-container {
  height: 100%;
  overflow: visible; }

.fc-row .fc-content-skeleton td,
.fc-row .fc-helper-skeleton td {
  padding: 5px; }

.fc-view,
.fc-view > table {
  border-top: 0;
  border-top: initial; }

.fc table {
  border: medium none currentColor;
  border: initial; }

.fc .table-bordered thead th,
.fc .table-bordered thead td {
  border-top: 0;
  border-top: initial;
  border-bottom: 0;
  border-bottom: initial;
  border-left: 0;
  border-left: initial; }

.fc td:first-of-type,
.fc th:first-of-type {
  border-left: 0;
  border-left: initial;
  border-bottom: 0;
  border-bottom: initial; }

.fc td:last-of-type,
.fc th:last-of-type {
  border-right: 0;
  border-right: initial;
  border-bottom: 0;
  border-bottom: initial; }

.fc-body .fc-row:last-of-type td {
  border-bottom: 0;
  border-bottom: initial; }

.fc tbody > tr > td.fc-widget-content:first-of-type {
  border-left: 0;
  border-left: initial;
  border-right: 0;
  border-right: initial;
  border-bottom: 0;
  border-bottom: initial; }

.fc table,
.fc .table-bordered th,
.fc .table-bordered td {
  border-color: #ecf0f1 !important; }

.fc-day-header {
  color: #3949ab; }
  .fc-day-header span {
    padding: 10px;
    display: inline-block; }

.fc-event {
  border: medium none currentColor;
  border: initial; }

.fc-event,
.fc-event-dot {
  background-color: #2980b9;
  color: #ffffff !important;
  padding: 1px 6px;
  border-radius: 10px;
  cursor: pointer;
  text-align: center; }

/* 19.Datatable */
.ReactTable {
  border: medium none currentColor;
  border: initial; }

.ReactTable .rt-th,
.ReactTable .rt-td {
  padding-top: 20px;
  padding-bottom: 10px; }

.ReactTable .rt-thead.-header {
  box-shadow: none;
  box-shadow: initial; }

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: none;
  box-shadow: initial;
  border: medium none currentColor;
  border: initial;
  text-align: left;
  font-weight: 700; }

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #ecf0f1; }

.ReactTable .list-item-heading {
  margin-bottom: 0; }

.ReactTable .rt-tbody .rt-td {
  border-right: 0;
  border-right: initial; }

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 #3949ab; }

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 #3949ab; }

/* 20.Alerts */
.alert {
  border-radius: 0; }

.alert-primary {
  color: #3949ab;
  background-color: rgba(57, 73, 171, 0.2);
  border-color: rgba(57, 73, 171, 0.1); }

.alert-secondary {
  color: #2980b9;
  background-color: rgba(41, 128, 185, 0.2);
  border-color: rgba(41, 128, 185, 0.1); }

.alert-success {
  color: #27ae60;
  background-color: rgba(39, 174, 96, 0.2);
  border-color: rgba(39, 174, 96, 0.1); }

.alert-info {
  color: #16a085;
  background-color: rgba(22, 160, 133, 0.2);
  border-color: rgba(22, 160, 133, 0.1); }

.alert-warning {
  color: #d35400;
  background-color: rgba(211, 84, 0, 0.2);
  border-color: rgba(211, 84, 0, 0.1); }

.alert-danger {
  color: #c0392b;
  background-color: rgba(192, 57, 43, 0.2);
  border-color: rgba(192, 57, 43, 0.1); }

.alert-dismissible .close {
  text-shadow: none;
  text-shadow: initial; }

.alert *[data-notify="title"] {
  display: block;
  font-size: 0.9rem; }

div[data-notify="container"] {
  padding: 18px; }

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto; }

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px; }

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0; }

.notification:hover,
.notification:focus {
  opacity: 1; }

.notification-enter {
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0); }

.notification-enter.notification-enter-active {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.4s;
  transition: all 0.4s; }

.notification-leave {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.notification-leave.notification-leave-active {
  visibility: hidden;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-transition: all 0.4s;
  transition: all 0.4s; }

/* 21.Forms */
.av-invalid .av-label {
  color: #495057; }

.av-tooltip .invalid-feedback {
  border-radius: 15px;
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  color: #ffffff;
  background: #c0392b;
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 1;
  margin-top: -0.2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  .av-tooltip .invalid-feedback:after {
    content: "";
    position: absolute;
    top: -5px;
    left: -2.5px;
    margin-left: 50%;
    width: 10px;
    height: 5px;
    border-bottom: solid 5px #c0392b;
    border-left: solid 5px transparent;
    border-right: solid 5px transparent; }

@media (max-width: 575px) {
  .form-inline .form-group {
    width: 100%; } }

.form-check-label,
.custom-control-label {
  line-height: 24px; }

.react-tagsinput {
  background-color: #ffffff;
  border: 1px solid #ced4da;
  outline: medium none invert;
  outline: initial;
  box-shadow: none;
  box-shadow: initial; }

.react-tagsinput--focused {
  border: 1px solid rgba(57, 73, 171, 0.6); }

.react-tagsinput-tag {
  background: #3949ab;
  border-radius: 15px;
  padding: 0px 10px;
  margin-bottom: 0px;
  display: inline-block;
  font-size: 12px;
  color: #ffffff;
  border: medium none currentColor;
  border: initial; }

.form-control:focus {
  background: #ffffff;
  color: #495057; }

.bootstrap-tagsinput {
  width: 100%; }

.bootstrap-tagsinput input {
  padding: 0; }

.form-control:focus {
  border-color: rgba(57, 73, 171, 0.6); }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.4rem + 3px); }

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: rgba(73, 80, 87, 0.25); }

.custom-control-input:active ~ .custom-control-label::before {
  background-color: transparent; }

.custom-checkbox .custom-control-label.indeterminate::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#ffffff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#ffffff'/%3E%3C/svg%3E"); }

.custom-control-label,
.custom-control-input {
  outline: medium none invert !important;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important; }

.custom-control-input {
  left: 1px;
  top: 3px;
  opacity: 0;
  z-index: 1; }

.custom-control-label::before {
  border: 1px solid #8898aa;
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial; }

.custom-checkbox
.custom-control-input:indeterminate
~ .custom-control-label::before {
  background-color: #3949ab; }

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3949ab;
  box-shadow: none !important;
  box-shadow: initial !important;
  border: 1px solid #3949ab; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3949ab;
  box-shadow: none !important;
  box-shadow: initial !important; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3949ab;
  box-shadow: none !important;
  box-shadow: initial !important; }

.custom-control-label::after,
.custom-control-label::before {
  box-shadow: none !important;
  box-shadow: initial !important; }

.custom-control-label::before {
  top: 0.25rem; }

.custom-control-label::after {
  top: 0.25rem; }

.btn.rotate-icon-click i {
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s; }

.btn.rotate i {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.btn .custom-control-label::before {
  border: 1px solid #ffffff; }

.btn .custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid #ffffff; }

.btn-group-icon {
  line-height: 22px; }

.valid-tooltip,
.invalid-tooltip {
  border-radius: 15px;
  padding: 0.5rem 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: 0.76rem; }

.valid-tooltip {
  background-color: #28a745;
  margin-top: -0.2rem; }

.invalid-tooltip {
  background-color: #dc3545;
  margin-top: -0.2rem; }

.valid-tooltip:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -2.5px;
  margin-left: 50%;
  width: 10px;
  height: 5px;
  border-bottom: solid 5px #28a745;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent; }

.invalid-tooltip:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -2.5px;
  margin-left: 50%;
  width: 10px;
  height: 5px;
  border-bottom: solid 5px #dc3545;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent; }

.valid-icon {
  position: absolute;
  bottom: 8px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.76rem;
  line-height: 1;
  border-radius: 0.2rem;
  right: 4px;
  color: #27ae60; }

.invalid-icon {
  position: absolute;
  bottom: 2px;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem;
  margin-top: 0;
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.2rem;
  right: 4px;
  color: #c0392b; }

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-icon,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-icon,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-icon,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-icon {
  display: block; }

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-icon,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-icon,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-icon,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-icon {
  display: block; }

.autosuggest {
  position: relative; }

.react-autosuggest__suggestions-container {
  border-radius: 0.375rem;
  z-index: 20;
  box-shadow: none;
  box-shadow: initial;
  margin-top: -1px;
  background: #ffffff;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  top: 100%; }

.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0; }

.react-autosuggest__suggestion {
  cursor: default;
  display: block;
  font-size: inherit;
  padding: 8px 12px;
  width: 100%;
  background: #ffffff !important;
  color: #495057 !important; }
  .react-autosuggest__suggestion:hover, .react-autosuggest__suggestion:active {
    background: #5262c5 !important;
    color: #ffffff !important; }

.react-autosuggest__input--open {
  border: 1px solid rgba(57, 73, 171, 0.6) !important; }

.react-autosuggest__suggestions-container--open {
  border: 1px solid rgba(57, 73, 171, 0.6);
  border-top: 0;
  border-top: initial; }

.react-select {
  outline: medium none invert !important;
  outline: initial !important;
  box-shadow: none !important; }

.react-select__value-container {
  outline: medium none invert !important;
  outline: initial !important;
  box-shadow: none !important;
  padding: 0.375rem 0.75rem !important;
  height: 2.25rem; }

.react-select .react-select__dropdown-indicator {
  color: #8898aa; }

.react-select .react-select__menu-list {
  padding-bottom: 0;
  padding-top: 0; }

.react-select .react-select__single-value,
.react-select .react-select__multi-value__label {
  color: #495057; }

.react-select .react-select__dropdown-indicator,
.react-select
.react-select__control--is-focused
.react-select__dropdown-indicator,
.react-select .react-select__clear-indicator,
.react-select
.react-select__control--is-focused
.react-select__clear-indicator {
  outline: medium none invert;
  outline: initial;
  box-shadow: none;
  box-shadow: initial; }
  .react-select .react-select__dropdown-indicator:active, .react-select .react-select__dropdown-indicator:focus, .react-select .react-select__dropdown-indicator:hover,
  .react-select
.react-select__control--is-focused
.react-select__dropdown-indicator:active,
  .react-select
.react-select__control--is-focused
.react-select__dropdown-indicator:focus,
  .react-select
.react-select__control--is-focused
.react-select__dropdown-indicator:hover,
  .react-select .react-select__clear-indicator:active,
  .react-select .react-select__clear-indicator:focus,
  .react-select .react-select__clear-indicator:hover,
  .react-select
.react-select__control--is-focused
.react-select__clear-indicator:active,
  .react-select
.react-select__control--is-focused
.react-select__clear-indicator:focus,
  .react-select
.react-select__control--is-focused
.react-select__clear-indicator:hover {
    color: #3949ab !important; }

.react-select__control {
  border-radius: 0.375rem !important;
  border: 1px solid #ced4da !important;
  background: #ffffff !important;
  color: #495057;
  background-clip: padding-box;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
  -webkit-transition: box-shadow .15s ease;
  transition: box-shadow .15s ease; }

.react-select__indicator-separator {
  display: none; }

.react-select__dropdown-indicator svg {
  width: 15px;
  height: 15px; }

.react-select__option {
  color: #495057 !important; }
  .react-select__option:hover, .react-select__option:active {
    background: #3949ab !important;
    color: #ffffff !important; }

.react-select__option--is-selected {
  background: #3949ab !important;
  color: #ffffff !important; }

.react-select__control--is-focused {
  border-color: rgba(57, 73, 171, 0.6) !important; }

.react-select__multi-value {
  background: transparent !important;
  border: 1px solid #ced4da; }

.react-select__multi-value__remove:hover,
.react-select__multi-value__remove:active {
  background: transparent !important;
  color: #3949ab !important; }

.react-select .react-select__menu {
  border-radius: 0.375rem !important;
  z-index: 20 !important;
  border: 1px solid rgba(57, 73, 171, 0.6) !important;
  border-top: 0 !important;
  border-top: initial !important;
  margin-top: 0px !important;
  background-color: #ffffff;
  border-width: 1px !important; }

.react-select__single-value {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.react-datepicker {
  background-color: #ffffff;
  border: #ced4da; }

.react-datepicker__input-container input {
  background-color: #ffffff;
  border: #ced4da; }

.react-datepicker__input-container input:focus {
  border-color: rgba(57, 73, 171, 0.6) !important; }

.react-datepicker__input-container input::-webkit-input-placeholder {
  color: #495057; }

.react-datepicker__input-container input::-ms-input-placeholder {
  color: #495057; }

.react-datepicker__input-container input::placeholder {
  color: #495057; }

.react-datepicker-popper {
  z-index: 20; }

.react-datepicker-wrapper {
  width: 100%; }

.react-datepicker__input-container {
  width: 100%; }

.react-datepicker__input-container input {
  font-size: 0.875rem;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  width: 100%;
  outline: medium none invert;
  outline: initial;
  padding: 0.375rem 0.75rem !important;
  height: calc(2.25rem + 2px);
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
  -webkit-transition: box-shadow .15s ease;
  transition: box-shadow .15s ease; }

.react-datepicker {
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  font-family: "Nunito Sans", sans-serif; }

.react-datepicker__header {
  background-color: #ffffff;
  border-bottom: 0;
  border-bottom: initial; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 35px;
  height: 35px;
  line-height: 2.3rem;
  border-radius: 0 !important;
  margin: 0;
  outline: medium none invert !important;
  outline: initial !important; }

.react-datepicker__day:hover {
  background: #ced4da; }

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected {
  background: #3949ab; }

.react-datepicker__day--today {
  font-weight: 400;
  color: #ffffff !important; }

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  border-bottom-color: #ffffff; }

.react-datepicker-popper[data-placement^="bottom"]
.react-datepicker__triangle::before {
  border-bottom-color: #ced4da; }

.react-datepicker__current-month,
.react-datepicker-time__header {
  color: #495057; }

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #495057; }

.react-datepicker__input-container input {
  color: #495057; }

.react-datepicker__time-container {
  border-left: 1px solid #ced4da;
  width: 71px; }

.react-datepicker__time-container .react-datepicker__time {
  background-color: #ffffff;
  color: #495057; }

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  color: #ffffff; }

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ffffff; }

.react-datepicker-popper[data-placement^="top"]
.react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-top-color: #ced4da; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item:hover {
  background: #fbfbff; }

.react-datepicker__triangle {
  left: 30px; }

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background: #3949ab; }

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background: #ced4da; }

.react-datepicker.embedded {
  border: medium none currentColor;
  border: initial;
  width: 100%; }
  .react-datepicker.embedded .react-datepicker__day-name,
  .react-datepicker.embedded .react-datepicker__day,
  .react-datepicker.embedded .react-datepicker__time-name {
    width: 14.28%; }
  .react-datepicker.embedded .react-datepicker__month-container {
    width: 100%; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box {
  width: 87px; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 30px; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item--selected {
  background: #3949ab; }

.react-fine-uploader-gallery-dropzone,
.react-fine-uploader-gallery-nodrop-container {
  min-height: 118px;
  border: 1px solid #ced4da;
  background: #ffffff;
  padding: 10px 10px;
  border-radius: 0.375rem;
  color: #495057;
  padding: 0 !important; }

.react-fine-uploader-gallery-dropzone-active {
  border: 1px solid rgba(57, 73, 171, 0.6) !important; }

.react-fine-uploader-gallery-dropzone button svg {
  fill: #3949ab; }

.react-fine-uploader-gallery-file-input-container {
  display: inline-block;
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute !important;
  margin: 0;
  padding: 0; }

.react-fine-uploader-gallery-dropzone-content,
.react-fine-uploader-gallery-nodrop-content {
  font-size: 0.875rem;
  color: #495057;
  opacity: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.react-fine-uploader-gallery-files {
  padding: 10px;
  margin-bottom: 0; }

.react-fine-uploader-gallery-file {
  display: flex;
  flex-direction: row;
  width: 260px;
  height: unset;
  box-shadow: none !important;
  box-shadow: initial !important;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  border: 1px solid #ced4da;
  float: left;
  margin: 16px; }

.react-fine-uploader-gallery-thumbnail-container {
  display: block;
  height: unset !important;
  text-align: center;
  width: 30% !important;
  height: 62px !important;
  overflow: hidden; }

.react-fine-uploader-gallery-progress-bar-container {
  width: 100%;
  height: 3px;
  background: #ced4da; }

.react-fine-uploader-gallery-progress-bar,
.react-fine-uploader-gallery-total-progress-bar {
  background: #3949ab;
  box-shadow: none;
  box-shadow: initial; }

.react-fine-uploader-gallery-status,
.react-fine-uploader-filesize-value,
.react-fine-uploader-filesize-unit {
  font-style: normal;
  font-style: initial;
  color: #3949ab;
  font-size: 0.76rem; }

.react-fine-uploader-filename.react-fine-uploader-gallery-filename {
  font-weight: normal; }

.react-fine-uploader-gallery-total-progress-bar-container {
  display: none;
  background: #ced4da; }

.react-fine-uploader-gallery-thumbnail {
  height: 100%; }

.react-fine-uploader-gallery-upload-failed-icon,
.react-fine-uploader-gallery-upload-success-icon {
  height: 36px;
  width: 36px; }

.react-fine-uploader-gallery-upload-failed-icon {
  fill: #ffffff;
  opacity: 1; }

.react-fine-uploader-gallery-file-footer {
  width: 70%;
  padding: 15px;
  background: #ffffff; }

.react-fine-uploader-gallery-thumbnail-icon-backdrop {
  opacity: 0; }

.react-fine-uploader-gallery-thumbnail-icon-backdrop,
.react-fine-uploader-gallery-upload-failed-icon,
.react-fine-uploader-gallery-upload-success-icon {
  left: 38px;
  position: absolute;
  top: 50%; }

.dropzone .dz-message {
  text-align: center; }

.dropzone.dz-clickable {
  cursor: pointer; }

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  position: relative;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  margin: 0; }

.dropzone .dz-preview.dz-image-preview {
  width: 260px;
  height: unset;
  min-height: unset;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  background: #ffffff;
  color: #495057; }
  .dropzone .dz-preview.dz-image-preview strong {
    font-weight: normal; }
  .dropzone .dz-preview.dz-image-preview .remove {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #3949ab; }
  .dropzone .dz-preview.dz-image-preview .dz-details {
    position: static;
    display: block;
    opacity: 1;
    text-align: left;
    min-width: unset;
    z-index: auto;
    z-index: initial;
    color: #495057; }
  .dropzone .dz-preview.dz-image-preview .dz-error-mark {
    color: #ffffff;
    -webkit-transform: translateX(-50%) translateY(-50%) !important;
            transform: translateX(-50%) translateY(-50%) !important;
    -webkit-transition: none 0s ease 0s !important;
    transition: none 0s ease 0s !important;
    -webkit-transition: initial !important;
    transition: initial !important;
    -webkit-animation: none 0s ease 0s 1 normal none running !important;
            animation: none 0s ease 0s 1 normal none running !important;
    -webkit-animation: initial !important;
            animation: initial !important;
    margin-left: 0;
    margin-top: 0; }
    .dropzone .dz-preview.dz-image-preview .dz-error-mark i {
      font-size: 26px; }
  .dropzone .dz-preview.dz-image-preview .dz-progress {
    width: 90%;
    margin-left: 0;
    margin-top: 0;
    left: 50%;
    right: 0;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    height: 5px; }
    .dropzone .dz-preview.dz-image-preview .dz-progress .dz-upload {
      width: 100%;
      background: #3949ab; }
  .dropzone .dz-preview.dz-image-preview .dz-error-message {
    border-radius: 15px;
    background: #c0392b;
    top: 60px; }
    .dropzone .dz-preview.dz-image-preview .dz-error-message:after {
      border-bottom: 6px solid #c0392b; }

.custom-switch {
  display: block;
  background: #ced4da;
  width: 58px;
  height: 27px;
  border: 1px solid #ced4da; }
  .custom-switch:after {
    width: 18px;
    height: 18px;
    top: 3px;
    margin-left: 3px;
    box-shadow: none;
    box-shadow: initial;
    -webkit-transform: none !important;
            transform: none !important;
    -webkit-transform: initial !important;
            transform: initial !important;
    -webkit-animation-name: unset !important;
            animation-name: unset !important;
    background: #ffffff; }
  .custom-switch.rc-switch-checked:after {
    left: 30px; }
  .custom-switch.rc-switch-checked.custom-switch-primary {
    background: #3949ab;
    border: 1px solid #3949ab; }
  .custom-switch.rc-switch-checked.custom-switch-secondary {
    background: #2980b9;
    border: 1px solid #2980b9; }
  .custom-switch.custom-switch-primary-inverse {
    border: 1px solid #ced4da; }
  .custom-switch.rc-switch-checked.custom-switch-primary-inverse {
    background: #ffffff;
    border: 1px solid #3949ab; }
    .custom-switch.rc-switch-checked.custom-switch-primary-inverse:after {
      background: #3949ab; }
  .custom-switch.custom-switch-secondary-inverse {
    border: 1px solid #ced4da; }
  .custom-switch.rc-switch-checked.custom-switch-secondary-inverse {
    background: #ffffff;
    border: 1px solid #2980b9; }
    .custom-switch.rc-switch-checked.custom-switch-secondary-inverse:after {
      background: #2980b9; }

.custom-switch .custom-switch-input + .custom-switch-btn {
  background: #ced4da; }

.custom-switch.custom-switch-primary
.custom-switch-input:checked
+ .custom-switch-btn {
  background: #3949ab;
  border: 1px solid #3949ab; }

.custom-switch.custom-switch-secondary
.custom-switch-input:checked
+ .custom-switch-btn {
  background: #2980b9;
  border: 1px solid #2980b9; }

.custom-switch.custom-switch-primary-inverse
.custom-switch-input
+ .custom-switch-btn {
  border: 1px solid #ced4da; }

.custom-switch.custom-switch-primary-inverse
.custom-switch-input:checked
+ .custom-switch-btn {
  background: #ffffff;
  border: 1px solid #3949ab; }

.custom-switch.custom-switch-primary-inverse
.custom-switch-input:checked
+ .custom-switch-btn:after {
  background: #3949ab; }

.custom-switch.custom-switch-secondary-inverse
.custom-switch-input
+ .custom-switch-btn {
  border: 1px solid #ced4da; }

.custom-switch.custom-switch-secondary-inverse
.custom-switch-input:checked
+ .custom-switch-btn {
  background: #ffffff;
  border: 1px solid #2980b9; }

.custom-switch.custom-switch-secondary-inverse
.custom-switch-input:checked
+ .custom-switch-btn:after {
  background: #2980b9; }

.custom-switch .custom-switch-input + .custom-switch-btn:after {
  background: #ffffff; }

.custom-switch .custom-switch-input + .custom-switch-btn {
  border-color: #ced4da; }

.input-group-text {
  border-radius: 0.375rem;
  background-color: white;
  border-color: #ced4da;
  color: #495057;
  font-size: 0.875rem;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem; }

@-webkit-keyframes autofill {
  to {
    color: #495057;
    background: transparent; } }

@keyframes autofill {
  to {
    color: #495057;
    background: transparent; } }

input:-webkit-autofill {
  -webkit-animation-name: autofill;
          animation-name: autofill;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

input:-webkit-autofill {
  -webkit-text-fill-color: #495057 !important; }

.form-control-sm,
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  border-radius: 0.375rem; }

.form-control-lg,
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  border-radius: 0.375rem; }

.custom-select {
  border-radius: 0.375rem;
  padding: 0.75rem 0.75rem 0.5rem 0.75rem; }

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  outline: medium none invert !important;
  outline: initial !important;
  box-shadow: none !important;
  box-shadow: initial !important; }

.custom-select {
  height: calc(2.5rem + 2px); }

.custom-select:focus {
  border-color: #3949ab; }

.custom-file-input:focus ~ .custom-file-label {
  border-color: rgba(57, 73, 171, 0.6); }

.custom-file-label::after {
  background: #ffffff;
  color: #495057;
  border-color: #ced4da; }

.custom-file-input {
  box-shadow: none !important;
  box-shadow: initial !important; }

.custom-file-label {
  background: #ffffff;
  border-color: #ced4da; }

.custom-file-label {
  box-shadow: none !important;
  box-shadow: initial !important;
  border-radius: 0.375rem;
  height: calc(2.5rem + 2px);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem; }

.custom-file {
  height: calc(2.5rem + 2px); }

.custom-file-label:focus,
.custom-file-input:focus {
  border-color: #3949ab; }

.custom-file-label::after {
  height: calc(calc(2.5rem + 2px) - 1px * 2);
  padding: 0.75rem 0.75rem 0.5rem 0.75rem; }

.jumbotron {
  background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
  background: initial; }

/* 22.Slider(Range) */
.rc-slider-tooltip {
  background: #ffffff;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  text-align: center;
  top: 150%;
  bottom: unset;
  padding: 5px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.rc-slider-handle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background: #ffffff;
  cursor: default;
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
  box-shadow: initial !important; }

.rc-slider-track,
.rc-slider-rail,
.rc-slider-step {
  height: 7px; }

.rc-slider-rail {
  border: 1px solid #ced4da;
  background: #ffffff; }

.rc-slider-handle {
  margin-top: -7px; }

.rc-slider-track {
  background: #3949ab; }

/* 23.Navs */
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #3949ab; }

.nav-pills .nav-link {
  border-radius: 50px; }

.nav-link {
  padding: 0.5rem 1rem; }
  @media (max-width: 767px) {
    .nav-link {
      padding: 0.5rem 0.5rem; } }
  .nav-link.active {
    color: #3949ab; }

.page-item {
  padding: 10px; }
  @media (max-width: 767px) {
    .page-item {
      padding: 3px; } }
  .page-item .page-link {
    outline: medium none invert !important;
    outline: initial !important;
    box-shadow: none !important;
    box-shadow: initial !important;
    line-height: 1.7;
    border-radius: 40px !important;
    min-width: 38px;
    text-align: center;
    height: 38px;
    padding: 0.55rem 0; }
    @media (max-width: 575px) {
      .page-item .page-link {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem; } }
    .page-item .page-link.next {
      background: #3949ab;
      color: #ffffff;
      border: 1px solid #3949ab; }
    .page-item .page-link.prev, .page-item .page-link.prev {
      background: #3949ab;
      border: 1px solid #3949ab;
      color: #ffffff; }
    .page-item .page-link.first, .page-item .page-link.last {
      background: transparent;
      color: #3949ab;
      border: 1px solid #3949ab;
      border-radius: 30px; }
      .page-item .page-link.first:hover, .page-item .page-link.last:hover {
        background: #3949ab;
        color: #ffffff;
        border: 1px solid #3949ab; }
    .page-item .page-link:hover {
      background-color: transparent;
      border-color: #5262c5;
      color: #3949ab; }
  .page-item.active .page-link {
    background: transparent;
    border: 1px solid #3949ab;
    color: #3949ab; }
  .page-item.disabled .page-link {
    border-color: #ced4da;
    color: #ced4da;
    background: transparent; }

.page-link {
  background-color: transparent;
  border-color: transparent;
  color: #495057; }

.btn-sm.page-link {
  padding: 0.5rem 0.5rem; }

.pagination-lg .page-item {
  padding: 15px; }
  @media (max-width: 767px) {
    .pagination-lg .page-item {
      padding: 3px; } }
  .pagination-lg .page-item .page-link {
    min-width: 50px;
    height: 50px; }
    @media (max-width: 767px) {
      .pagination-lg .page-item .page-link {
        min-width: 38px;
        height: 38px;
        padding: 0.55rem 0;
        line-height: 1.5;
        font-size: 0.875rem; } }
    @media (max-width: 575px) {
      .pagination-lg .page-item .page-link {
        min-width: 30px;
        height: 30px;
        line-height: 0.9;
        font-size: 0.76rem; } }

.pagination-sm .page-item {
  padding: 6px; }
  @media (max-width: 767px) {
    .pagination-sm .page-item {
      padding: 3px; } }
  .pagination-sm .page-item .page-link {
    min-width: 30px;
    height: 30px;
    line-height: 0.9;
    font-size: 0.76rem; }

/* 24.Tooltip and popover */
.popover {
  border-radius: 0.375rem;
  background-color: #ffffff;
  border-color: #ced4da; }
  .popover .popover-body {
    color: #495057; }

.popover-header {
  background-color: transparent;
  border-bottom: 0;
  border-bottom: initial; }

.tooltip-inner {
  padding: 0.5rem 0.75rem;
  color: #495057;
  background-color: #ffffff;
  border-radius: 0.1rem;
  border: 1px solid #ced4da; }

.tooltip.show {
  opacity: 1; }

.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  border-right-color: #ced4da; }

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-right-color: #ffffff; }

.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  border-left-color: #ced4da; }

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-left-color: #ffffff; }

.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #ced4da; }

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-bottom-color: #ffffff; }

.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  border-top-color: #ced4da; }

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-top-color: #ffffff; }

.tooltip .arrow::before,
.tooltip .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: #ced4da; }

.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #ffffff; }

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::after,
.bs-tooltip-auto[x-placement^="right"] .arrow::after {
  border-width: 0.4rem 0.4rem 0.4rem 0; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: #ced4da; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0; }

.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #ffffff; }

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::after,
.bs-tooltip-auto[x-placement^="top"] .arrow::after {
  border-width: 0.4rem 0.4rem 0; }

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: #ced4da; }

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem; }

.bs-tooltip-bottom .arrow::after,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #ffffff; }

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: #ced4da; }

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before,
.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem; }

.bs-tooltip-left .arrow::after,
.bs-tooltip-auto[x-placement^="left"] .arrow::after {
  right: 0;
  border-left-color: #ffffff; }

.search-sm {
  position: relative; }
  .search-sm input {
    background: none;
    outline: medium none invert !important;
    outline: initial !important;
    border: 1px solid #8898aa;
    border-radius: 15px;
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    font-size: 0.76rem;
    line-height: 1.3;
    color: #495057; }
  .search-sm:after {
    font-family: "simple-line-icons";
    content: "\E090";
    font-size: 14px;
    border-radius: 10px;
    color: #8898aa;
    position: absolute;
    width: 25px;
    right: 2px;
    text-align: center;
    cursor: pointer;
    top: 2px; }

.notification {
  border-radius: 0.375rem;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  padding: 25px 25px 25px 25px; }

.notification:before {
  content: "";
  display: none; }

.notification-primary {
  color: #3949ab;
  background-color: #ffffff;
  border: 1px solid #3949ab;
  opacity: 1; }
  .notification-primary .notification-message .title {
    color: #3949ab; }
  .notification-primary .notification-message .message {
    color: #495057; }
  .notification-primary.filled {
    color: #ffffff;
    background-color: #3949ab; }
    .notification-primary.filled .notification-message .title {
      color: #ffffff; }
    .notification-primary.filled .notification-message .message {
      color: #ffffff; }

.notification-secondary {
  color: #2980b9;
  background-color: #ffffff;
  border: 1px solid #2980b9;
  opacity: 1; }
  .notification-secondary .notification-message .title {
    color: #2980b9; }
  .notification-secondary .notification-message .message {
    color: #8898aa; }
  .notification-secondary.filled {
    color: #ffffff;
    background-color: #2980b9; }
    .notification-secondary.filled .notification-message .title {
      color: #ffffff; }
    .notification-secondary.filled .notification-message .message {
      color: #ffffff; }

.notification-info {
  color: #16a085;
  background-color: #ffffff;
  border: 1px solid #16a085;
  opacity: 1; }
  .notification-info .notification-message .title {
    color: #16a085; }
  .notification-info .notification-message .message {
    color: #8898aa; }
  .notification-info.filled {
    color: #ffffff;
    background-color: #16a085; }
    .notification-info.filled .notification-message .title {
      color: #ffffff; }
    .notification-info.filled .notification-message .message {
      color: #ffffff; }

.notification-success {
  color: #27ae60;
  background-color: #ffffff;
  border: 1px solid #27ae60;
  opacity: 1; }
  .notification-success .notification-message .title {
    color: #27ae60; }
  .notification-success .notification-message .message {
    color: #8898aa; }
  .notification-success.filled {
    color: #ffffff;
    background-color: #27ae60; }
    .notification-success.filled .notification-message .title {
      color: #ffffff; }
    .notification-success.filled .notification-message .message {
      color: #ffffff; }

.notification-warning {
  color: #d35400;
  background-color: #ffffff;
  border: 1px solid #d35400;
  opacity: 1; }
  .notification-warning .notification-message .title {
    color: #d35400; }
  .notification-warning .notification-message .message {
    color: #8898aa; }
  .notification-warning.filled {
    color: #ffffff;
    background-color: #d35400; }
    .notification-warning.filled .notification-message .title {
      color: #ffffff; }
    .notification-warning.filled .notification-message .message {
      color: #ffffff; }

.notification-error {
  color: #c0392b;
  background-color: #ffffff;
  border: 1px solid #c0392b;
  opacity: 1; }
  .notification-error .notification-message .title {
    color: #c0392b; }
  .notification-error .notification-message .message {
    color: #8898aa; }
  .notification-error.filled {
    color: #ffffff;
    background-color: #c0392b; }
    .notification-error.filled .notification-message .title {
      color: #ffffff; }
    .notification-error.filled .notification-message .message {
      color: #ffffff; }

/* 25.Progress */
.progress {
  background-color: #ecf0f1;
  height: 3px; }

.progress-bar {
  background-color: #3949ab; }

.CircularProgressbar-text {
  fill: #495057 !important; }

.progress-bar-circle {
  width: 54px;
  height: 54px; }
  .progress-bar-circle svg path:first-of-type {
    stroke: #ced4da; }
  .progress-bar-circle svg path:last-of-type {
    stroke: #3949ab; }
  .progress-bar-circle.progress-bar-banner svg path:first-of-type {
    stroke: #263172; }
  .progress-bar-circle.progress-bar-banner svg path:last-of-type {
    stroke: #ffffff; }

.progress-banner {
  height: 200px;
  background-image: -webkit-linear-gradient(left bottom, #2980b9, #3498db, #3949ab);
  background-image: linear-gradient(to right top, #2980b9, #3498db, #3949ab);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-size: 200% auto;
  cursor: pointer; }
  .progress-banner .CircularProgressbar .CircularProgressbar-text {
    fill: #ffffff !important; }
  .progress-banner .lead {
    font-size: 1.5rem;
    margin-bottom: 0.5rem; }
    @media (max-width: 1199px) {
      .progress-banner .lead {
        font-size: 1.2rem;
        margin-bottom: 0.2rem; } }
  .progress-banner i {
    font-size: 2.7rem;
    margin-bottom: 1rem; }
    @media (max-width: 1199px) {
      .progress-banner i {
        font-size: 2rem;
        margin-bottom: 0.2rem; } }
  .progress-banner .progress-bar-circle.progress-bar-banner {
    width: 120px;
    height: 120px; }
    .progress-banner .progress-bar-circle.progress-bar-banner svg path:last-of-type {
      stroke: #e6e6e6; }
    @media (max-width: 1199px) {
      .progress-banner .progress-bar-circle.progress-bar-banner {
        width: 80px;
        height: 80px; } }
  .progress-banner .progress-bar-banner .progressbar-text {
    color: #495057 !important;
    font-size: 1.7rem;
    width: 110px;
    font-weight: 300; }
    @media (max-width: 1199px) {
      .progress-banner .progress-bar-banner .progressbar-text {
        font-size: 1.2rem;
        margin-bottom: 0.2rem; } }
  .progress-banner:hover {
    background-position: right top; }

/* 26.Rating */
.br-theme-bootstrap-stars .br-widget a.br-active:after {
  color: #3949ab; }

.br-theme-bootstrap-stars .br-widget a.br-selected:after {
  color: #3949ab; }

.react-rater {
  display: block !important;
  font-size: 16px;
  color: #ced4da; }

.react-rater-star {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "simple-line-icons";
  color: #ced4da;
  margin-right: 3px;
  font-size: 18px; }
  .react-rater-star.is-active, .react-rater-star.will-be-active {
    color: #3949ab; }
  .react-rater-star:after {
    content: "\E09B"; }

/* 27.Sortable */
.sortable {
  cursor: default; }

.sortable span {
  vertical-align: middle; }

.sortable-ghost {
  opacity: 0.5; }

/* 28.Spinner */
.spinner {
  width: 36px;
  text-align: center; }

.spinner > span {
  width: 6px;
  height: 6px;
  background-color: #ffffff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.2s infinite ease-in-out both;
  animation: sk-bouncedelay 1.2s infinite ease-in-out both; }

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

/* 29.Croppper */
.cropper-line {
  background-color: #3949ab; }

.cropper-point {
  background-color: #3949ab; }

.cropper-view-box {
  outline-color: #3949ab;
  outline: #3949ab; }

.cropper-preview {
  overflow: hidden; }

#cropperContainer {
  height: 300px;
  display: none; }

/* 30.Modal */
.modal .modal-header,
.modal .modal-body,
.modal .modal-footer {
  padding: 1.75rem; }

.modal .modal-header {
  border-bottom: 1px solid #ced4da; }

.modal .modal-footer {
  border-top: 1px solid #ced4da; }

.modal .close {
  color: #495057;
  text-shadow: none;
  text-shadow: initial; }

.modal-content {
  border: medium none currentColor;
  border: initial;
  border-radius: 0.1rem;
  background: #ffffff; }

.modal-dialog {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }

.modal-right {
  padding-right: 0 !important; }
  .modal-right .modal-dialog {
    margin: 0 auto;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 100%;
    max-width: 380px; }
  .modal-right .modal-content {
    min-height: 100%; }
  .modal-right .modal-header {
    height: 58px; }
    @media (max-width: 767px) {
      .modal-right .modal-header {
        height: 58px; } }
  .modal-right .modal-footer {
    justify-content: center; }
  .modal-right .modal.fade .modal-dialog {
    -webkit-transform: translate(25%, 0) !important;
            transform: translate(25%, 0) !important; }
  .modal-right .modal.show .modal-dialog {
    -webkit-transform: translate(0, 0) !important;
            transform: translate(0, 0) !important; }

/* 31.Authorization */
.auth-card {
  display: flex;
  flex-direction: row;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04); }
  .auth-card .image-side {
    width: 40%;
    background: url("/assets/img/login-balloon.jpg") no-repeat center top;
    background-size: cover;
    padding: 80px 40px; }
    .auth-card .image-side .h3 {
      line-height: 0.875rem; }
  .auth-card .form-side {
    width: 60%;
    padding: 80px; }
  @media (max-width: 991px) {
    .auth-card {
      flex-direction: column; }
      .auth-card .image-side {
        width: 100%;
        padding: 60px; }
      .auth-card .form-side {
        width: 100%;
        padding: 60px; } }
  @media (max-width: 767px) {
    .auth-card p.h2 {
      font-size: 1.6rem; } }
  @media (max-width: 575px) {
    .auth-card {
      flex-direction: column; }
      .auth-card .image-side {
        padding: 35px 30px; }
      .auth-card .form-side {
        padding: 35px 30px; }
      .auth-card .logo-single {
        margin-bottom: 20px; }
      .auth-card p.h2 {
        font-size: 1.4rem; } }

/* 32.Html Editors */
.html-editor {
  height: 350px; }

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: #3949ab; }

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: #3949ab; }

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar button:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter {
  stroke: #3949ab; }

.ql-toolbar.ql-snow {
  border-color: #ced4da; }

.ql-container.ql-snow {
  border-color: #ced4da; }

.html-editor-bubble {
  height: 200px; }

.html-editor-bubble .ql-editor {
  border: 1px solid #ced4da; }

.ql-tooltip {
  z-index: 4; }

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  height: 350px; }

.ck.ck-content.ck-editor__editable.ck-rounded-corners {
  box-shadow: none !important;
  box-shadow: initial !important; }

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-focused {
  border-color: #ced4da; }

.RichEditor-root {
  background: #ffffff;
  border: 1px solid #ced4da;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  padding: 15px; }

.RichEditor-editor {
  border-top: 1px solid #ced4da;
  cursor: text;
  font-size: 14px;
  margin-top: 10px; }

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px; }

.RichEditor-editor .public-DraftEditor-content {
  min-height: 200px; }

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none; }

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #ced4da;
  color: #8898aa;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px; }

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: #8898aa;
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px; }

.RichEditor-controls {
  font-family: "Nunito Sans", sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.RichEditor-styleButton {
  color: #ced4da;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
  color: #495057;
  font-size: 0.76rem; }

.RichEditor-activeButton {
  color: #495057; }

.ql-editor {
  height: 200px; }

.ql-container.ql-bubble {
  border: 1px solid #ced4da; }

/* 33.Icons */
.simple-line-icons .glyph,
.mind-icons .glyph {
  width: 14.28%;
  text-align: center;
  float: left;
  height: 100px; }
  .simple-line-icons .glyph .glyph-icon,
  .simple-line-icons .glyph .fa,
  .mind-icons .glyph .glyph-icon,
  .mind-icons .glyph .fa {
    font-size: 32px; }
  .simple-line-icons .glyph .author-name,
  .mind-icons .glyph .author-name {
    display: none; }
  .simple-line-icons .glyph .class-name,
  .mind-icons .glyph .class-name {
    font-size: 0.76rem;
    color: #8898aa !important; }
  @media (max-width: 1199px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 16.66%; } }
  @media (max-width: 991px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 20%; } }
  @media (max-width: 767px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 25%; } }
  @media (max-width: 575px) {
    .simple-line-icons .glyph,
    .mind-icons .glyph {
      width: 50%; } }

.chart-container {
  height: 300px; }

.theme-colors {
  width: 280px;
  position: fixed;
  z-index: 1030;
  top: 50%;
  right: 0;
  background: #ffffff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
  -webkit-transform: translate(280px, -50%);
          transform: translate(280px, -50%);
  -webkit-transition: -webkit-transform 0.4s ease-out;
  transition: -webkit-transform 0.4s ease-out;
  transition: transform 0.4s ease-out;
  transition: transform 0.4s ease-out, -webkit-transform 0.4s ease-out;
  padding-top: 10px;
  padding-bottom: 10px; }
  .theme-colors .theme-button {
    position: absolute;
    left: -34px;
    background: #ffffff;
    padding: 13px 7px 13px 7px;
    border-radius: 0.2rem;
    color: #495057;
    box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.04);
    font-size: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #3949ab; }
  .theme-colors .theme-color {
    width: 24px;
    height: 24px;
    display: inline-block;
    border-radius: 20px;
    -webkit-transition: background 0.25s;
    transition: background 0.25s; }
    .theme-colors .theme-color.active, .theme-colors .theme-color:hover {
      background: #ffffff; }
  .theme-colors .theme-color-purple {
    border: 3px solid #8965e0;
    background: #8965e0; }
  .theme-colors .theme-color-blue {
    border: 3px solid #edefff;
    background: #edefff; }
  .theme-colors .theme-color-orange {
    border: 3px solid #e67e22;
    background: #e67e22; }
  .theme-colors .theme-color-green {
    border: 3px solid #2ecc71;
    background: #2ecc71; }
  .theme-colors .theme-color-red {
    border: 3px solid #e74c3c;
    background: #e74c3c; }
  .theme-colors.shown {
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%); }

/* 34.Floating Label */
.has-float-label {
  display: block;
  position: relative; }

.has-float-label label::after,
.has-float-label > span::after {
  background: #ffffff !important; }

.has-top-label label,
.has-top-label > span,
.has-float-label label,
.has-float-label > span {
  color: rgba(73, 80, 87, 0.7); }

.has-float-label label,
.has-float-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 90%;
  opacity: 1;
  top: -0.4em;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px; }

.has-float-label label::after,
.has-float-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 5px;
  top: 3px;
  left: -0.2em;
  right: -0.2em;
  z-index: -1; }

.has-float-label .form-control::-webkit-input-placeholder {
  opacity: 1; }

.has-float-label .form-control::-ms-input-placeholder {
  opacity: 1; }

.has-float-label .form-control::placeholder {
  opacity: 1; }

.has-float-label
.form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0; }

.has-float-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0; }

.has-float-label .form-control:placeholder-shown:not(:focus)::-ms-input-placeholder {
  opacity: 0; }

.has-float-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0; }

.input-group .has-float-label {
  flex-grow: 1;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.input-group .has-float-label .form-control {
  width: 100%; }

.input-group .has-float-label:not(:last-child),
.input-group .has-float-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0; }

.input-group .has-float-label:not(:first-child),
.input-group .has-float-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
  padding: 1.7rem 0.75rem 0.5rem 0.75rem !important; }

.has-top-label {
  display: block;
  position: relative; }
  .has-top-label .react-tagsinput-input {
    padding: 0;
    margin: 0;
    font-size: 0.875rem;
    line-height: 1; }

.has-top-label .react-select__value-container .css-rsyb7x {
  margin: 0 !important;
  padding: 0 !important; }

.has-top-label label,
.has-top-label > span:last-of-type {
  position: absolute;
  cursor: text;
  font-size: 76%;
  opacity: 1;
  top: 0.7rem;
  left: 0.75rem;
  z-index: 3;
  line-height: 1;
  padding: 0 1px;
  font-weight: 600; }

.has-top-label label::after,
.has-top-label > span::after {
  content: " ";
  display: block;
  position: absolute;
  height: 2px;
  top: 50%;
  left: -0.2em;
  right: -0.2em;
  z-index: -1; }

.has-top-label .form-control::-webkit-input-placeholder {
  opacity: 1; }

.has-top-label .form-control::-ms-input-placeholder {
  opacity: 1; }

.has-top-label .form-control::placeholder {
  opacity: 1; }

.has-top-label
.form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0; }

.has-top-label .form-control:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0; }

.has-top-label .form-control:placeholder-shown:not(:focus)::-ms-input-placeholder {
  opacity: 0; }

.has-top-label .form-control:placeholder-shown:not(:focus)::placeholder {
  opacity: 0; }

.has-top-label .form-control:placeholder-shown:not(:focus) + * {
  font-size: 150%;
  opacity: 0.5;
  top: 0.3em; }

.has-top-label .react-select__single-value {
  top: unset !important;
  bottom: -3px !important;
  margin-left: 0 !important; }

.input-group .has-top-label {
  flex-grow: 1;
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.input-group .has-top-label .form-control {
  width: 100%; }

.input-group .has-top-label:not(:last-child),
.input-group .has-top-label:not(:last-child) .form-control {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-right: 0; }

.input-group .has-top-label:not(:first-child),
.input-group .has-top-label:not(:first-child) .form-control {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0; }

.form-group.has-top-label .form-control,
.form-group.has-top-label .react-tagsinput,
.form-group.has-top-label .react-datepicker__input-container input,
.form-group.has-top-label .react-select__value-container {
  min-height: calc(3.3rem + 2px) !important; }

.form-group.has-top-label select.form-control:not([size]):not([multiple]) {
  height: calc(3.3rem + 2px);
  padding: 1.7rem 0.75rem 0.5rem 0.5rem; }

/* 35.Loading */
.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(57, 73, 171, 0.2);
  border-radius: 50%;
  border-top-color: #3949ab;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1; }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

/* 36.Context Menu */
.react-contextmenu {
  box-shadow: none;
  box-shadow: initial;
  border-radius: 0.375rem;
  background: #ffffff;
  border: 1px solid rgba(73, 80, 87, 0.15);
  padding: 0.5rem 0;
  opacity: 0; }

.react-contextmenu.react-contextmenu--visible {
  opacity: 1;
  pointer-events: auto;
  z-index: 9999; }

.react-contextmenu-item {
  padding: 0.5rem 1.5rem;
  background: #ffffff;
  color: #495057;
  cursor: pointer; }
  .react-contextmenu-item span {
    font-family: "Nunito Sans", sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    margin-left: 0.5rem;
    line-height: 15px; }
  .react-contextmenu-item:hover {
    color: #495057;
    text-decoration: none;
    background-color: #fbfbff; }

.react-contextmenu-item:not(.react-contextmenu-item--disabled):hover {
  color: #495057;
  text-decoration: none;
  background-color: #fbfbff; }

.card.react-contextmenu--visible,
.card.active {
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.1), 0 3px 20px rgba(0, 0, 0, 0.1); }

/* VHMS */
.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 600 !important; }

.font-weight-strong {
  font-weight: 700 !important; }

small, .small {
  font-size: 90%; }

.fixed-footer {
  position: absolute;
  left: 0;
  right: 0; }

body.landing {
  height: 100%; }
  body.landing main {
    margin: 0 !important;
    min-height: 100%;
    padding-top: 58px !important; }
    body.landing main .container {
      height: 100%; }

.lead {
  font-weight: 400; }

.heading-small {
  text-transform: uppercase;
  font-weight: 600; }

.invalid-feedback {
  font-size: 90%; }

.dropdown-item {
  padding: 0.5rem 1rem;
  font-weight: 600; }

.preloader {
  background-color: #3949ab;
  /* background: -webkit-linear-gradient(3deg, #3949ab 0, #11cdef 100%) !important;
    background: linear-gradient(87deg, #3949ab 0, #11cdef 100%) !important; */
  position: fixed;
  z-index: 999;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }

.spinner {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 50px; }

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #FFF;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1s infinite ease-in-out both;
  animation: sk-bouncedelay 1s infinite ease-in-out both; }

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s; }

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s; }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0); }
  40% {
    -webkit-transform: scale(1); } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.navbar {
  background: #3949ab;
  padding-right: 0;
  padding-left: 0;
  z-index: 100; }
  .navbar .search {
    border-radius: 5px; }
  .navbar .header-icon {
    color: #ffffff; }
    .navbar .header-icon:hover {
      color: #f6f9fc; }
  .navbar .menu-button-mobile {
    color: #8898aa;
    text-align: center; }
    .navbar .menu-button-mobile svg {
      fill: #ffffff;
      height: 16px; }
    @media (max-width: 767px) {
      .navbar .menu-button-mobile {
        width: 90px; } }
  .navbar .menu-button {
    fill: #8898aa;
    width: 100px; }
    .navbar .menu-button .main {
      fill: #ffffff; }
    .navbar .menu-button .sub {
      fill: #ffffff !important; }
    .navbar .menu-button:hover {
      fill: #2980b9 !important; }
    .navbar .menu-button svg {
      height: 16px; }
  .navbar .navbar-logo {
    width: 94px;
    height: 30px; }
    .navbar .navbar-logo .logo {
      background-size: 94px 30px; }
    .navbar .navbar-logo .logo-mobile {
      background-size: 94px 30px; }
  .navbar .user .name {
    color: #ffffff; }
  @media (max-width: 767px) {
    .navbar {
      height: 58px; } }

.fixed-navbar {
  position: absolute;
  left: 0;
  right: 0; }

.card {
  border-radius: 0.375rem; }

.auth-card .image-side {
  border-radius: .325rem 0 0 .325rem; }
  .auth-card .image-side p {
    margin-bottom: 0; }

.auth-card .form-side {
  padding: 30px; }

@media (max-width: 991px) {
  .auth-card {
    margin-top: 80px;
    margin-bottom: 80px; }
    .auth-card .image-side {
      padding: 45px 30px;
      border-radius: .325rem .325rem 0 0; }
    .auth-card .form-side {
      padding: 30px; } }

@media (max-width: 575px) {
  .auth-card .image-side {
    padding: 30px; } }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 768px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card .card-header-dashboard {
  border-bottom: 0;
  font-size: 1.25rem;
  font-weight: 600;
  padding-top: 1rem;
  padding-bottom: 1rem; }

.card .card-top-buttons {
  padding: 0.875rem;
  right: 0;
  top: 0; }

.card-header:first-child {
  border-radius: 0.375rem 0.375rem 0 0; }

.form-control-label {
  color: #7f8c8d;
  font-size: 0.875rem;
  font-weight: 600; }

.form-control {
  font-size: 0.875rem;
  border-radius: 0.375rem; }
  .form-control:focus::-webkit-input-placeholder {
    color: #95a5a6; }
  .form-control:focus::-ms-input-placeholder {
    color: #95a5a6; }
  .form-control:focus::placeholder {
    color: #95a5a6; }

textarea[resize="none"] {
  resize: none !important; }

textarea[resize="both"] {
  resize: both !important; }

textarea[resize="vertical"] {
  resize: vertical !important; }

textarea[resize="horizontal"] {
  resize: horizontal !important; }

.form-control-muted {
  background-color: #F7FAFE;
  border-color: #F7FAFE;
  box-shadow: none; }
  .form-control-muted:focus {
    background-color: #fcfdff; }

.form-control-alternative {
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
  -webkit-transition: box-shadow .15s ease;
  transition: box-shadow .15s ease; }
  .form-control-alternative:focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important; }

.form-control-lg {
  font-size: 0.875rem; }

.btn {
  position: relative;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  font-size: 0.875rem;
  font-weight: 600;
  will-change: transform; }
  .btn:hover {
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    -webkit-transform: translateY(-1px);
            transform: translateY(-1px); }
  .btn:not(:last-child) {
    margin-right: .5rem; }
  .btn i:not(:first-child),
  .btn svg:not(:first-child) {
    margin-left: .5rem; }
  .btn i:not(:last-child),
  .btn svg:not(:last-child) {
    margin-right: .5rem; }

.btn-empty:hover {
  box-shadow: none;
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.btn-empty:focus {
  box-shadow: none; }

.btn-group .btn,
.input-group .btn {
  margin-right: 0;
  -webkit-transform: translateY(0);
          transform: translateY(0); }

.btn-sm {
  font-size: 0.75rem; }

.btn-lg {
  font-size: 0.925rem; }

[class*="btn-outline-"] {
  border-width: 1px; }

.btn-inner--icon i:not(.fa) {
  position: relative;
  top: 2px; }

.btn-link {
  font-weight: 600;
  box-shadow: none; }
  .btn-link:hover {
    box-shadow: none;
    -webkit-transform: none;
            transform: none; }

.btn-neutral {
  color: #3949ab; }

.btn-icon .btn-inner--icon img {
  width: 20px; }

.btn-icon .btn-inner--text:not(:first-child) {
  margin-left: 0.75em; }

.btn-icon .btn-inner--text:not(:last-child) {
  margin-right: 0.75em; }

.btn-icon-only {
  width: 2.375rem;
  height: 2.375rem;
  padding: 0; }

a.btn-icon-only {
  line-height: 2.5; }

.btn-icon-only.btn-sm {
  width: 2rem;
  height: 2rem; }

.btn-default {
  color: #ffffff;
  background-color: #3949ab;
  border-color: #3949ab;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-default:hover {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
  .btn-default:focus, .btn-default.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(57, 73, 171, 0.5); }
  .btn-default.disabled, .btn-default:disabled {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
  .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active,
  .show > .btn-default.dropdown-toggle {
    color: #ffffff;
    background-color: #2c3985;
    border-color: #3949ab; }
    .btn-default:not(:disabled):not(.disabled):active:focus, .btn-default:not(:disabled):not(.disabled).active:focus,
    .show > .btn-default.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(57, 73, 171, 0.5); }

.btn-primary {
  color: #ffffff;
  background-color: #3949ab;
  border-color: #3949ab;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(57, 73, 171, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #2c3985;
    border-color: #3949ab; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(57, 73, 171, 0.5); }

.btn-secondary {
  color: #ffffff;
  background-color: #7f8c8d;
  border-color: #7f8c8d;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #7f8c8d;
    border-color: #7f8c8d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(127, 140, 141, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #ffffff;
    background-color: #7f8c8d;
    border-color: #7f8c8d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #667273;
    border-color: #7f8c8d; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(127, 140, 141, 0.5); }

.btn-success {
  color: #ffffff;
  background-color: #27ae60;
  border-color: #27ae60;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-success:hover {
    color: #ffffff;
    background-color: #27ae60;
    border-color: #27ae60; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(39, 174, 96, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #ffffff;
    background-color: #27ae60;
    border-color: #27ae60; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #1e8449;
    border-color: #27ae60; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(39, 174, 96, 0.5); }

.btn-info {
  color: #ffffff;
  background-color: #16a085;
  border-color: #16a085;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-info:hover {
    color: #ffffff;
    background-color: #16a085;
    border-color: #16a085; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(22, 160, 133, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #ffffff;
    background-color: #16a085;
    border-color: #16a085; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #107360;
    border-color: #16a085; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(22, 160, 133, 0.5); }

.btn-warning {
  color: #ffffff;
  background-color: #d35400;
  border-color: #d35400;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-warning:hover {
    color: #ffffff;
    background-color: #d35400;
    border-color: #d35400; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(211, 84, 0, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #ffffff;
    background-color: #d35400;
    border-color: #d35400; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #a04000;
    border-color: #d35400; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(211, 84, 0, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #c0392b;
  border-color: #c0392b;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #c0392b;
    border-color: #c0392b; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(192, 57, 43, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #c0392b;
    border-color: #c0392b; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #962d22;
    border-color: #c0392b; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(192, 57, 43, 0.5); }

.btn-white {
  color: #1f3448;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-white:hover {
    color: #1f3448;
    background-color: white;
    border-color: white; }
  .btn-white:focus, .btn-white.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #1f3448;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #1f3448;
    background-color: #e6e6e6;
    border-color: white; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-neutral {
  color: #1f3448;
  background-color: #ffffff;
  border-color: #ffffff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-neutral:hover {
    color: #1f3448;
    background-color: white;
    border-color: white; }
  .btn-neutral:focus, .btn-neutral.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-neutral.disabled, .btn-neutral:disabled {
    color: #1f3448;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-neutral:not(:disabled):not(.disabled):active, .btn-neutral:not(:disabled):not(.disabled).active,
  .show > .btn-neutral.dropdown-toggle {
    color: #1f3448;
    background-color: #e6e6e6;
    border-color: white; }
    .btn-neutral:not(:disabled):not(.disabled):active:focus, .btn-neutral:not(:disabled):not(.disabled).active:focus,
    .show > .btn-neutral.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #1f3448;
  border-color: #1f3448;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #1f3448;
    border-color: #1f3448; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(31, 52, 72, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #1f3448;
    border-color: #1f3448; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #101a24;
    border-color: #1f3448; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(31, 52, 72, 0.5); }

.btn-darker {
  color: #ffffff;
  background-color: #080d13;
  border-color: #080d13;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-darker:hover {
    color: #ffffff;
    background-color: #080d13;
    border-color: #080d13; }
  .btn-darker:focus, .btn-darker.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(8, 13, 19, 0.5); }
  .btn-darker.disabled, .btn-darker:disabled {
    color: #ffffff;
    background-color: #080d13;
    border-color: #080d13; }
  .btn-darker:not(:disabled):not(.disabled):active, .btn-darker:not(:disabled):not(.disabled).active,
  .show > .btn-darker.dropdown-toggle {
    color: #ffffff;
    background-color: black;
    border-color: #080d13; }
    .btn-darker:not(:disabled):not(.disabled):active:focus, .btn-darker:not(:disabled):not(.disabled).active:focus,
    .show > .btn-darker.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(8, 13, 19, 0.5); }

.btn-primary-light {
  color: #1f3448;
  background-color: #edefff;
  border-color: #edefff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-primary-light:hover {
    color: #1f3448;
    background-color: #edefff;
    border-color: #edefff; }
  .btn-primary-light:focus, .btn-primary-light.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(237, 239, 255, 0.5); }
  .btn-primary-light.disabled, .btn-primary-light:disabled {
    color: #1f3448;
    background-color: #edefff;
    border-color: #edefff; }
  .btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-primary-light.dropdown-toggle {
    color: #1f3448;
    background-color: #bac2ff;
    border-color: #edefff; }
    .btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-light.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(237, 239, 255, 0.5); }

.btn-info-light {
  color: #ffffff;
  background-color: #1abc9c;
  border-color: #1abc9c;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); }
  .btn-info-light:hover {
    color: #ffffff;
    background-color: #1abc9c;
    border-color: #1abc9c; }
  .btn-info-light:focus, .btn-info-light.focus {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(26, 188, 156, 0.5); }
  .btn-info-light.disabled, .btn-info-light:disabled {
    color: #ffffff;
    background-color: #1abc9c;
    border-color: #1abc9c; }
  .btn-info-light:not(:disabled):not(.disabled):active, .btn-info-light:not(:disabled):not(.disabled).active,
  .show > .btn-info-light.dropdown-toggle {
    color: #ffffff;
    background-color: #148f77;
    border-color: #1abc9c; }
    .btn-info-light:not(:disabled):not(.disabled):active:focus, .btn-info-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info-light.dropdown-toggle:focus {
      box-shadow: none, 0 0 0 0 rgba(26, 188, 156, 0.5); }

.btn-outline-default {
  color: #3949ab;
  background-color: transparent;
  background-image: none;
  border-color: #3949ab; }
  .btn-outline-default:hover {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
  .btn-outline-default:focus, .btn-outline-default.focus {
    box-shadow: 0 0 0 0 rgba(57, 73, 171, 0.5); }
  .btn-outline-default.disabled, .btn-outline-default:disabled {
    color: #3949ab;
    background-color: transparent; }
  .btn-outline-default:not(:disabled):not(.disabled):active, .btn-outline-default:not(:disabled):not(.disabled).active,
  .show > .btn-outline-default.dropdown-toggle {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
    .btn-outline-default:not(:disabled):not(.disabled):active:focus, .btn-outline-default:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-default.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(57, 73, 171, 0.5); }

.btn-outline-primary {
  color: #3949ab;
  background-color: transparent;
  background-image: none;
  border-color: #3949ab; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(57, 73, 171, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #3949ab;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #3949ab;
    border-color: #3949ab; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(57, 73, 171, 0.5); }

.btn-outline-secondary {
  color: #7f8c8d;
  background-color: transparent;
  background-image: none;
  border-color: #7f8c8d; }
  .btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #7f8c8d;
    border-color: #7f8c8d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(127, 140, 141, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #7f8c8d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #7f8c8d;
    border-color: #7f8c8d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(127, 140, 141, 0.5); }

.btn-outline-success {
  color: #27ae60;
  background-color: transparent;
  background-image: none;
  border-color: #27ae60; }
  .btn-outline-success:hover {
    color: #ffffff;
    background-color: #27ae60;
    border-color: #27ae60; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(39, 174, 96, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #27ae60;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #27ae60;
    border-color: #27ae60; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(39, 174, 96, 0.5); }

.btn-outline-info {
  color: #16a085;
  background-color: transparent;
  background-image: none;
  border-color: #16a085; }
  .btn-outline-info:hover {
    color: #ffffff;
    background-color: #16a085;
    border-color: #16a085; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #16a085;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #16a085;
    border-color: #16a085; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(22, 160, 133, 0.5); }

.btn-outline-warning {
  color: #d35400;
  background-color: transparent;
  background-image: none;
  border-color: #d35400; }
  .btn-outline-warning:hover {
    color: #ffffff;
    background-color: #d35400;
    border-color: #d35400; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(211, 84, 0, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #d35400;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #ffffff;
    background-color: #d35400;
    border-color: #d35400; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(211, 84, 0, 0.5); }

.btn-outline-danger {
  color: #c0392b;
  background-color: transparent;
  background-image: none;
  border-color: #c0392b; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #c0392b;
    border-color: #c0392b; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(192, 57, 43, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #c0392b;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #c0392b;
    border-color: #c0392b; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(192, 57, 43, 0.5); }

.btn-outline-white {
  color: #ffffff;
  background-color: transparent;
  background-image: none;
  border-color: #ffffff; }
  .btn-outline-white:hover {
    color: #1f3448;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #1f3448;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-outline-neutral {
  color: #ffffff;
  background-color: transparent;
  background-image: none;
  border-color: #ffffff; }
  .btn-outline-neutral:hover {
    color: #1f3448;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-neutral:focus, .btn-outline-neutral.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-outline-neutral.disabled, .btn-outline-neutral:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-neutral:not(:disabled):not(.disabled):active, .btn-outline-neutral:not(:disabled):not(.disabled).active,
  .show > .btn-outline-neutral.dropdown-toggle {
    color: #1f3448;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-neutral:not(:disabled):not(.disabled):active:focus, .btn-outline-neutral:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-neutral.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-outline-dark {
  color: #1f3448;
  background-color: transparent;
  background-image: none;
  border-color: #1f3448; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #1f3448;
    border-color: #1f3448; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(31, 52, 72, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #1f3448;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #1f3448;
    border-color: #1f3448; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(31, 52, 72, 0.5); }

.btn-outline-darker {
  color: #080d13;
  background-color: transparent;
  background-image: none;
  border-color: #080d13; }
  .btn-outline-darker:hover {
    color: #ffffff;
    background-color: #080d13;
    border-color: #080d13; }
  .btn-outline-darker:focus, .btn-outline-darker.focus {
    box-shadow: 0 0 0 0 rgba(8, 13, 19, 0.5); }
  .btn-outline-darker.disabled, .btn-outline-darker:disabled {
    color: #080d13;
    background-color: transparent; }
  .btn-outline-darker:not(:disabled):not(.disabled):active, .btn-outline-darker:not(:disabled):not(.disabled).active,
  .show > .btn-outline-darker.dropdown-toggle {
    color: #ffffff;
    background-color: #080d13;
    border-color: #080d13; }
    .btn-outline-darker:not(:disabled):not(.disabled):active:focus, .btn-outline-darker:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-darker.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(8, 13, 19, 0.5); }

.btn-outline-primary-light {
  color: #edefff;
  background-color: transparent;
  background-image: none;
  border-color: #edefff; }
  .btn-outline-primary-light:hover {
    color: #1f3448;
    background-color: #edefff;
    border-color: #edefff; }
  .btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
    box-shadow: 0 0 0 0 rgba(237, 239, 255, 0.5); }
  .btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
    color: #edefff;
    background-color: transparent; }
  .btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-light.dropdown-toggle {
    color: #1f3448;
    background-color: #edefff;
    border-color: #edefff; }
    .btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(237, 239, 255, 0.5); }

.btn-outline-info-light {
  color: #1abc9c;
  background-color: transparent;
  background-image: none;
  border-color: #1abc9c; }
  .btn-outline-info-light:hover {
    color: #ffffff;
    background-color: #1abc9c;
    border-color: #1abc9c; }
  .btn-outline-info-light:focus, .btn-outline-info-light.focus {
    box-shadow: 0 0 0 0 rgba(26, 188, 156, 0.5); }
  .btn-outline-info-light.disabled, .btn-outline-info-light:disabled {
    color: #1abc9c;
    background-color: transparent; }
  .btn-outline-info-light:not(:disabled):not(.disabled):active, .btn-outline-info-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info-light.dropdown-toggle {
    color: #ffffff;
    background-color: #1abc9c;
    border-color: #1abc9c; }
    .btn-outline-info-light:not(:disabled):not(.disabled):active:focus, .btn-outline-info-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(26, 188, 156, 0.5); }

.footer {
  background: theme-color("secondary");
  padding: 2.5rem 0; }
  .footer .col-footer .heading {
    color: #8898aa;
    letter-spacing: 0;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 1rem; }
  .footer .nav .nav-item .nav-link,
  .footer .footer-link {
    color: #8898aa !important; }
    .footer .nav .nav-item .nav-link:hover,
    .footer .footer-link:hover {
      color: #7f8c8d !important; }
  .footer .list-unstyled li a {
    display: inline-block;
    padding: .125rem 0;
    color: #8898aa;
    font-size: 0.875rem; }
    .footer .list-unstyled li a:hover {
      color: #7f8c8d; }
  .footer .copyright {
    font-size: 0.875rem; }

.footer-dark .col-footer .heading {
  color: #ffffff; }

.nav-footer .nav-link {
  font-size: 0.875rem; }

.nav-footer .nav-item:last-child .nav-link {
  padding-right: 0; }

.dashboard-lifecard-img {
  width: 100%;
  max-width: 425px;
  height: auto;
  padding: 0px; }

.dashboard-thumbnail-letters {
  width: 100px;
  height: 80px;
  border-radius: 0.375rem;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ffffff; }
  .dashboard-thumbnail-letters i {
    font-size: 35px;
    margin-top: 15px; }

.dashboard-records-img {
  width: 100%;
  max-width: 400px;
  height: auto;
  padding: 0px; }

.dashboard-records-item {
  width: 150px; }

.dash-records-item {
  width: 100px; }

.dash-records-item-thumbnail {
  width: 65px;
  height: 65px;
  background: #3949ab;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: .75rem; }
  .dash-records-item-thumbnail i {
    font-size: 28px;
    color: #ffffff; }
  .dash-records-item-thumbnail:hover {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
    background: #16a085; }

.dropdown-header {
  padding: .5rem .5rem;
  padding-top: 0;
  font-size: 0.75rem; }

.dropdown-profile {
  padding: .5rem 0; }
  .dropdown-profile .name {
    color: #3949ab !important; }

.dropdown-divider {
  margin: 0.1rem 0; }

.ant-modal-title {
  font-size: 18px !important;
  line-height: 22px !important;
  font-weight: 600 !important; }

.ant-modal-close-x {
  line-height: 48px !important; }

.anticon {
  vertical-align: baseline !important;
  vertical-align: initial !important; }

.bg-default {
  background: #3949ab !important; }

.bg-primary {
  background: #3949ab !important; }

.bg-secondary {
  background: #7f8c8d !important; }

.bg-success {
  background: #27ae60 !important; }

.bg-info {
  background: #16a085 !important; }

.bg-warning {
  background: #d35400 !important; }

.bg-danger {
  background: #c0392b !important; }

.bg-white {
  background: #ffffff !important; }

.bg-neutral {
  background: #ffffff !important; }

.bg-dark {
  background: #1f3448 !important; }

.bg-darker {
  background: #080d13 !important; }

.bg-primary-light {
  background: #edefff !important; }

.bg-info-light {
  background: #1abc9c !important; }

.fill-default {
  fill: #3949ab !important; }

.fill-primary {
  fill: #3949ab !important; }

.fill-secondary {
  fill: #7f8c8d !important; }

.fill-success {
  fill: #27ae60 !important; }

.fill-info {
  fill: #16a085 !important; }

.fill-warning {
  fill: #d35400 !important; }

.fill-danger {
  fill: #c0392b !important; }

.fill-white {
  fill: #ffffff !important; }

.fill-neutral {
  fill: #ffffff !important; }

.fill-dark {
  fill: #1f3448 !important; }

.fill-darker {
  fill: #080d13 !important; }

.fill-primary-light {
  fill: #edefff !important; }

.fill-info-light {
  fill: #1abc9c !important; }

.badge-default {
  color: #ffffff;
  background: #3949ab !important; }

.badge-primary {
  color: #ffffff;
  background: #3949ab !important; }

.badge-secondary {
  color: #ffffff;
  background: #7f8c8d !important; }

.badge-success {
  color: #ffffff;
  background: #27ae60 !important; }

.badge-info {
  color: #ffffff;
  background: #16a085 !important; }

.badge-warning {
  color: #ffffff;
  background: #d35400 !important; }

.badge-danger {
  color: #ffffff;
  background: #c0392b !important; }

.badge-white {
  color: #1f3448;
  background: #ffffff !important; }

.badge-neutral {
  color: #1f3448;
  background: #ffffff !important; }

.badge-dark {
  color: #ffffff;
  background: #1f3448 !important; }

.badge-darker {
  color: #ffffff;
  background: #080d13 !important; }

.badge-primary-light {
  color: #1f3448;
  background: #edefff !important; }

.badge-info-light {
  color: #ffffff;
  background: #1abc9c !important; }

.text-default {
  color: #3949ab !important; }

.text-primary {
  color: #3949ab !important; }

.text-secondary {
  color: #7f8c8d !important; }

.text-success {
  color: #27ae60 !important; }

.text-info {
  color: #16a085 !important; }

.text-warning {
  color: #d35400 !important; }

.text-danger {
  color: #c0392b !important; }

.text-white {
  color: #ffffff !important; }

.text-neutral {
  color: #ffffff !important; }

.text-dark {
  color: #1f3448 !important; }

.text-darker {
  color: #080d13 !important; }

.text-primary-light {
  color: #edefff !important; }

.text-info-light {
  color: #1abc9c !important; }

